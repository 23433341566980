import React from 'react';
import OptimizeTabs from '../menus/OptimizeTabs';
import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';
import { optimizeSetActiveDevice, optimizeSetActiveTab, setModule } from '../../actions';
import PerformanceWidgets from '../widgetGroups/optimize/PerformanceWidgets';
import CertWidgets from '../widgetGroups/optimize/CertWidgets';
import DiagnosticsWidgets from '../widgetGroups/optimize/DiagnosticsWidgets';
import ImprovementsWidgets from '../widgetGroups/optimize/ImprovementsWidgets';
import AlignmentWidgets from '../widgetGroups/optimize/AlignmentWidgets';
import functions from '../../functions/functions';
import { styles } from '../styles';

class OptimizeDetails extends React.Component {
  constructor(props) {
    super(props);
    if (this.props.currentModule !== 'Optimize') {
      this.props.setModule('Optimize');
    }
    this.props.optimizeSetActiveDevice(this.props.match.params.id);
    this.props.optimizeSetActiveTab(this.props.match.params.tab);
  }
  componentDidUpdate() {
    if (this.props.match.params.tab !== this.props.activeTab) {
      this.props.optimizeSetActiveTab(this.props.match.params.tab);
    }
    if (this.props.match.params.id !== this.props.activeDevice) {
      this.props.optimizeSetActiveDevice(this.props.match.params.id);
    }
  }
  render() {
    const { classes } = this.props;
    const printStyles = functions.setPrintStyles(this.props.activeTab);
    return (
      <React.Fragment>
        {this.props.format ? null : <OptimizeTabs />}
        <div className={printStyles ? null : printStyles ? null : classes.details}>
          {this.props.activeTab === 'performance' && <PerformanceWidgets />}
          {this.props.activeTab === 'improvements' && <ImprovementsWidgets />}
          {this.props.activeTab === 'diagnostics' && <DiagnosticsWidgets />}
          {this.props.activeTab === 'alignment' && <AlignmentWidgets />}
          {this.props.activeTab === 'certification' && <CertWidgets />}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    activeTab: state.optimize.activeTab,
    activeDevice: state.optimize.activeLoop,
    currentModule: state.currentModule,
    format: state.format.format,
  };
};

export default connect(mapStateToProps, {
  optimizeSetActiveDevice,
  optimizeSetActiveTab,
  setModule,
})(withStyles(styles, { withTheme: true })(OptimizeDetails));
