import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { Typography, Grid, Paper, Divider, Tooltip, MenuItem, ClickAwayListener, Popper, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import initializeTheme from '../theme';
import OptimizeProgress from './OptimizeProgress';
import ProgressGuide from './ProgressGuide';
import { connect } from 'react-redux';
import { styles } from '../styles';
import { CircularProgress } from '@mui/material';
import { MoreVert, Tune, Speed } from '@mui/icons-material';
import functions from '../../functions/functions';
import { optimizeSetCompareTest, optimizeSetCompareFlag, optimizeSetCompareDevice, fetchDevices /*, fetchDeviceEvents*/ } from '../../actions/index';
//import PrioritizeProgress from './PrioritizeProgress';
//import Table from '../charts/Table';

class OptimizeTile extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      hasBorder: false,
      selectMenu: false,
      selectMenuAnchor: null,
    };
  }
  componentDidMount() {
    if (this.props.hasBorder) {
      this.setState({ hasBorder: true });
    }
    if (this.props.prioritize) {
      this.props.fetchDevices(this.props.device.device_id, 'optimize');
    }
  }
  renderLoading() {
    const { classes } = this.props;
    if (this.state.loading) {
      return (
        <Typography variant='subtitle2'>
          <CircularProgress className={classes.loadIcon} size={15} color='secondary' />
          Loading...
        </Typography>
      );
    } else {
      return;
    }
  }
  renderMoreEvents() {
    const device = this.props.device;
    const eventCount = device.events.length - 4;
    if (eventCount > 0) {
      return <Typography variant='subtitle2'>... {eventCount} More Event(s) Found</Typography>;
    }
  }
  render() {
    const { classes } = this.props;
    const theme = initializeTheme(this.props.colorMode);
    const hasBorder = this.state.hasBorder;
    const device = this.props.device;
    let content = <div>Could not find data for this device!</div>;
    const length = 17;
    let trimmedSite = !device || !device.site ? 'Unknown' : device.site.substring(0, length);
    let trimmedApplication = !device || !device.application ? 'Unknown' : device.application.substring(0, length);
    //let improvementCount = +device.level1 + +device.level2 + +device.level3;
    /*let tableData = [
      {
        id: 1,
        recommendation: 'Fix this',
        priority: 'Low',
        approval: 'DW - Approved'
      },
      {
        id: 2,
        recommendation: 'Do the thing',
        priority: 'Low',
        approval: ''
      },
      {
        id: 3,
        recommendation: 'Add a Widget',
        priority: 'High',
        approval: ''
      },
      {
        id: 4,
        recommendation: 'New Page Item',
        priority: 'Medium',
        approval: 'KS - Pending'
      }
    ];
    const tableHeader = [
      {
        id: 'recommendation',
        numeric: false,
        label: 'Recommendation'
      },
      { id: 'priority', numeric: false, label: 'Priority' },
      { id: 'approval', numeric: false, label: 'Approval' }
    ];*/
    const handleClick = (e) => {
      e.preventDefault();
      this.setState({
        selectMenu: !this.state.selectMenu,
        selectMenuAnchor: e.currentTarget,
      });
    };
    const handleLinkClick = (e) => {
      e.preventDefault();
      let win = window.open(`/optimize/${this.props.device.deviceId}/certification`, '_blank');
      win.focus();
    };
    const handleClose = () => {
      this.setState({ selectMenu: false });
    };
    const printStyles = functions.setPrintStyles(this.props.activeTab);
    if (this.props.device) {
      content = (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Grid container justifyContent='space-between'>
              <Grid item>
                <Tooltip arrow placement='top' title={`${!device.site ? 'Site Not Found' : device.site} ${!device.application ? '' : '- ' + device.application}`} enterDelay={300}>
                  <Typography variant='subtitle2' className={classes.optimizeTileInfo}>
                    {!device.site ? 'Site Not Found' : trimmedSite}
                    {!!device.site && device.site.length > length ? '...' : null} {!device.application ? null : '- ' + trimmedApplication}
                    {!!device.application && device.application.length > length ? '...' : null}
                  </Typography>
                </Tooltip>
                <Typography variant='subtitle2' className={classes.optimizeTileInfo}>
                  <span>{!device.importance || device.importance === 'Unknown' ? null : device.importance} </span>
                  Valve
                </Typography>
                <Typography style={theme.palette.blue} variant='subtitle2' className={classes.optimizeTileInfo}>
                  {!device.name ? 'No Device Exists' : device.name}
                </Typography>
                <Typography variant='subtitle2' className={classes.optimizeTileInfo}>
                  {!device.dateTime ? 'Unknown Test Date' : new Date(device.dateTime * 1000).toLocaleDateString()}
                </Typography>
              </Grid>
              <Grid item>
                <Grid container>
                  <Grid item>
                    <Tooltip arrow placement='top' title='Create Performance Certificate in a New Tab' aria-label='Create Performance Certificate in a New Tab'>
                      <IconButton className={classes.textColor} aria-label='more' size='large' onClick={handleLinkClick}>
                        <Speed />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item>
                    <Tooltip arrow placement='top' title='Quick Navigation' aria-label='Quick Navigation'>
                      <IconButton color='inherit' aria-label='more' onClick={handleClick} style={{ marginRight: -10 }} size='large'>
                        <MoreVert />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
                <Popper open={this.state.selectMenu} onClose={handleClose} anchorEl={this.state.selectMenuAnchor} placement='bottom-start'>
                  <ClickAwayListener onClickAway={handleClose}>
                    <Paper elevation={3}>
                      <MenuItem component={Link} to={`/optimize/${this.props.device.deviceId}/performance`}>
                        Performance
                      </MenuItem>
                      <MenuItem component={Link} to={`/optimize/${this.props.device.deviceId}/improvements`}>
                        Improvement Paths
                      </MenuItem>
                      <MenuItem component={Link} to={`/optimize/${this.props.device.deviceId}/diagnostics`}>
                        Diagnostics
                      </MenuItem>
                      <MenuItem component={Link} to={`/optimize/${this.props.device.deviceId}/alignment`}>
                        Alignment
                      </MenuItem>
                    </Paper>
                  </ClickAwayListener>
                </Popper>
              </Grid>
            </Grid>
            <Divider className={classes.dividerMd} />
            <Typography variant='subtitle2'>{!device.disruptionType ? null : device.disruptionType}</Typography>
            <Typography variant='subtitle2' className={classes.tileTitle}>
              Performance
            </Typography>
            <OptimizeProgress
              className={classes.progress}
              label='Resolution'
              alignmentType='Resolution'
              alignment={device.resolutionResult}
              alignmentMax={!device.resolutionApp || device.resolutionApp === null ? 5 : device.resolutionApp}
              alignmentShift={!device.resolutionShift || device.resolutionShift === null ? 0 : device.resolutionShift}
              alignmentTitle={`${device.resolutionPrefix} ${Math.round(device.resolutionResult * 1000) / 1000}% ${device.resolutionPostfix !== '' ? `(${device.resolutionPostfix})` : ''}`}
              alignmentTarget={!device.resolutionApp ? null : `${device.resolutionApp}%`}
            />
            <ProgressGuide
              value={device.resolutionResult / 2}
              valueMax={!device.resolutionApp || device.resolutionApp === null ? 5 : device.resolutionApp}
              valueShift={!device.resolutionShift || device.resolutionShift === null ? 0 : device.resolutionShift}
              valueTarget={device.resolutionApp}
            ></ProgressGuide>
            <OptimizeProgress
              className={classes.progress}
              label='Sensitivity'
              alignmentType='Sensitivity'
              alignment={device.sensitivityResult}
              alignmentMax={!device.sensitivityApp || device.sensitivityApp === null ? 2.5 : device.sensitivityApp}
              alignmentShift={!device.sensitivityShift || device.sensitivityShift === null ? 0 : device.sensitivityShift}
              alignmentTitle={`${device.sensitivityPrefix} ${Math.round(device.sensitivityResult * 1000) / 1000}% ${device.sensitivityPostfix !== '' ? `(${device.sensitivityPostfix})` : ''}`}
              alignmentTarget={!device.sensitivityApp ? null : `${device.sensitivityApp}%`}
            />
            <ProgressGuide
              value={device.sensitivityResult / 2}
              valueMax={!device.sensitivityApp || device.sensitivityApp === null ? 2.5 : device.sensitivityApp}
              valueShift={!device.sensitivityShift || device.sensitivityShift === null ? 0 : device.sensitivityShift}
              valueTarget={device.sensitivityApp}
            ></ProgressGuide>

            <OptimizeProgress
              className={classes.progress}
              label='Stroke Speed'
              alignmentType='Stroke'
              alignment={device.strokeResult}
              alignmentMax={!device.strokeApp || device.strokeApp === null ? 5 : device.strokeApp}
              alignmentShift={!device.strokeShift || device.strokeShift === null ? 0 : device.strokeShift}
              alignmentTitle={`${device.strokePrefix} ${Math.round(device.strokeResult * 1000) / 1000}s ${device.strokePostfix !== '' ? `(${device.strokePostfix})` : ''}`}
              alignmentTarget={!device.strokeApp ? null : `${device.strokeApp}s`}
            />
            <ProgressGuide
              value={device.strokeResult / 2}
              valueMax={!device.strokeApp || device.strokeApp === null ? 5 : device.strokeApp}
              valueShift={!device.strokeShift || device.strokeShift === null ? 0 : device.strokeShift}
              valueTarget={device.strokeApp}
            ></ProgressGuide>
            {/*<Typography variant='subtitle2' className={classes.tileTitle}>
              Improvement Paths
            </Typography>
            <Typography className={classes.tileText}>
              <span className={`${classes.bold} ${classes.repairNumber}`}>{!device.level1 ? 0 : device.level1}</span>
              Field Adjustments
            </Typography>
            <Typography className={classes.tileText}>
              <span className={`${classes.bold} ${classes.repairNumber}`}>{!device.level2 ? 0 : device.level2}</span>
              Part Replacements
            </Typography>
            <Typography className={classes.tileText}>
              <span className={`${classes.bold} ${classes.repairNumber}`}>{!device.level3 ? 0 : device.level3}</span>
              Body Repairs
      </Typography>
            <Typography className={classes.tileText} variant='subtitle2' style={{ marginTop: 8 }}>
              <span className={`${classes.bold} ${classes.repairNumber}`}>{!improvementCount ? 0 : improvementCount}</span>
              Issue{improvementCount === 1 ? null : 's'}
            </Typography>
            <Typography className={classes.tileText} variant='subtitle2'>
              <span className={`${classes.bold} ${classes.repairNumber}`}>{!device.recommendations.length ? 0 : device.recommendations.length}</span>
              Recommendation{device.recommendations.length === 1 ? null : 's'}
            </Typography>
*/}
            <Grid container>
              <Grid item>
                <Typography className={classes.tileText} variant='subtitle2'>
                  <span className={`${classes.bold} ${classes.repairNumber}`}>{!device.issueCount ? 0 : device.issueCount}</span>
                  Open Issue{device.issueCount > 1 || device.issueCount < 1 || device.issueCount === undefined ? 's' : null}
                </Typography>
              </Grid>
              {!device.issueCountControl || device.issueCountControl === '0' || device.issueCountControl === 0 || device.issueCountControl === undefined ? null : (
                <Grid item>
                  <Tooltip
                    arrow
                    placement='top'
                    title={`${!device.issueCountControl ? 0 : device.issueCountControl} Alignment Issue${
                      device.issueCountControl > 1 || device.issueCountControl < 1 || device.issueCountControl === undefined ? 's' : ''
                    }`}
                    enterDelay={300}
                  >
                    <Tune style={{ width: 20, height: 20, marginLeft: 8 }} />
                  </Tooltip>
                </Grid>
              )}
            </Grid>
            <Typography className={classes.tileText} variant='subtitle2'>
              <span className={`${classes.bold} ${classes.repairNumber}`}>{!device.level1 ? 0 : device.level1}</span>
              Field Adjustments
            </Typography>
            <Typography className={classes.tileText} variant='subtitle2'>
              <span className={`${classes.bold} ${classes.repairNumber}`}>{!device.level2 ? 0 : device.level2}</span>
              Part Replacements
            </Typography>
            <Typography className={classes.tileText} variant='subtitle2'>
              <span className={`${classes.bold} ${classes.repairNumber}`}>{!device.level3 ? 0 : device.level3}</span>
              Body Repairs
            </Typography>
            {/*<Typography className={classes.tileText} variant='subtitle2'>
              <span className={`${classes.bold} ${classes.repairNumber}`}>{!device.recommendationCount ? 0 : device.recommendationCount}</span>
              Recommendation{device.recommendationCount === 1 ? '' : 's'}
            </Typography>
            <Typography className={classes.tileText} variant='subtitle2'>
              <span className={`${classes.bold} ${classes.repairNumber}`}>{!improvementCount ? 0 : improvementCount}</span>
              Improvement Path{improvementCount === 1 ? '' : 's'}
              </Typography>*/}
          </Grid>
        </Grid>
      );
    }
    if (this.props.hasLink) {
      return (
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={this.props.format ? 6 : 4}
          className={classes.textDecoNone}
          onClick={() => {
            this.props.optimizeSetCompareFlag(false);
            this.props.optimizeSetCompareDevice('');
            this.props.optimizeSetCompareTest('');
          }}
          component={Link}
          to={`/optimize/${this.props.device.deviceId}/performance`}
        >
          <Paper className={printStyles ? classes.printOptimizeTile : classes.optimizeTile} elevation={3} style={{ borderWidth: hasBorder ? 1 : '' }}>
            {content}
          </Paper>
        </Grid>
      );
    } else {
      return (
        <Grid item xs={12} sm={printStyles ? 6 : 12} md={6} lg={printStyles ? 6 : 4} className={classes.textDecoNone}>
          <Paper className={printStyles ? classes.printOptimizeTile : classes.optimizeTile} elevation={3} style={{ borderWidth: hasBorder ? 1 : '' }}>
            {content}
          </Paper>
        </Grid>
      );
    }
  }
}

OptimizeTile.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    isSignedIn: state.auth.isSignedIn,
    userId: state.auth.userId,
    locations: state.locations,
    errors: state.errors,
    events: state.events,
    devices: state.devices.devices,
    format: state.format.format,
    colorMode: state.colorMode.colorMode,
  };
};

export default connect(mapStateToProps, {
  optimizeSetCompareTest,
  optimizeSetCompareFlag,
  optimizeSetCompareDevice,
  fetchDevices,
})(withStyles(styles)(OptimizeTile));
