import React from 'react';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../../styles';
import { Link } from 'react-router-dom';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import UserConfig from './user/UserConfig';
import DemoConfig from './user/DemoConfig';
/*import ComparatorReport from '../../../optimize/ComparatorReport';
import ImprovementsReport from '../../../optimize/ImprovementsReport';
import PerformanceReport from '../../../optimize/PerformanceReport';
import PortfolioReport from '../../../optimize/PortfolioReport';
import RoiReport from '../../../optimize/RoiReport';*/
import { getImprovementsReport, reportsSetActiveReport } from '../../../actions/index';

class UserWidgets extends React.Component {
  componentDidMount() {
    let url = window.location.href.toLowerCase();
    url.includes('demo') ? this.props.reportsSetActiveReport('demo') : this.props.reportsSetActiveReport('user');
  }
  handleReport(event, value) {
    this.props.reportsSetActiveReport(value);
    if (!value) {
      this.props.reportsSetActiveReport('user');
    }
    //console.log(value);
  }
  //TODO fix wrapper buttons onclick
  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <ToggleButtonGroup
          value={!this.props.activeReport || (this.props.activeReport !== 'user' && this.props.activeReport !== 'demo') ? 'user' : this.props.activeReport}
          exclusive
          onChange={(event, value) => this.handleReport(event, value)}
          aria-label='text alignment'
          className={classes.toggleButtons}
        >
          <ToggleButton value='user' text='user' component={Link} to={`/configure/user/user`}>
            User Settings
          </ToggleButton>
          <ToggleButton value='demo' text='demo' component={Link} to={`/configure/user/demo`}>
            Available Demos
          </ToggleButton>
        </ToggleButtonGroup>
        {this.props.activeReport === 'user' || this.props.activeReport === null || (this.props.activeReport !== 'user' && this.props.activeReport !== 'demo') ? (
          <UserConfig />
        ) : this.props.activeReport === 'demo' ? (
          <DemoConfig />
        ) : null}
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    reports: state.reports.report_data,
    activeReport: state.reports.activeReport,
    view: state.auth.view,
  };
};

export default connect(mapStateToProps, {
  getImprovementsReport,
  reportsSetActiveReport,
})(withStyles(styles)(UserWidgets));
