import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { CircularProgress, Typography, Grid, Paper, Button } from '@mui/material';
import TextDisplay from '../filters/TextDisplay';
import initializeTheme from '../theme';
import { connect } from 'react-redux';
import { styles } from '../styles';
import { FilePresent } from '@mui/icons-material';
import functions from '../../functions/functions.js';
import SamplePDF from '../../files/_ROTARY_V_SIM_VALTEK_LOGIX.pdf';
import BenchmarkLogo from '../../images/benchmarkLogo.jpg';
import BenchmarkLogoDark from '../../images/benchmarkLogoDark.jpg';

class OptimizeSpecs extends React.Component {
  state = {
    loading: false,
    hasBorder: false,
  };

  componentDidMount() {
    if (this.props.hasBorder) {
      this.setState({ hasBorder: true });
    }
  }
  renderLoading() {
    const { classes } = this.props;
    if (this.state.loading) {
      return (
        <Typography variant='subtitle2'>
          <CircularProgress className={classes.loadIcon} size={15} color='secondary' />
          Loading...
        </Typography>
      );
    } else {
      return;
    }
  }
  renderMoreSpecs() {
    const device = this.props.device;
    return (
      <React.Fragment>
        <TextDisplay id={null} label='Spring' text={!device.spring ? '- ' : device.spring} type='tile' spec />
        <TextDisplay id={null} label='Pressure Class' text={!device.pressureClass ? '- ' : device.pressureClass} type='tile' spec />
        <TextDisplay id={null} label='Seat Trim Dia' text={!device.seatDia ? '- ' : device.seatDia + ' in.'} type='tile' spec />
        <TextDisplay id={null} label='Flow Char.' text={!device.flowCharacteristic ? '- ' : device.flowCharacteristic} type='tile' spec />
        <TextDisplay id={null} label='Packing Config.' text={!device.packingConfiguration ? '-' : device.packingConfiguration} type='tile' spec />
        <TextDisplay id={null} label='Packing Material' text={!device.packingMatl ? '- ' : device.packingMatl} type='tile' spec />
        <TextDisplay id={null} label='PBal Seal Mat.' text={!device.pBalSealMatl ? '- ' : device.pBalSealMatl} type='tile' spec />
        <TextDisplay id={null} label='Quick Exhaust' text={!device.quickExhaust ? '- ' : device.quickExhaust} type='tile' spec />
        <TextDisplay id={null} label='Booster' text={!device.booster ? '- ' : device.booster} type='tile' spec />
        {!device.flowDirection ? null : <TextDisplay id={null} label='Flow Direction' text={!device.flowDirection ? '- ' : device.flowDirection} type='tile' spec />}
        {!device.solenoidsMfgr ? null : <TextDisplay id={null} label='Solenoids Mfgr' text={!device.solenoidsMfgr ? '- ' : device.soleonoidsMfgr} type='tile' spec />}
        {!device.handwheelOrientation ? null : <TextDisplay id={null} label='Handwheel' text={!device.handwheelOrientation ? '- ' : device.handwheelOrientation} type='tile' spec />}
        {!device.customerPO ? null : <TextDisplay id={null} label='PO' text={!device.customerPO ? '- ' : device.customerPO} type='tile' spec />}
        {!device.yearOfInstall ? null : <TextDisplay id={null} label='Year of Install' text={!device.yearOfInstall ? '- ' : device.yearOfInstall} type='tile' spec />}
        {/*<TextDisplay
            id={null}
            label='Tag'
            text={!device.tag ? '- ' : device.tag}
             type='tile' spec
          />
          <TextDisplay
            id={null}
            label='Location'
            text={!device.location ? '- ' : device.location}
             type='tile' spec
          />
          <TextDisplay
            id={null}
            label='Valve Application'
            text={!device.valveApplication ? '- ' : device.valveApplication}
             type='tile' spec
          />                              
          <TextDisplay
            id={null}
            label='Positioner Type'
            text={!device.positionerType ? 'WRONG' : device.positionerType}
             type='tile' spec
          />                                                
          <TextDisplay
            id={null}
            label='Fail Mode'
            text={!device.failMode ? '- ' : device.failMode}
             type='tile' spec
          />*/}
      </React.Fragment>
    );
  }
  renderDiagnosticSpecs() {
    //const device = this.props.device;
    const activeProfileId = this.props.device.activeProfileId;
    const device = this.props.device;
    //console.log(profileTestSpecs);
    if (!!this.props.tests && !!this.props.tests.diagnosticsSpecs && !!this.props.tests.diagnosticsSpecs[activeProfileId] && (this.props.activeTab === 'Diagnostics' || this.props.diagnosticsSpecs)) {
      //console.log(this.props.tests.diagnosticsSpecs[activeProfileId]);
      const diagnosticsSpecs = this.props.tests.diagnosticsSpecs[activeProfileId];
      return (
        <React.Fragment>
          <TextDisplay id={null} label='Travel' text={!diagnosticsSpecs.travel ? '- ' : diagnosticsSpecs.travel} type='tile' spec />
          <TextDisplay id={null} label='Seat Contact' text={!diagnosticsSpecs.seatContact ? '- ' : diagnosticsSpecs.seatContact} type='tile' spec />
          <TextDisplay id={null} label='Full Open' text={!diagnosticsSpecs.fullOpen ? '- ' : diagnosticsSpecs.fullOpen} type='tile' spec />
          <TextDisplay id={null} label='Seat Contact I/P' text={!diagnosticsSpecs.seatContactIP ? '- ' : diagnosticsSpecs.seatContactIP} type='tile' spec />
          <TextDisplay id={null} label='Full Open I/P' text={!diagnosticsSpecs.fullOpenIP ? '- ' : diagnosticsSpecs.fullOpenIP} type='tile' spec />
          <TextDisplay id={null} label='Dyn. Err. (Mean)' text={!diagnosticsSpecs.dynamicErrorMeanPerc ? '- ' : `${diagnosticsSpecs.dynamicErrorMeanPerc}%`} type='tile' spec />
          <TextDisplay id={null} label='Dyn. Err. (Max)' text={!diagnosticsSpecs.dynamicErrorMaxPerc ? '- ' : `${diagnosticsSpecs.dynamicErrorMaxPerc}%`} type='tile' spec />
          <TextDisplay id={null} label='Bench Set (Min)' text={!diagnosticsSpecs.benchSetMin ? '- ' : diagnosticsSpecs.benchSetMin} type='tile' spec />
          <TextDisplay id={null} label='Bench Set (Max)' text={!diagnosticsSpecs.benchSetMax ? '- ' : diagnosticsSpecs.benchSetMax} type='tile' spec />
          <TextDisplay id={null} label='Seat Load' text={!diagnosticsSpecs.seatLoad ? '- ' : diagnosticsSpecs.seatLoad} type='tile' spec />
          <TextDisplay id={null} label='Hold Time' text={!device.holdTime ? '- ' : device.holdTime} type='tile' spec />
          <TextDisplay id={null} label='Ramp Time' text={!device.rampTime ? '- ' : device.rampTime} type='tile' spec />
          <TextDisplay id={null} label='Start Ramp' text={!device.startRamp ? '- ' : device.startRamp} type='tile' spec />
          <TextDisplay id={null} label='Stop Ramp' text={!device.stopRamp ? '- ' : device.stopRamp} type='tile' spec />
        </React.Fragment>
      );
    } else {
      return null;
    }
  }
  renderMoreEvents() {
    const device = this.props.device;
    const eventCount = device.events.length - 4;
    if (eventCount > 0) {
      return <Typography variant='subtitle2'>... {eventCount} More Event(s) Found</Typography>;
    }
  }
  //TODO resolution,sensitivity etc driven from 'concern' prop, cleaner to add optimize variables to state of OptimizeProgress.js
  render() {
    const { classes } = this.props;
    const theme = initializeTheme(this.props.colorMode);
    const hasBorder = this.state.hasBorder;
    const device = this.props.device;
    const compareDevice = this.props.compareDevice;
    const compareTo = this.props.compareTo;
    const printStyles = functions.setPrintStyles(this.props.activeTab);
    let content = <div>Could not find data for this device!</div>;
    if (this.props.device) {
      content = (
        <Grid container spacing={1}>
          <Grid item sm={this.props.format || this.props.activeTab === 'certification' ? 2 : 12} md={2}>
            <Typography variant='subtitle2' className={printStyles ? classes.printDeviceInfo : classes.deviceInfo}>
              {!device.site ? 'Site Not Found' : device.site} {!device.application ? null : '- ' + device.application}
            </Typography>
            <Typography variant='subtitle2' className={printStyles ? classes.printDeviceInfo : classes.deviceInfo}>
              {!device.importance || device.importance === 'Unknown' ? null : device.importance}
            </Typography>
            <Typography variant='subtitle2' className={printStyles ? classes.printDeviceInfo : classes.deviceInfo}>
              Optimize Valve
            </Typography>
            <Typography style={theme.palette.blue} variant='subtitle2' className={printStyles ? classes.printDeviceInfo : classes.deviceInfo}>
              {!device.name ? 'No Device Exists' : device.name}
            </Typography>
            <Typography style={theme.palette.blue} variant='subtitle2' className={printStyles ? classes.printDeviceInfo : classes.deviceInfo}>
              {!device ? 'Unknown Test Date' : functions.dateToString(device.dateTime * 1000, 'date')}
            </Typography>
            <Typography style={theme.palette.blue} variant='subtitle2' className={printStyles ? classes.printDeviceInfo : classes.deviceInfo}>
              {!device ? 'Unknown Test Time' : functions.dateToString(device.dateTime * 1000, 'time')}
            </Typography>
            {compareTo === 'No Comparison' ? null : (
              <React.Fragment>
                <Typography variant='subtitle2' style={{ marginTop: 10 }} className={printStyles ? classes.printDeviceInfo : classes.deviceInfo}>
                  Compared To
                </Typography>
                <Typography style={theme.palette.blue} variant='subtitle2' className={printStyles ? classes.printDeviceInfo : classes.deviceInfo}>
                  {!compareDevice ? 'No Comparison Device' : compareDevice.name}
                </Typography>
                <Typography style={theme.palette.blue} variant='subtitle2' className={printStyles ? classes.printDeviceInfo : classes.deviceInfo}>
                  {!compareTo ? 'Unknown Test Date' : functions.dateToString(new Date(compareTo).getTime(), 'date')}
                </Typography>
                <Typography style={theme.palette.blue} variant='subtitle2' className={printStyles ? classes.printDeviceInfo : classes.deviceInfo}>
                  {!compareTo ? 'Unknown Test Time' : functions.dateToString(new Date(compareTo).getTime(), 'time')}
                </Typography>
              </React.Fragment>
            )}
            {!!this.props.device && !!this.props.device.deviceId && this.props.device.deviceId === 8515 && this.props.device.activeProfileId === 10158 ? (
              <Button variant='text' onClick={() => window.open(SamplePDF /*'https://www.africau.edu/images/default/sample.pdf'*/)} startIcon={<FilePresent />} style={{ marginLeft: -6 }}>
                <Typography variant='subtitle2' style={{ marginTop: 4 }}>
                  Original Test
                </Typography>
              </Button>
            ) : null}
            {!!this.props.device && !!this.props.device.deviceId && this.props.device.deviceId === 8515 && this.props.device.activeProfileId === 10158 ? (
              <Grid container>
                <Grid item>
                  <img alt='Benchmark Logo' src={this.props.colorMode === 'dark' ? BenchmarkLogoDark : BenchmarkLogo} style={{ maxWidth: 110, width: '100%', marginTop: 6 }} />
                </Grid>
              </Grid>
            ) : null}
            {/*<Grid item>
              <Grid containerdirection='column' justifyContent='space-between'>
                <Grid item></Grid>
                <Grid item>
                  <Tooltip arrow placement='top' title={!!this.state.moreSpecs ? 'Show Less Specs' : 'Show More Specs'} aria-label={!!this.state.moreSpecs ? 'Show Less Specs' : 'Show More Specs'}>
                    <IconButton
                      className={`${classes.moreSpecs} ${classes.printHide}`}
                      onClick={() =>
                        this.setState({
                          moreSpecs: !this.state.moreSpecs,
                        })
                      }
                      style={this.state.allSpecs || this.props.diagnosticsToggle ? { display: 'none' } : null}
                      size='large'
                    >
                      {this.state.moreSpecs ? <KeyboardArrowUp></KeyboardArrowUp> : <KeyboardArrowDown></KeyboardArrowDown>}
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>*/}
          </Grid>
          <Grid item sm={this.props.format || this.props.activeTab === 'certification' ? 10 : 12} md={10}>
            <Grid item>
              <Grid container>
                <TextDisplay id={null} label='Serial #' text={!device.serial ? '- ' : device.serial} type='tile' spec />
                <TextDisplay id={null} label='Valve Mfgr' text={!device.valveMfgr ? '- ' : device.valveMfgr === 'Dresser/Masoneilan' ? 'Masoneilan' : device.valveMfgr} type='tile' spec />
                <TextDisplay id={null} label='Valve Type' text={!device.valveType ? '- ' : device.valveType} type='tile' spec />
                <TextDisplay id={null} label='Body Type' text={!device.bodyType ? '- ' : device.bodyType} type='tile' spec />
                <TextDisplay id={null} label='Body Size' text={!device.bodySize ? '- ' : device.bodySize} type='tile' spec />
                <TextDisplay id={null} label='Valve Model' text={!device.valveModel ? '- ' : device.valveModel} type='tile' spec />
                <TextDisplay id={null} label='Positioner Type' text={!device.positionerType ? '- ' : device.positionerType} type='tile' spec />
                <TextDisplay id={null} label='Positioner' text={!device.positioner ? '- ' : device.positioner} type='tile' spec />
                <TextDisplay id={null} label='Act. Type' text={!device.actuatorType ? '- ' : device.actuatorType} type='tile' spec />
                <TextDisplay id={null} label='Act. Size (in2)' text={!device.actuatorSizeModel ? '- ' : device.actuatorSizeModel} type='tile' spec />
                <TextDisplay id={null} label='Air Action' text={!device.airAction ? '- ' : device.airAction} type='tile' spec />
                <TextDisplay id={null} label='Fail Mode' text={!device.failMode ? '- ' : device.failMode} type='tile' spec />
                <TextDisplay id={null} label='Signal Source' text={!device.signalSource ? '- ' : device.signalSource} type='tile' spec />
                <TextDisplay id={null} label='CV' text={!device.cv ? '- ' : device.cv} type='tile' spec />
                <TextDisplay id={null} label='Nom. Stroke' text={!device.nomStroke ? '- ' : device.nomStroke} type='tile' spec />
                <TextDisplay id={null} label='Spring Range' text={!device.springRange ? '- ' : device.springRange} type='tile' spec />
                {/*<TextDisplay
                id={null}
                label='Nom. Stroke Expected'
                text={!device.nomStroke ? '- ' : device.nomStroke}
                 type='tile' spec
              />*/}
                {/*<TextDisplay
                id={null}
                label='Nom. Stroke'
                text={
                  !device.nomStrokeActual ? '- ' : device.nomStrokeActual
                }
                 type='tile' spec
              />*/}
                {/*<TextDisplay
                id={null}
                label='Calibration'
                text={!device.calibration ? '- ' : device.calibration}
                 type='tile' spec
              />*/}
                {this.renderDiagnosticSpecs()}
                {this.renderMoreSpecs()}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid item xs={12} className={classes.widgetMargin}>
        <Paper className={printStyles ? classes.printPaper : classes.tilePaper} elevation={3} style={{ border: hasBorder ? '2px solid' : '' }}>
          {content}
        </Paper>
      </Grid>
    );
  }
}

OptimizeSpecs.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    isSignedIn: state.auth.isSignedIn,
    userId: state.auth.userId,
    locations: state.locations,
    errors: state.errors,
    events: state.events,
    devices: state.devices.devices,
    tests: state.tests.tests,
    colorMode: state.colorMode.colorMode,
    activeTab: state.optimize.activeTab,
    format: state.format.format,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(OptimizeSpecs));
