import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { Typography, Grid, Divider, Paper, Tooltip, CircularProgress, MenuItem, ClickAwayListener, Popper, IconButton } from '@mui/material';
import { MoreVert, History, Speed } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import initializeTheme from '../theme';
import TextDisplay from '../filters/TextDisplay';
import PrioritizeProgress from './PrioritizeProgress';
import ProgressGuide from './ProgressGuide';
import { connect } from 'react-redux';
import { styles } from '../styles';
import TableComponent from '../charts/Table';
import functions from '../../functions/functions';
//import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

class PrioritizeTile extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      hasBorder: false,
      tileExpanded: false,
      selectMenu: false,
      valveTestMenu: false,
      anchor: null,
    };
  }
  componentDidMount() {
    //componentDidUpdate() {
    if (this.props.hasBorder && this.setState.hasBorder) {
      this.setState({ hasBorder: true });
    }
    if (this.props.expanded && !this.setState.tileExpanded) {
      this.setState({ tileExpanded: true });
    }
  }
  handleTileExpand = (e) => {
    e.preventDefault();
    this.setState({
      tileExpanded: !this.state.tileExpanded,
    });
  };
  renderLoading() {
    const { classes } = this.props;
    if (this.state.loading) {
      return (
        <React.Fragment>
          <CircularProgress className={classes.loadIcon} size={15} color='secondary' />
          Loading...
        </React.Fragment>
      );
    } else {
      return;
    }
  }

  processImportance(importance) {
    switch (importance) {
      case 5:
        return 'Extremely Important';
      case 4:
        return 'Very Important';
      case 3:
        return 'Important';
      case 2:
        return 'Standard';
      case 1:
        return 'Not Important';
      default:
        return 'Standard';
    }
  }
  /*renderMoreEvents() {
    const loop = this.props.loop;

    const eventCount = !loop.events ? 0 : loop.events.length - 4;
    if (eventCount > 0) {
      return (
        <Typography variant='subtitle2'>
          ... {eventCount} More Event(s) Found
        </Typography>
      );
    }
  }*/
  renderHistoricalIcon() {
    const { classes } = this.props;
    //TODO Prio_1.0 historical flag - put next to device name in table
    return (
      <Tooltip
        arrow
        placement='top'
        title={
          <React.Fragment>
            <Grid>
              <Typography variant='caption'> This loop contains historical data that may be over 2 years old.</Typography>
            </Grid>
            <Grid>
              <Typography variant='caption' style={{ marginTop: 4 }}>
                Last tested on ___
              </Typography>
            </Grid>
          </React.Fragment>
        }
        enterDelay={300}
      >
        <IconButton
          className={classes.checklistInfo}
          style={
            this.props.disableHover
              ? {
                  backgroundColor: 'transparent',
                }
              : null
          }
          color='inherit'
          aria-label='information'
          size='large'
        >
          <History color='inherit' style={{ height: 18, width: 18 }}></History>
        </IconButton>
      </Tooltip>
    );
  }
  renderValveTest(loop) {
    const { classes } = this.props;
    const handleClose = () => {
      this.setState({ valveTestMenu: false });
    };
    const handleClickValveTests = (e) => {
      e.preventDefault();
      this.setState({ valveTestMenu: !this.state.valveTestMenu, anchor: e.currentTarget });
    };
    if (!!loop && !!loop.device_ids) {
      for (let i = 0; i < loop.device_ids.length; i++) {
        const handleClickLink = (e) => {
          e.preventDefault();
          let win = window.open(`/optimize/${loop.optimize_device_ids[i]}/certification`, '_blank');
          win.focus();
        };
        let deviceCheck =
          loop.optimize_device_ids.filter(function (x) {
            return x !== 'null';
          }).length > 1;
        if (!!loop && !!loop.optimize_device_ids && loop.optimize_device_ids[i] !== 'null' && deviceCheck) {
          return (
            <React.Fragment>
              {console.log()}
              <Tooltip arrow placement='top' title='Latest Optimize Test' enterDelay={300}>
                <IconButton size='large' onClick={handleClickValveTests}>
                  <Speed className={classes.iconGray} />
                </IconButton>
              </Tooltip>
              <Popper open={this.state.valveTestMenu} onClose={handleClose} anchorEl={this.state.anchor} placement='bottom-start'>
                <ClickAwayListener onClickAway={handleClose}>
                  <Paper>
                    <Typography variant='caption' style={{ padding: '10px 12px 6px 14px', display: 'block' }}>
                      Select a valve to view
                    </Typography>
                    {loop.optimize_device_ids.map((device_id) => {
                      const handleClickTest = (e) => {
                        e.preventDefault();
                        let win = window.open(`/optimize/${device_id}/certification`, '_blank');
                        win.focus();
                      };
                      if (device_id !== 'null') {
                        return (
                          <MenuItem key={device_id} onClick={handleClickTest}>
                            Valve {device_id}
                          </MenuItem>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </Paper>
                </ClickAwayListener>
              </Popper>
            </React.Fragment>
          );
        } else if (!!loop && !!loop.optimize_device_ids && loop.optimize_device_ids[i] !== 'null') {
          return (
            <Tooltip arrow placement='top' title='Latest Optimize Test' enterDelay={300}>
              <IconButton size='large' onClick={handleClickLink}>
                <Speed className={classes.iconGray} />
              </IconButton>
            </Tooltip>
          );
        }
      }
    }
  }
  renderExpandedTile() {
    const { classes } = this.props;
    const headerArr = [
      { id: 'deviceId', label: 'Device' },
      { id: 'deviceType', label: 'Device Type' },
      { id: 'description', label: 'Description' },
      { id: 'concernScore', label: 'Concern Score' },
      { id: 'concernShift', label: 'Concern Shift' },
      { id: 'concernMax', label: 'Concern Max' },
      { id: 'importance', label: 'Importance' },
    ];
    const loop = this.props.loops[this.props.activeLoop];
    const printStyles = functions.setPrintStyles(this.props.activeTab);
    let dataArr = [];
    //console.log(!loop ? 'Not Ready' : loop);
    if (!!loop && !!loop.device_ids) {
      for (let i = 0; i < loop.device_ids.length; i++) {
        let device = this.props.devices[parseInt(loop.device_ids[i])];
        if (!!device && !!device.processName) {
          dataArr.push({
            id: device.device_id,
            deviceId: device.name,
            deviceType: (
              <React.Fragment>
                <Typography>
                  {`${device.deviceType}`}
                  {/*!device.optimize_id ? null : (
                    <InfoPopover
                      center
                      tooltip='Latest Optimize Test'
                      title='Latest Optimize Test'
                      titleIcon='Speed'
                      resize
                      style={{ marginBottom: -15 }}
                      icon={<Speed />}
                      content={<OptimizeTile hasLink={!printStyles} hasBorder key={`${this.props.loops[device.device_id]}_optimize_test`} device={device} prioritize />}
                    />
                  )*/}
                </Typography>
              </React.Fragment>
            ),
            description: !device.description ? null : device.description.length > 12 && !this.props.format ? (
              <Tooltip arrow placement='top' title={device.description} enterDelay={300} style={{ cursor: 'pointer' }}>
                <span>{device.description.substring(0, 12)} ...</span>
              </Tooltip>
            ) : (
              device.description
            ),
            concernScore: device.concernAdjusted,
            concernShift: Math.round(device.concernShift),
            concernMax: Math.round(device.concernMax),
            importance: device.importance,
          });
        }
      }
    }

    /*{
        deviceId: 'testID',
        deviceType: 'Valve',
        concernScore: 70,
        concernShift: 5,
        concernMax: 72,
        importance: 'Standard',
      },*/

    if (this.state.tileExpanded) {
      return (
        <Grid item xs={12} className={printStyles ? null : classes.prioritizeTextMargin}>
          <TableComponent data={dataArr} header={headerArr} rows={25} tileTable printHidePagination></TableComponent>
        </Grid>
      );
    } else {
      return null;
    }
  }
  parseanalysisRange = (range) => {
    switch (range) {
      case '7':
        return { analysisRange: 7, analysisRangeShift: 7 };
      case '14':
        return { analysisRange: 14, analysisRangeShift: 14 };
      case '30':
        return { analysisRange: 30, analysisRangeShift: 30 };
      case '90':
        return { analysisRange: 90, analysisRangeShift: 30 };
      case '180':
        return { analysisRange: 180, analysisRangeShift: 90 };
      case '365':
        return { analysisRange: 365, analysisRangeShift: 180 };
      default:
        return { analysisRange: 90, analysisRangeShift: 30 };
    }
  };
  getLoopOpenIssues(loop) {
    let content = (
      <Tooltip
        placement='top'
        title={
          <React.Fragment>
            <Typography color='inherit' variant='subtitle2'>
              {!loop || !loop.issues ? 0 : loop.issues.length} Issue{!loop || !loop.issues || loop.issues.length === 1 ? '' : 's'}
            </Typography>
            {!loop || !loop.issues
              ? null
              : loop.issues.map((issue) => {
                  let issueString = issue.toString();
                  issueString = issueString.replace(loop.loopId, '');
                  return (
                    <span key={issue}>
                      {issueString}
                      <br />
                    </span>
                  );
                })}
          </React.Fragment>
        }
      >
        <span style={{ cursor: 'pointer' }}>{!loop || !loop.issues ? 0 : loop.issues.length}</span>
      </Tooltip>
    );
    return content;
  }
  getDeviceTypeList(loop) {
    let obj = {};
    let str = '';
    for (let i = 0; i < loop.deviceTypes.length; i++) {
      if (!(loop.deviceTypes[i] in obj)) {
        obj[loop.deviceTypes[i]] = 1;
      } else {
        obj[loop.deviceTypes[i]]++;
      }
    }
    let objKeys = Object.keys(obj).sort();
    for (let i = 0; i < objKeys.length; i++) {
      str += `${objKeys[i]} (${obj[objKeys[i]]})`;
      if (i + 1 !== objKeys.length) {
        str += ', ';
      }
    }
    //return loop.deviceTypesDistinct;
    return str;
  }
  render() {
    const theme = initializeTheme(this.props.colorMode);
    const { classes } = this.props;
    const hasBorder = this.state.hasBorder;
    const loop = this.props.loop;
    const openIssuesContent = this.getLoopOpenIssues(loop);
    let content = <div>Could not find data for this loop!</div>;
    //console.log(this.props.analysisRange);
    const { analysisRange, analysisRangeShift } = this.parseanalysisRange(this.props.analysisRange);

    // let borderColor;
    // {if (loop.concern < 25) {
    //   borderColor = classes.greenBorder;
    // } else if (loop.concern < 75) {
    //   borderColor = classes.yellowBorder;
    // } else {
    //   borderColor = classes.redBorder;
    // }
    const handleClick = (e) => {
      e.preventDefault();
      this.setState({
        selectMenu: !this.state.selectMenu,
        selectMenuAnchor: e.currentTarget,
      });
    };
    const handleClose = () => {
      this.setState({ selectMenu: false });
    };
    const printStyles = functions.setPrintStyles(this.props.activeTab);
    if (this.props.loop) {
      //console.log(loop);
      content = (
        <Grid container justifyContent='space-between' spacing={printStyles ? 1 : 2}>
          <Grid item xs={12} sm={4} lg={4}>
            <Grid container justifyContent='space-between'>
              <Grid item>
                <Typography variant='subtitle2'></Typography>
                <Typography variant='subtitle2'>
                  {!loop.site ? 'Site Not Found' : loop.site}
                  {!loop.location ? ' - Unit Not Found' : ` - ${loop.location}`}
                </Typography>
                <Grid container spacing={1}>
                  <Grid item>
                    <Typography style={theme.palette.blue} variant='subtitle2'>
                      {!loop.processName ? 'No Device Exists' : loop.processName}
                    </Typography>
                  </Grid>
                  {/* TODO Prio_1.0 <Grid item style={{ marginTop: -3 }}>
                    {this.renderHistoricalIcon()}
      </Grid>*/}
                </Grid>
              </Grid>
              {!this.props.hasLink ? null : (
                <Grid item>
                  <Tooltip arrow placement='top' title='Quick Navigation' aria-label='Quick Navigation'>
                    <IconButton color='inherit' aria-label='more' onClick={handleClick} style={{ marginRight: -10 }} size='large'>
                      <MoreVert />
                    </IconButton>
                  </Tooltip>
                  <Popper open={this.state.selectMenu} onClose={handleClose} anchorEl={this.state.selectMenuAnchor} placement='bottom-start'>
                    <ClickAwayListener onClickAway={handleClose}>
                      <Paper elevation={3}>
                        <MenuItem component={Link} to={`/prioritize/${this.props.loop.loopId}/workbench`}>
                          Workbench
                        </MenuItem>
                        <MenuItem component={Link} to={`/prioritize/${this.props.loop.loopId}/issues`}>
                          Issues
                        </MenuItem>
                        <MenuItem component={Link} to={`/prioritize/${this.props.loop.loopId}/actions`}>
                          Action Items
                        </MenuItem>
                      </Paper>
                    </ClickAwayListener>
                  </Popper>
                </Grid>
              )}
              <Divider className={classes.dividerMd} />
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant='subtitle2'>{loop.application}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item>
                      <Typography variant='subtitle2' className={classes.deviceInfo}>
                        {!loop.devices || loop.devices === 'undefined' ? 'Unknown Devices' : loop.devices.length} Device{!loop.devices || loop.devices.length === 1 ? '' : 's'}
                        {!loop.deviceTypes || loop.deviceTypes === 'undefined' ? null : ` - ${this.getDeviceTypeList(loop)}`}
                      </Typography>
                    </Grid>
                    <Grid item style={{ marginTop: -4, marginLeft: 8, marginBottom: -8 }}>
                      {this.renderValveTest(loop)}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='subtitle2'>Application Order - {!loop.applicationOrder ? 'Unknown' : loop.applicationOrder}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='subtitle2'>{!loop.disruptionType ? null : loop.disruptionType}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={printStyles ? 8 : 6} lg={printStyles ? 8 : 4} className={classes.prioritizeConcernMargin}>
            <PrioritizeProgress label={`Concern (${analysisRangeShift} Day Shift)`} concern={loop.concern} concernShift={Math.round(loop.concernShift * 100) / 100} />
            <ProgressGuide value={loop.concern}></ProgressGuide>
            <Typography variant='subtitle2' style={{ marginTop: 10 }}>
              Max ({analysisRange} Days): {!loop.concernMax ? 'N/A' : <span className={classes[`${functions.concernToColorClass(loop.concernMax)}Text`]}>{Math.round(loop.concernMax)}</span>}
            </Typography>
          </Grid>
          <Grid item xs={12} md={12} lg={printStyles ? 12 : 4} className={printStyles ? null : classes.prioritizeTextMargin}>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <TextDisplay label='Importance' id='Importance' text={this.processImportance(loop.importance_max)} type='prioritize' hasHelper />
              </Grid>
              <Grid item xs={4}>
                <TextDisplay label='Open Issues' id='Issues' text={!loop.issues || !loop.issues.length ? 0 : openIssuesContent} type='prioritize' hasHelper />
              </Grid>
              <Grid item xs={4}>
                <TextDisplay label='Action Items' id='Actions' text={!loop.actions || !loop.actions.length ? 0 : loop.actions.length} type='prioritize' hasHelper />
              </Grid>
            </Grid>
            {/*{!loop.events
              ? ''
              : loop.events
                  .slice(0, 4)
                  .map((event) =>
                    !this.props.events[event] ? (
                      <EventDisplay
                        className={classes.event}
                        key={event}
                        event={'Event Not Found'}
                      />
                    ) : (
                      <EventDisplay
                        className={classes.event}
                        key={event}
                        event={this.props.events[event].eventName}
                      />
                    )
                    )}*/}
          </Grid>
          {/*<Grid item>
            <IconButton
              className={`${classes.moreSpecs} ${classes.printHide}`}
              onClick={(e) => this.handleTileExpand(e)}
            >
              {this.state.tileExpanded ? (
                <KeyboardArrowUp></KeyboardArrowUp>
              ) : (
                <KeyboardArrowDown></KeyboardArrowDown>
              )}
            </IconButton>
              </Grid>*/}
          {/*<Grid item>{this.renderMoreEvents()}</Grid>*/}
          {this.renderExpandedTile()}
        </Grid>
      );
    }
    if (this.props.hasLink) {
      return (
        <Grid item xs={12} className={classes.textDecoNone} component={Link} to={`/prioritize/${this.props.loop.loopId}/workbench`}>
          <Paper
            className={`${printStyles ? classes.printPrioritizeTile : classes.prioritizeTile} ${classes.avoidBreak}`}
            style={{ marginBottom: this.props.colorMode === 'dark' ? 6 : 4 }}
            elevation={3}
            //classes={{ root: classes.primaryBorder }}
          >
            {content}
          </Paper>
        </Grid>
      );
    } else {
      return (
        <Grid item xs={12} className={classes.textDecoNone}>
          <Paper
            className={`${printStyles ? classes.printPrioritizeTile : classes.prioritizeTileWidget} ${classes.avoidBreak}`}
            style={{ border: hasBorder ? '1px solid' : '', marginBottom: this.props.colorMode === 'dark' ? 6 : 4 }}
            elevation={3}
          >
            {content}
          </Paper>
        </Grid>
      );
    }
  }
}

PrioritizeTile.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    isSignedIn: state.auth.isSignedIn,
    userId: state.auth.userId,
    locations: state.locations,
    errors: state.errors,
    events: state.events,
    loops: state.loops.loops,
    devices: state.devices.devices,
    activeLoop: state.prioritize.activeLoop,
    analysisRange: state.sort.filter.AnalysisRange,
    colorMode: state.colorMode.colorMode,
    format: state.format.format,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(PrioritizeTile));
