import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';
import { Typography, Grid } from '@mui/material';
import { styles } from '../styles';

class FastFieldDisplay extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <Grid item xs={12}>
        <Grid container className={classes.field} style={this.props.last ? { borderBottomWidth: 1 } : null}>
          <Grid item xs={6} className={classes.fieldName}>
            <Typography variant='subtitle2'>{this.props.label}</Typography>
          </Grid>
          <Grid item xs={6} className={classes.fieldValue}>
            {this.props.image}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

FastFieldDisplay.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    colorMode: state.colorMode.colorMode,
    activeTab: state.optimize.activeTab,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles, { withTheme: true })(FastFieldDisplay));
