import React from 'react';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../styles';
//import initializeTheme from '../theme';
import { Slide, IconButton, Typography, Grid } from '@mui/material';
import FastFieldImage from './FastFieldImage';
import { Close, ArrowBack, ArrowForward, Download } from '@mui/icons-material';
import CustomDialog from '../popup/CustomDialog';
import functions from '../../functions/functions';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const ImageGallery = function ImageGallery(props) {
  //const theme = initializeTheme(props.colorMode);
  const { classes } = props;
  const [selectedTile, setSelectedTile] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(null);
  //const [value, setValue] = React.useState([]);
  const printStyles = functions.setPrintStyles(props.activeTab);

  const handleClickOpen = (tile, index) => {
    setSelectedTile(tile);
    setSelectedIndex(index);
    //console.log('clicked');
    //console.log(tile);
  };

  const handleClose = () => {
    setSelectedTile(null);
    setSelectedIndex(null);
  };

  const incrementImage = (change) => {
    console.log(change);
    console.log(props.images);
    let newIndex = selectedIndex + change;
    if (newIndex > props.images.length - 1) {
      newIndex = 0;
    }
    if (newIndex < 0) {
      newIndex = props.images.length - 1;
    }
    console.log(selectedIndex);
    console.log(newIndex);
    setSelectedTile(props.images[newIndex]);
    setSelectedIndex(newIndex);
  };

  const downloadImage = () => {
    const link = document.createElement('a');
    link.download = selectedTile;
    link.href = selectedTile;
    link.click();
  };

  //console.log(selectedTile);
  //console.log(props);
  return (
    <React.Fragment>
      <Grid container spacing={2}>
        {!props.images
          ? null
          : props.images.map((src, index) => {
              return (
                <Grid item key={`list_device_img_${index}`}>
                  <div
                    onClick={() => {
                      handleClickOpen(src, index);
                    }}
                  >
                    {!printStyles ? (
                      <FastFieldImage thumbnail key={`device_img_${index}`} file={src} width={240} /*height={250}*/ />
                    ) : (
                      <FastFieldImage printThumbnail key={`device_img_${index}`} file={src} width={200} /*height={250}*/ />
                    )}
                  </div>
                </Grid>
              );
            })}
      </Grid>
      <CustomDialog
        center
        type='image'
        title={`Images for ${!props.tag || props.tag === 'Unknown' ? `Unknown Location` : `${props.tag}`}`}
        controls={
          <Grid item>
            <Grid container justifyContent='space-between' spacing={2}>
              <Grid item>
                <IconButton
                  disabled={selectedIndex === 0}
                  onClick={() => {
                    incrementImage(-1);
                  }}
                >
                  <ArrowBack />
                </IconButton>
              </Grid>
              <Grid item>
                <Typography variant='h6' className={classes.title} style={{ paddingTop: 3 }}>
                  {`${selectedIndex + 1} of ${props.images.length}`}
                </Typography>
              </Grid>
              <Grid item>
                <IconButton
                  disabled={selectedIndex === props.images.length - 1}
                  onClick={() => {
                    incrementImage(1);
                  }}
                >
                  <ArrowForward />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton
                  onClick={() => {
                    downloadImage();
                  }}
                  className={classes.textColor}
                >
                  <Download />
                </IconButton>
              </Grid>
              <Grid item>
                <Grid container justifyContent='space-between' spacing={2}>
                  <Grid item></Grid>
                  <Grid item>
                    <IconButton edge='start' color='inherit' onClick={handleClose} aria-label='close' className={classes.textColor}>
                      <Close />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
        titleIcon='Image'
        open={selectedTile !== null}
        onClose={handleClose}
        TransitionComponent={Transition}
        maxWidth='md'
        content={<FastFieldImage width={720} file={selectedTile} dialog={true} />}
      ></CustomDialog>
    </React.Fragment>
  );
};

export default withStyles(styles)(ImageGallery);
