const header_arr = [
  {
    headerName: 'Details',

    sub_items: [
      { id: 'technician', label: 'Technician' },
      { id: 'serial', label: 'Serial' },
      { id: 'is_follow_up', label: 'Follow Up?' },
      { id: 'previous_visit_reference', label: 'Reference #' },
      { id: 'pmo_no', label: 'PMO #', ignore_null: true },
      { id: 'work_order_no', label: 'Work Order #', ignore_null: true },

      /*{ id: 'as_found_value_rounded_pH', label: 'As Found (pH)' },
      { id: 'as_left_value_rounded_pH', label: 'As Left (pH)' },
      { id: 'change_value_rounded_pH', label: 'Value Change (pH)' },
      { id: 'as_found_value_rounded_orp', label: 'As Found (ORP)' },
      { id: 'as_left_value_rounded_orp', label: 'As Left (ORP)' },
      { id: 'change_value_rounded_orp', label: 'Value Change (ORP)' },*/
      { id: 'repair_required', label: 'Additional Repair Required' },
      { id: 'follow_up_required', label: 'Is Owner Follow-Up Required?' },
      { id: 'overall_comments', label: 'Visit Comments' },
      { id: 'tag_comments', label: 'Tag Comments' },
      { id: 'deficiencies_communicated', label: 'Was Customer Notified?' },
      { id: 'person_notified', label: 'Person Notified' },

      //{ id: '', label: '' },
      //{ id: '', label: '' },
      //{ id: '', label: '' },
    ],
  },
  {
    headerName: 'Measurements',
    sub_items: [
      { id: 'measurement_range_low', label: 'Measurement Range Low' },
      { id: 'measurement_range_high', label: 'Measurement Range High' },
      { id: 'maximum_dcs_deviation', label: 'Maximum DCS Deviation' },
      { id: 'dcs_measurement_af', label: 'DCS As Found' },
      { id: 'field_measurement_af', label: 'Field As Found' },
      { id: 'dcs_measurement_al', label: 'DCS As Left' },
      { id: 'field_measurement_al', label: 'Field As Left' },
      { id: 'within_dcs', label: 'Is Measurement within DCS Deviation?' },
    ],
  },
  //{ headerName: 'Status' },

  {
    headerName: 'Field Identification',
    sub_items: [
      { id: 'is_tag_label_damaged', label: 'Is label/tag with unique identifier (e.g. Tag Number) missing, damaged, or unreadable?', pass_values: ['No', 'N/A'] },
      { id: 'is_special_function_label_damaged', label: 'Is label/tag for special function (e.g. SIS) missing, damaged or unreadable?', pass_values: ['No', 'N/A'] },
      { id: 'is_cable_label_damaged', label: 'Is cable label/tag missing, damaged or unreadable?', pass_values: ['No', 'N/A'] },
    ],
  },

  {
    headerName: 'Sample Handling/Conditioning System',
    sub_items: [
      { id: 'shs_corrosion', label: 'Any signs of corrosion or leaks in SHS?', pass_values: ['No', 'N/A'] },
      { id: 'shs_connections', label: 'Are there any missing fittings, bolts, or damage to SHS?', pass_values: ['No', 'N/A'] },
      { id: 'shs_indicators_damaged', label: 'Any local indicators (e.g. gauges, rotameters) missing, damaged or unreadable?', pass_values: ['No', 'N/A'] },
      { id: 'shs_pressure_flow_values', label: 'Are all pressures and flows set to correct values?', pass_values: ['Yes', 'N/A'] },
      { id: 'shs_functioning', label: 'Are all SHS elements (e.g. filters, valves) functioning as designed?', pass_values: ['Yes', 'N/A'] },
      { id: 'shs_utilities_functioning', label: 'Are SHS purges and utilities (e.g. heaters) functioning as designed?', pass_values: ['Yes', 'N/A'] },
      { id: 'shs_heat_tracing_damage', label: 'Any loose parts, open areas, water ingress or damage to insulation or heat tracing?', pass_values: ['No', 'N/A'] },
    ],
  },
  {
    headerName: 'Electrical Connections',
    sub_items: [
      { id: 'loose_wiring', label: 'Any signs of loose connections or damage to cables/wiring?', pass_values: ['No', 'N/A'] },
      { id: 'cable_damaged', label: 'Any signs of corrosion or damage to cable tray or conduit?', pass_values: ['No', 'N/A'] },
      { id: 'covers_drains_plugs_missing', label: 'Are conduit covers, drains or plugs missing?', pass_values: ['No', 'N/A'] },
      { id: 'conduit_openings', label: 'Are plugs missing on unused analyzer conduit openings?', pass_values: ['No', 'N/A'] },
    ],
  },

  {
    headerName: 'Analyzer Condition',
    sub_items: [
      { id: 'analyzer_damage', label: 'Any signs of corrosion, loose parts, missing bolts or damage to analyzer?', pass_values: ['No', 'N/A'] },
      { id: 'indicator_damage', label: 'Any signs of damage to the local indicator (gauges, analog or digital meter)?', pass_values: ['No', 'N/A'] },
      { id: 'piping_damage', label: 'Any signs of corrosion, leaks or damage to analyzer piping, tubing or process connections?', pass_values: ['No', 'N/A'] },
      { id: 'analyzer_heat_tracing_damage', label: 'Any loose parts, open areas, water ingress or damage to insulation or heat tracing?', pass_values: ['No', 'N/A'] },
      { id: 'sensor_connection_damage', label: 'Any damage to cable from sensor to transmitter? Any issue with locking collar (if applicable, e.g. E+H Memosens)?', pass_values: ['No', 'N/A'] },
      { id: 'probe_damage', label: 'Any buildup of material that would prevent probe from measuring process?', pass_values: ['No', 'N/A'] },
      { id: 'probe_buildup', label: 'Any issue with sensor response time or transmitter readings?', pass_values: ['No', 'N/A'] },
    ],
  },

  {
    headerName: 'Documentation',
    sub_items: [
      { id: 'transmitter_issue', label: 'Any discrepancies between field installation and records in CMMS (e.g. SAP)?', pass_values: ['No', 'N/A'] },
      { id: 'sap_discrepancy', label: "Any discrepancies between field installation and P&ID's?", pass_values: ['No', 'N/A'] },
    ],
  },
  {
    headerName: 'Signature',

    sub_items: [
      { id: 'owner_name', label: 'Owner Name' },
      { id: 'employee_number', label: 'Employee Number' },
      { id: 'signature_date', label: 'Date' },
      { id: 'owner_signature', label: 'Signature', type: 'image' },
    ],
  },
  { headerName: 'Images', id: 'multi_photo_picker_1_photo' },
  { headerName: 'Map' },
];

const turned_away_arr = [
  {
    headerName: 'Details',

    sub_items: [
      { id: 'technician', label: 'Technician' },
      { id: 'serial', label: 'Serial' },
      { id: 'is_follow_up', label: 'Follow Up?' },
      { id: 'previous_visit_reference', label: 'Reference #', ignore_null: true },
      { id: 'pmo_no', label: 'PMO #', ignore_null: true },
      { id: 'work_order_no', label: 'Work Order #', ignore_null: true },
      { id: 'repair_required', label: 'Additional Repair Required' },
      { id: 'deficiencies_idenitied', label: 'Were Any Deficiencies Identified?' },
      { id: 'deficiency_category', label: 'Deficiency Category' },
      { id: 'deficiency_category_other', label: 'Other:', ignore_null: true },
      { id: 'overall_comments', label: 'Visit Comments' },
      { id: 'tag_comments', label: 'Tag Comments' },
      { id: 'return_to_operations', label: 'Device ready to return to Operations?' },
      { id: 'deficiencies_communicated', label: 'Was Customer Notified?' },
      { id: 'person_notified', label: 'Person Notified' },

      //{ id: '', label: '' },
      //{ id: '', label: '' },
      //{ id: '', label: '' },
    ],
  },
  //{ headerName: 'Status' },

  {
    headerName: 'Turned Away Details',
    sub_items: [
      { id: 'turned_away_reason', label: 'Reason' },
      { id: 'turned_away_additional_comments', label: 'Additional Comments' },
      { id: 'turned_away_dow_tags', label: 'Tags', ignore_null: true },
      { id: 'turned_away_olin_tags', label: 'Tags', ignore_null: true },
      { id: 'turned_away_signature_print', label: 'Printed Name' },
      { id: 'turned_away_customer_signature', label: 'Signature', type: 'image' },
      { id: 'turned_away_acknowledgement', label: 'Acknowledgement' },
    ],
  },
  { headerName: 'Images', id: 'multi_photo_picker_1_photo' },
  { headerName: 'Map' },
];

const getHeaderArr = (type) => {
  if (type === 'turned_away') {
    return turned_away_arr;
  } else {
    return header_arr;
  }
};

export default getHeaderArr;
