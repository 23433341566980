import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { Link } from 'react-router-dom';
import { Menu } from '@mui/icons-material';
import { connect } from 'react-redux';
import { AppBar, CssBaseline, Drawer, Hidden, IconButton, Toolbar } from '@mui/material';
import PageTitle from './PageTitle';
import DrawerList from './DrawerList';
import Display from '../Display';
import AppBarButtons from './AppBarButtons';
//import logo from '../../images/logo.svg';
import logoFull from '../../images/logoSplashWhite.svg';
import SearchBar from '../drawer/SearchBar';
import UserPanel from './UserPanel';
import Back from '../filters/Back';
import functions from '../../functions/functions';
import { styles } from '../styles';
import { setActiveNav } from '../../actions';
import { setFormat } from '../../actions';

import { Print } from '@mui/icons-material';
class ResponsiveDrawer extends React.Component {
  state = {
    mobileOpen: false,
    curTime: new Date(),
  };
  componentDidUpdate = () => {
    let url = window.location.href.toLowerCase();
    let title = 'Ten One';
    let date = `_${functions.dateToString(this.state.curTime, 'date')}`;
    let device = !this.props.devices[this.props.activeDevice] ? '' : this.props.devices[this.props.activeDevice];
    let deviceName = '';
    let loop = !this.props.loops[this.props.activeLoop] ? '' : this.props.loops[this.props.activeLoop];
    let module = !this.props.activeModule ? '' : this.props.activeModule;
    if (this.props.activeModule === 'Optimize') {
      deviceName = !device ? '' : `_${device.name}`;
    } else if (this.props.activeModule === 'Prioritize') {
      deviceName = !loop ? '' : `_${loop.processName}`;
    }
    if (url.includes('print') || this.props.activeTab === 'certification') {
      title = `${module}${deviceName}${date}`;
      document.title = title;
    } else {
      title = `Ten One ${!module ? '' : ` - ${module}`}`;
      document.title = title;
    }
  };
  handleDrawerToggle = () => {
    this.setState((state) => ({ mobileOpen: !state.mobileOpen }));
  };
  clickPrint = () => {
    window.print();
  };
  render() {
    const { classes, theme } = this.props;
    const printStyles = functions.setPrintStyles(this.props.activeTab);
    const drawer = (
      <React.Fragment>
        <div className={classes.logoContainer}>
          <Link to='/' exact='true' onClick={() => this.props.setActiveNav(null)}>
            {/*<img src={logo} alt='Ten One Logo' className={classes.logo} />*/}
            <img src={logoFull} alt='Ten One Logo' className={classes.logoFull} />
          </Link>
        </div>
        <UserPanel />
        <span className={!this.props.isSignedIn ? classes.displayNone : null}>
          <DrawerList />
        </span>
      </React.Fragment>
    );
    return (
      <div className={classes.flex}>
        <CssBaseline />
        <AppBar position='fixed' className={classes.appBar} elevation={0} style={printStyles ? { display: 'none' } : null}>
          <Toolbar className={!this.props.isSignedIn ? classes.displayNone : classes.toolBar}>
            <IconButton color='inherit' aria-label='Open drawer' onClick={this.handleDrawerToggle} className={classes.menuButton} size='large'>
              <Menu />
            </IconButton>
            <Back />
            <PageTitle />
            <SearchBar />
            <div className={classes.flexGrow} />
            <AppBarButtons />
          </Toolbar>
        </AppBar>
        <nav className={classes.drawer} style={printStyles ? { display: 'none' } : null}>
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden smUp implementation='css'>
            <Drawer
              container={this.props.container}
              variant='temporary'
              anchor={theme.direction === 'rtl' ? 'right' : 'left'}
              open={this.state.mobileOpen}
              onClose={this.handleDrawerToggle}
              className={classes.printHide}
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden mdDown implementation='css'>
            <Drawer
              className={classes.printHide}
              classes={{
                paper: classes.drawerPaper,
              }}
              variant='permanent'
              open
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
        <main className={classes.content} style={this.props.format || this.props.activeTab === 'certification' ? { width: 800, margin: 'auto' } : null}>
          {printStyles === false ? null : (
            <IconButton className={classes.printHide} color='inherit' onClick={() => this.clickPrint()} size='large' style={{ float: 'right', marginRight: -60, marginTop: 8 }}>
              <Print></Print>
            </IconButton>
          )}
          <Display />
        </main>
      </div>
    );
  }
}

ResponsiveDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  // Injected by the documentation to work in an iframe.
  // You won't need it on your project.
  container: PropTypes.object,
  theme: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    activeNav: state.nav.activeNav,
    activeTab: state.optimize.activeTab,
    format: state.format.format,
    isSignedIn: state.auth.isSignedIn,
    activeModule: state.currentModule.currentModule,
    activeDevice: state.optimize.activeDevice,
    activeLoop: state.prioritize.activeLoop,
    devices: state.devices.devices,
    loops: state.loops.loops,
  };
};

export default connect(mapStateToProps, { setActiveNav, setFormat })(withStyles(styles, { withTheme: true })(ResponsiveDrawer));
