//import { numberFormat } from 'highcharts';
import React from 'react';

function delay(time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

const getFileExtension = (fname) => {
  return fname.slice(((fname.lastIndexOf('.') - 1) >>> 0) + 2); //highest performing version
};

const setCookie = (cname, cvalue, exdays, sameSite) => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = !exdays ? '' : 'expires=' + d.toUTCString();
  let sameSiteStr = false && sameSite ? ';SameSite=none; Secure' : '';
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/' + sameSiteStr;
};

const getCookie = (cname) => {
  let name = cname + '=';
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

const dateToString = (timestamp, format, utc) => {
  const intTimestamp = typeof timestamp === 'string' ? parseInt(timestamp) : timestamp;
  const dt = new Date(intTimestamp);
  const mm = !utc ? (1 + dt.getMonth()).toString().padStart(2, '0') : (1 + dt.getMonth()).toString().padStart(2, '0');
  const dd = !utc ? dt.getDate().toString().padStart(2, '0') : dt.getUTCDate().toString().padStart(2, '0');
  const yy = !utc ? dt.getFullYear() : dt.getFullYear();
  const hours = !utc
    ? dt.getHours() === 0
      ? 12
      : dt.getHours() > 12
      ? dt.getHours() - 12
      : dt.getHours()
    : dt.getUTCHours() === 0
    ? 12
    : dt.getUTCHours() > 12
    ? dt.getUTCHours() - 12
    : dt.getUTCHours();
  const mins = !utc ? (dt.getMinutes() < 10 ? '0' + dt.getMinutes() : dt.getMinutes()) : dt.getUTCMinutes() < 10 ? '0' + dt.getUTCMinutes() : dt.getUTCMinutes();
  const am_pm = !utc ? (dt.getHours() >= 12 ? 'PM' : 'AM') : dt.getUTCHours() >= 12 ? 'PM' : 'AM';
  if (isValidDate(dt)) {
    switch (format) {
      case 'date':
        return mm + '/' + dd + '/' + yy;
      case 'time':
        return hours + ':' + mins + ' ' + am_pm;
      default:
        return mm + '/' + dd + '/' + yy + ' ' + hours + ':' + mins + ' ' + am_pm;
    }
  } else return null;
};

const importanceToValue = (importance) => {
  switch (importance) {
    case 'Extremely Important':
      return 5;
    case 'Very Important':
      return 4;
    case 'Important':
      return 3;
    case 'Standard':
      return 2;
    case 'Not Important':
      return 1;
    case 'Unknown':
      return -1;
    default:
      return -2;
  }
};

const importanceToString = (importance) => {
  switch (importance) {
    case 5:
      return 'Extremely Important';
    case 4:
      return 'Very Important';
    case 3:
      return 'Important';
    case 2:
      return 'Standard';
    case 1:
      return 'Not Important';
    case -1:
      return 'Unknown';
    default:
      return 'Unknown';
  }
};

const sortImportance = (a, b) => {
  if (importanceToValue(a) < importanceToValue(b)) {
    return 1;
  } else if (importanceToValue(a) > importanceToValue(b)) {
    return -1;
  } else {
    return 0;
  }
};

const isValidDate = (d) => {
  return d instanceof Date && !isNaN(d) && d.getTime() > 0;
};

const arrayWordWrap = (bigArray, size) => {
  return (
    <React.Fragment>
      {(() => {
        let wrapped = [];
        for (var i = 0; i < bigArray.length; i += size) {
          wrapped.push(<div>{bigArray.slice(i, i + size).join(', ')}</div>);
        }
        return wrapped;
      })()}
    </React.Fragment>
  );
};

const concernToColorClass = (concern) => {
  let barColorPrimary;
  if (concern < 31) {
    barColorPrimary = 'green';
  } else if (concern < 61) {
    barColorPrimary = 'yellow';
  } else {
    barColorPrimary = 'red';
  }
  return barColorPrimary;
};

const numberToColor = (number) => {
  switch (number) {
    case -5:
    case -4:
    case -3:
      return 'purple';
    case -2:
      return 'black';
    case -1:
      return 'gray';
    case 0:
      return 'green';
    case 1:
      return 'yellow';
    case 2:
      return 'red';
    case 3:
      return 'red';
    default:
      return 'default';
  }
};

const numberToOptimizeTooltip = (number) => {
  switch (number) {
    case -3:
      return 'This diagnostic has been Acknowledged';
    case -2:
      return 'This diagnostic is not relevant for this valve';
    case -1:
      return 'This diagnostic cannot be determined';
    case 0:
      return 'Good';
    case 1:
      return 'Advisory';
    case 2:
      return 'Critical';
    case 3:
      return 'Critical';
    default:
      return '';
  }
};

const numberToPrioritizeTooltip = (number) => {
  switch (number) {
    case -3:
      return 'This issue has been Acknowledged';
    case -2:
      return 'This issue is not relevant for this loop';
    case -1:
      return 'This issue cannot be determined';
    case 0:
      return 'Good';
    case 1:
      return 'Advisory';
    case 2:
      return 'Critical';
    case 3:
      return 'Critical';
    default:
      return '';
  }
};

const numberToRepairLevel = (level) => {
  switch (level) {
    case 0:
      return 'Note';
    case 1:
      return 'Field Adjustment';
    case 2:
      return 'Parts Replacement';
    case 3:
      return 'Body Repair';
    default:
      return 'Unknown';
  }
};

const setPrintStyles = (activeTab) => {
  const printFlag = window.location.href.slice(-6);
  const printStyles = activeTab === 'certification' || printFlag.toLowerCase() === '/print';
  return printStyles;
};

const functions = {
  dateToString,
  isValidDate,
  sortImportance,
  importanceToValue,
  importanceToString,
  arrayWordWrap,
  setCookie,
  getCookie,
  concernToColorClass,
  numberToColor,
  numberToOptimizeTooltip,
  numberToPrioritizeTooltip,
  numberToRepairLevel,
  setPrintStyles,
  delay,
  getFileExtension,
};

export default functions;
