import React from 'react';
import initializeTheme from '../theme';
import { Checkbox } from '@mui/material';
import { connect } from 'react-redux';
import {
  updateConcern,
  fetchLoops,
  fetchSites,
  fetchDeviceTypes,
  fetchApplications,
  fetchLocations,
  fetchImportances,
  fetchEvents,
  fetchIssueTypes,
  setTableOffset,
  setTablePageSize,
  setTablePage,
  updateSort,
  updateFilter,
  deleteLoops,
  deleteDevices,
  setModule,
  prioritizeSetActiveLoop,
  clearFilter,
} from '../../actions/index';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../styles';
import { Grid, Typography } from '@mui/material';

class Legend extends React.Component {
  constructor(props) {
    super(props);
    this.state = { filter: {} };
    this.updateStatus = this.updateStatus.bind(this);
    this.updateConcern = this.updateConcern.bind(this);
  }
  componentDidMount() {
    let obj = { ...this.props.sort.filter };
    switch (this.props.type) {
      case 'Status':
        obj.Status = ['No Issue', 'Issue Found', 'Turned Away'];
        break;
      case 'Streamline':
        obj.Status = ['No Issue', 'Additional Action', 'Broken Probe'];
        break;
      case 'Meter Status':
        obj.Status = ['No Issue', 'Recommended Action', 'Off'];
        break;
      default:
        obj.Status = null;
    }
    this.props.updateFilter(obj);
  }
  updateStatus = async (item) => {
    let obj = { ...this.props.sort.filter };
    if (!obj[`Status`]) {
      obj[`Status`] = [item];
    } else {
      var index = obj[`Status`].indexOf(item);
      if (index !== -1) {
        obj[`Status`].splice(index, 1);
      } else {
        obj[`Status`].push(item);
      }
    }
    //console.log(obj);
    //console.log(this.props.sort.filter.Status);
    this.props.updateFilter(obj);
    this.props.setTableOffset(0);
    this.props.setTablePage(0);
  };
  updateConcern = async (event) => {
    let concern = {
      [event.target.name]: !this.props.concern[event.target.name],
    };
    //99.9% SAME AS MULTISELECTONCLOSE
    let obj = { ...this.props.sort.filter };
    obj[`SpecificDevice`] = event.target.checked;
    this.props.updateFilter(obj);
    console.log(concern);
    let filters = {
      Application: this.props.sort.filter.Application,
      Site: this.props.sort.filter.Site,
      Location: this.props.sort.filter.Location,
      Disruption: this.props.sort.filter.Disruption,
      Range: JSON.stringify(this.props.sort.filter.Range),
      Devicetypes: this.props.sort.filter['Device Types'],
      Issuetypes: this.props.sort.filter['Issue Types'],
      Importance: this.props.sort.filter.Importance,
      SpecificDevice: event.target.checked,
      Concerns: JSON.stringify(Object.assign(this.props.concern, concern)),
      Status: this.props.sort.filter.Status,
    };
    if (
      !(
        !filters.Application &&
        !filters.Disruption &&
        !filters.Devicetypes &&
        !filters.Issuetypes &&
        !filters.Importance &&
        !filters.Location &&
        !filters.Site &&
        !filters.Concerns &&
        !filters.Status &&
        this.props.auth.require_filter
      )
    ) {
      this.props.deleteDevices();
      this.props.deleteLoops();
      //TODO Finish with update of page
      this.props.updateConcern(concern);
      //console.log(filters);
      //console.log(this.props.sort.filter);
      //this.setState({ loading: true });
      this.props.setTablePage(0);
      //await this.props.fetchDevicesSummary();
      console.log(filters);
      this.props.fetchLoops(0, this.props.table.pageSize, this.props.sort.sort, filters, this.props.sort.filter.Dataset);
    }
  };
  render() {
    const theme = initializeTheme(this.props.colorMode);
    const { classes } = this.props;
    if (this.props.type === 'Meter Status') {
      return (
        <React.Fragment>
          <Typography variant='subtitle2' className={classes.concernLegend}>
            {this.props.type}
          </Typography>
          <Grid container justifyContent='flex-start'>
            <Grid item xs={12} sm='auto' className={classes.legendItem}>
              <Typography style={theme.palette.green} variant='subtitle2'>
                <Checkbox
                  name='No Issue'
                  checked={!!this.props.sort.filter.Status && this.props.sort.filter.Status.indexOf('No Issue') !== -1}
                  //disabled={!this.props.sort.filter.Status}
                  onClick={() => this.updateStatus('No Issue')}
                  className={classes.checkboxLegend}
                  style={theme.palette.green}
                />
                No Issue
              </Typography>
            </Grid>
            <Grid item xs={12} sm='auto' className={classes.legendItem}>
              <Typography style={theme.palette.blue} variant='subtitle2'>
                <Checkbox
                  name='Recommended Action'
                  checked={!!this.props.sort.filter.Status && this.props.sort.filter.Status.indexOf('Recommended Action') !== -1}
                  //disabled={!this.props.sort.filter.Status}
                  onClick={() => this.updateStatus('Recommended Action')}
                  className={classes.checkboxLegend}
                  style={theme.palette.blue}
                />
                Recommended Action
              </Typography>
            </Grid>
            <Grid item xs={12} sm='auto' className={classes.legendItem}>
              <Typography style={theme.palette.gray} variant='subtitle2'>
                <Checkbox
                  name='Off'
                  checked={!!this.props.sort.filter.Status && this.props.sort.filter.Status.indexOf('Off') !== -1}
                  //disabled={!this.props.sort.filter.Status}
                  onClick={() => this.updateStatus('Off')}
                  className={classes.checkboxLegend}
                  style={theme.palette.gray}
                />
                Off
              </Typography>
            </Grid>
          </Grid>
        </React.Fragment>
      );
    } else if (this.props.type === 'Streamline') {
      return (
        <React.Fragment>
          <Typography variant='subtitle2' className={classes.concernLegend}>
            {this.props.type}
          </Typography>
          <Grid container justifyContent='flex-start'>
            <Grid item xs={12} sm='auto' className={classes.legendItem}>
              <Typography style={theme.palette.green} variant='subtitle2'>
                <Checkbox
                  name='No Issue'
                  checked={!!this.props.sort.filter.Status && this.props.sort.filter.Status.indexOf('No Issue') !== -1}
                  //disabled={!this.props.sort.filter.Status}
                  onClick={() => this.updateStatus('No Issue')}
                  className={classes.checkboxLegend}
                  style={theme.palette.green}
                />
                No Issue
              </Typography>
            </Grid>
            <Grid item xs={12} sm='auto' className={classes.legendItem}>
              <Typography style={theme.palette.blue} variant='subtitle2'>
                <Checkbox
                  name='Additional Action'
                  checked={!!this.props.sort.filter.Status && this.props.sort.filter.Status.indexOf('Additional Action') !== -1}
                  //disabled={!this.props.sort.filter.Status}
                  onClick={() => this.updateStatus('Additional Action')}
                  className={classes.checkboxLegend}
                  style={theme.palette.blue}
                />
                Additional Action
              </Typography>
            </Grid>
            <Grid item xs={12} sm='auto' className={classes.legendItem}>
              <Typography style={theme.palette.red} variant='subtitle2'>
                <Checkbox
                  name='Broken Probe'
                  checked={!!this.props.sort.filter.Status && this.props.sort.filter.Status.indexOf('Broken Probe') !== -1}
                  //disabled={!this.props.sort.filter.Status}
                  onClick={() => this.updateStatus('Broken Probe')}
                  className={classes.checkboxLegend}
                  style={theme.palette.red}
                />
                Broken Probe
              </Typography>
            </Grid>
          </Grid>
        </React.Fragment>
      );
    } else if (this.props.type === 'Status') {
      return (
        <React.Fragment>
          <Typography variant='subtitle2' className={classes.concernLegend}>
            {this.props.type}
          </Typography>
          <Grid container justifyContent='flex-start'>
            <Grid item xs={12} sm='auto' className={classes.legendItem}>
              <Typography style={theme.palette.green} variant='subtitle2'>
                <Checkbox
                  name='No Issue'
                  checked={!!this.props.sort.filter.Status && this.props.sort.filter.Status.indexOf('No Issue') !== -1}
                  //disabled={!this.props.sort.filter.Status}
                  onClick={() => this.updateStatus('No Issue')}
                  className={classes.checkboxLegend}
                  style={theme.palette.green}
                />
                No Issue
              </Typography>
            </Grid>
            <Grid item xs={12} sm='auto' className={classes.legendItem}>
              <Typography style={theme.palette.blue} variant='subtitle2'>
                <Checkbox
                  name='Turned Away'
                  checked={!!this.props.sort.filter.Status && this.props.sort.filter.Status.indexOf('Turned Away') !== -1}
                  disabled={!this.props.concern}
                  onClick={() => this.updateStatus('Turned Away')}
                  className={classes.checkboxLegend}
                  style={theme.palette.blue}
                />
                Turned Away
              </Typography>
            </Grid>
            <Grid item xs={12} sm='auto' className={classes.legendItem}>
              <Typography style={theme.palette.red} variant='subtitle2'>
                <Checkbox
                  name='Issue Found'
                  checked={!!this.props.sort.filter.Status && this.props.sort.filter.Status.indexOf('Issue Found') !== -1}
                  disabled={!this.props.concern}
                  onClick={() => this.updateStatus('Issue Found')}
                  className={classes.checkboxLegend}
                  style={theme.palette.red}
                />
                Issue Found
              </Typography>
            </Grid>
          </Grid>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <Typography variant='subtitle2' className={classes.concernLegend}>
            {this.props.type} Legend
          </Typography>
          <Grid container justifyContent='flex-start'>
            <Grid item xs={12} sm='auto' className={classes.legendItem}>
              <Typography style={theme.palette.green} variant='subtitle2'>
                <Checkbox
                  name='Good'
                  checked={!this.props.concern ? true : this.props.concern.Good}
                  disabled={!this.props.concern}
                  onClick={this.updateConcern}
                  className={classes.checkboxLegend}
                  style={theme.palette.green}
                />
                {this.props.type !== 'Reliability' ? null : 'xx'} <span style={{ marginTop: 2 }}>Good {this.props.type !== 'Concern' ? null : '0 - 30'}</span>
              </Typography>
            </Grid>
            <Grid item xs={12} sm='auto' className={classes.legendItem}>
              <Typography style={theme.palette.yellow} variant='subtitle2'>
                <Checkbox
                  name='Advisory'
                  checked={!this.props.concern ? true : this.props.concern.Advisory}
                  disabled={!this.props.concern}
                  onClick={this.updateConcern}
                  className={classes.checkboxLegend}
                  style={theme.palette.yellow}
                />
                {this.props.type !== 'Reliability' ? null : 'xx'} Advisory {this.props.type !== 'Concern' ? null : '31 - 60'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm='auto' className={classes.legendItem}>
              <Typography style={theme.palette.red} variant='subtitle2'>
                <Checkbox
                  name='Critical'
                  checked={!this.props.concern ? true : this.props.concern.Critical}
                  disabled={!this.props.concern}
                  onClick={this.updateConcern}
                  className={classes.checkboxLegend}
                  style={theme.palette.red}
                />
                {this.props.type !== 'Reliability' ? null : 'xx'} Critical {this.props.type !== 'Concern' ? null : '61 - 100'}
              </Typography>
            </Grid>
            {this.props.type !== 'Reliability' ? null : (
              <React.Fragment>
                <Grid item xs={12} sm='auto' className={classes.legendItem}>
                  <Typography style={theme.palette.blue} variant='subtitle2'>
                    <Checkbox
                      name='Critical'
                      checked={!this.props.concern ? true : this.props.concern.Critical}
                      disabled={!this.props.concern}
                      onClick={this.updateConcern}
                      className={classes.checkboxLegend}
                      style={theme.palette.blue}
                    />
                    xx Calibrations Due
                  </Typography>
                </Grid>
                <Grid item xs={12} sm='auto' className={classes.legendItem}>
                  <Typography style={theme.palette.purple} variant='subtitle2'>
                    <Checkbox
                      name='Critical'
                      checked={!this.props.concern ? true : this.props.concern.Critical}
                      disabled={!this.props.concern}
                      onClick={this.updateConcern}
                      className={classes.checkboxLegend}
                      style={theme.palette.purple}
                    />
                    xx Calibrations Late
                  </Typography>
                </Grid>
              </React.Fragment>
            )}
          </Grid>
        </React.Fragment>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    concern: state.sort.concern,
    sort: state.sort,
    table: state.table,
    colorMode: state.colorMode.colorMode,
  };
};

export default connect(mapStateToProps, {
  updateConcern,
  clearFilter,
  fetchLoops,
  fetchSites,
  fetchDeviceTypes,
  fetchApplications,
  fetchLocations,
  fetchImportances,
  fetchEvents,
  fetchIssueTypes,
  setTableOffset,
  setTablePageSize,
  setTablePage,
  updateSort,
  updateFilter,
  deleteLoops,
  deleteDevices,
  setModule,
  prioritizeSetActiveLoop,
})(withStyles(styles, { withTheme: true })(Legend));
