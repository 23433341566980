import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import withStyles from '@mui/styles/withStyles';
import { Link } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Addchart, ManageSearch, Timeline, DateRange } from '@mui/icons-material';
import { connect } from 'react-redux';
import { reliabilitySetActiveTab } from '../../actions/index';
import { styles } from '../styles';

class PrioritizeTabs extends React.Component {
  setActiveTab = (event, value) => {
    this.props.reliabilitySetActiveTab(value);
  };
  render() {
    const { classes } = this.props;
    return (
      <Paper square className={classes.tabsWrapper} elevation={3}>
        <Tabs value={this.props.activeTab} onChange={this.setActiveTab} variant='fullWidth' indicatorColor='secondary' textColor='secondary' className={classes.tabs}>
          <Tab className={classes.tab} icon={<Timeline />} label='Maintenance' value='maintenance' component={Link} to={`maintenance`} />
          <Tab className={classes.tab} icon={<ManageSearch />} label='Field Analysis' value='field' component={Link} to={`field`} />
          <Tab className={classes.tab} icon={<DateRange />} label='Schedule' value='schedule' component={Link} to={`schedule`} />
          <Tab className={classes.tab} icon={<Addchart />} label='Data & Alarms' value='data' component={Link} to={`data`} />
        </Tabs>
      </Paper>
    );
  }
}

PrioritizeTabs.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    activeTab: state.reliability.activeTab,
    activeLoop: state.reliability.activeLoop,
    loops: state.loops.loops,
  };
};

export default connect(mapStateToProps, { reliabilitySetActiveTab })(withStyles(styles)(PrioritizeTabs));
