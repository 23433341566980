import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { Typography, Grid, Paper, Tooltip, CircularProgress, IconButton } from '@mui/material';
//import { CheckBox } from '@mui/icons-material';
import {
  BuildCircle,
  Construction,
  Error,
  PowerOff,
  /*Replace,*/ Sync,
  Troubleshoot,
  Warning,
  KeyboardArrowUp,
  KeyboardArrowDown,
  Print,
  Flag,
  /*PriorityHigh*/
  FlagOutlined,
} from '@mui/icons-material';
//import { Link } from 'react-router-dom';
import initializeTheme from '../theme';
import { connect } from 'react-redux';
import { styles } from '../styles';
//import TableComponent from '../charts/Table';
import functions from '../../functions/functions';
import ImageGallery from '../test/ImageGallery';
import WidgetTitle from '../headers/WidgetTitle.js';
import TextDisplay from '../filters/TextDisplay.js';
import { updateDevice } from '../../actions/index';

class IBATile extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      hasBorder: false,
      tileExpanded: false,
      selectMenu: false,
      valveTestMenu: false,
      anchor: null,
      checked: false,
    };
  }
  componentDidMount() {
    //componentDidUpdate() {

    if (this.props.hasBorder && this.setState.hasBorder) {
      this.setState({ hasBorder: true });
    }
    if (this.props.expanded && !this.setState.tileExpanded) {
      this.setState({ tileExpanded: true });
    }
  }

  handleTileExpand = (e) => {
    e.preventDefault();
    this.setState({
      tileExpanded: !this.state.tileExpanded,
    });
  };

  updateFlagged = (deviceId, flagged) => {
    //console.log(deviceId, flagged);
    this.props.updateDevice(deviceId, flagged);
  };
  renderLoading() {
    const { classes } = this.props;
    if (this.state.loading) {
      return (
        <React.Fragment>
          <CircularProgress className={classes.loadIcon} size={15} color='secondary' />
          Loading...
        </React.Fragment>
      );
    } else {
      return;
    }
  }

  processImportance(importance) {
    switch (importance) {
      case 5:
        return 'Extremely Important';
      case 4:
        return 'Very Important';
      case 3:
        return 'Important';
      case 2:
        return 'Standard';
      case 1:
        return 'Not Important';
      default:
        return 'Standard';
    }
  }

  getGoogleMapLink = (device) => {
    if (!device.location_gps_lat || !device.location_gps_long) return null;
    let lat = device.location_gps_lat;
    //let latSuff = lat > 0 ? 'N' : 'S';
    let long = device.location_gps_long;
    let zoom = 14; //higher is closer
    //let longSuff = long > 0 ? 'E' : 'W';
    //return `https://maps.google.com/maps?width=100%25&height=600&hl=en&q=${lat},${long}+(My%20Name%20Here)&t=&z=14&ie=UTF8&iwloc=B&output=embed`;
    return `https://maps.google.com/maps?hl=en&q=${lat},${long}&z=${zoom}&ie=UTF8&iwloc=B&output=embed`;
  };

  textDisplayFactory = (item) => {
    let device = !this.props.device ? {} : this.props.device; // !this.props.loops || !this.props.loops[this.props.match.params.id] ? {} : this.props.loops[this.props.match.params.id];
    //const { classes } = this.props;
    return <TextDisplay id={null} key={`text_display_${item.label}`} label={item.label} text={!device[item.id] ? '- ' : device[item.id]} type='large' />;
  };

  getDeviceRecommendations = (device) => {
    //console.log(device);
    const { classes } = this.props;
    let list = [];
    if (device.meter_measurement_check === '0' && device.recommendation_type.indexOf('Replace') === -1) {
      list.push({
        type: 'Measurement',
        text: 'Device type is incorrect for measurement value.',
      });
    }
    if (device.meter_power_check === 'False') {
      list.push({ type: 'Off', text: 'Device is unpowered.' });
    }
    if (!!device.recommendations && device.recommendations !== '') {
      let recommendations = device.recommendations.split(','); //'Test 1||| where was my comma, Test 2'.split(','); //
      let recommendation_types = !device.recommendation_type ? [] : device.recommendation_type.split(',');
      for (let i = 0; i < recommendations.length; i++) {
        let recommendationsComma = recommendations[i].replace('|||', ',');
        list.push({
          type: recommendation_types[i],
          text: recommendationsComma,
        });
      }
    }
    let defaultColors = ['#E19878', '#598FD6', '#C866A7', '#48959B', '#9473C5', '#CEAD9F', '#88A5C9', '#CB8A9F', '#84AFBB', '#A796C1'];
    let backgroundColors = [
      'rgba(225, 152, 120, 0.1)',
      'rgba(89, 143, 214, 0.1)',
      'rgba(200, 102, 167, 0.1)',
      'rgba(72, 149, 155, 0.1)',
      'rgba(148, 115, 197, 0.1)',
      'rgba(206, 173, 159, 0.1)',
      'rgba(136, 165, 201, 0.1)',
      'rgba(203, 138, 159, 0.1)',
      'rgba(132, 175, 187, 0.1)',
      'rgba(167, 150, 193, 0.1)',
    ];
    return (
      <React.Fragment>
        {list.map((item, index) => {
          let icon = null;
          let color = '#666';
          let backgroundColor = this.props.colorMode === 'dark' ? '#666' : '#EEE';
          switch (item.type) {
            case 'Error':
              color = defaultColors[0];
              backgroundColor = backgroundColors[0];
              icon = <Error className={classes.alertIcon} sx={{ color: color }} />;
              break;
            case 'Warning':
              color = defaultColors[1];
              backgroundColor = backgroundColors[1];
              icon = <Warning className={classes.alertIcon} sx={{ color: color }} />;
              break;
            case 'Off':
              color = defaultColors[1];
              backgroundColor = backgroundColors[1];
              icon = <PowerOff className={classes.alertIcon} sx={{ color: color }} />;
              break;
            case 'Measurement':
              color = defaultColors[1];
              backgroundColor = backgroundColors[1];
              icon = <Sync className={classes.alertIcon} sx={{ color: color }} />;
              break;
            case 'Calibration':
              color = defaultColors[2];
              backgroundColor = backgroundColors[2];
              icon = <BuildCircle className={classes.alertIcon} sx={{ color: color }} />;
              break;
            case 'Repair':
              color = defaultColors[3];
              backgroundColor = backgroundColors[3];
              icon = <Construction className={classes.alertIcon} sx={{ color: color }} />;
              break;
            case 'Replace':
              color = defaultColors[4];
              backgroundColor = backgroundColors[4];
              icon = <Sync className={classes.alertIcon} sx={{ color: color }} />;
              break;
            case 'Troubleshoot':
              color = defaultColors[5];
              backgroundColor = backgroundColors[5];
              icon = <Troubleshoot className={classes.alertIcon} sx={{ color: color }} />;
              break;
            default:
              color = defaultColors[6];
              backgroundColor = backgroundColors[6];
              icon = <Warning className={classes.alertIcon} sx={{ color: color }} />;
          }
          return (
            <Grid key={`recommendation_${index}`} style={{ marginBottom: 4 }}>
              <div
                className={classes.alert}
                style={{
                  borderLeft: '8px solid ' + color,
                  borderColor: color,
                  backgroundColor: backgroundColor,
                }}
                elevation={3}
              >
                <Typography>
                  {icon} {item.type} - {item.text}
                </Typography>
              </div>
            </Grid>
          );
          /*return (
            <Alert key={`alert_${item.text}`} severity={item.type} style={{ marginBottom: 1 }}>
              {item.text}
            </Alert>
          );*/
        })}
      </React.Fragment>
    );
  };

  renderExpandedTile() {
    const { classes } = this.props;
    let headerArr = [
      //{ id: 'tag', label: 'Device' },
      //{ id: 'plant_name', label: 'Plant' },
      //{ id: 'plant_location', label: 'Unit' },
      //{ id: 'device_location', label: 'Application' },
      //{ id: 'vessel_line_location', label: 'Line' },
      { id: 'sap_location', label: 'SAP Location' },
      { id: 'manufacturer', label: 'Manuf.' },
      { id: 'order_code', label: 'order_code' },
      { id: 'year_manufactured', label: 'Year Manuf.' },
      //{ id: 'meterCheck', label: 'Meter Status' },
      { id: 'criticality', label: 'Importance' },
      //{ id: 'actionCheck', label: 'Recommendations' },
      { id: 'tech_name', label: 'Technician' },
      //{ id: 'actionCheck', label: 'Recommendations' },
      { id: 'serial_code', label: 'Serial' },
      { id: 'ease_of_access', label: 'Ease of Access' },
      { id: 'asset_description', label: 'Description' },
      { id: 'general_asset_notes', label: 'Notes' },
      //{ id: '', label: '' },
      //{ id: '', label: '' },
      //{ id: '', label: '' },
      //{ id: '', label: '' },
      //{ id: '', label: '' },
    ];

    const device = !this.props.device ? {} : this.props.device;
    let googleMapLink = this.getGoogleMapLink(device);
    const printStyles = functions.setPrintStyles(this.props.activeTab);
    //console.log(device);
    let dataArr = [];
    //console.log(!loop ? 'Not Ready' : loop);
    if (!!device && !!device.device_ids) {
      for (let i = 0; i < device.device_ids.length; i++) {
        let device = this.props.devices[parseInt(this.props.device.device_ids[i])];
        //console.log(this.props.devices);
        if (!!device && !!device.processName) {
          dataArr.push({
            id: device.device_id,
            deviceId: device.name,
            deviceType: (
              <React.Fragment>
                <Typography>
                  {`${device.deviceType}`}
                  {/*!device.optimize_id ? null : (
                    <InfoPopover
                      center
                      tooltip='Latest Optimize Test'
                      title='Latest Optimize Test'
                      titleIcon='Speed'
                      resize
                      style={{ marginBottom: -15 }}
                      icon={<Speed />}
                      content={<OptimizeTile hasLink={!printStyles} hasBorder key={`${this.props.devices[device.device_id]}_optimize_test`} device={device} prioritize />}
                    />
                  )*/}
                </Typography>
              </React.Fragment>
            ),
            description: !device.description ? null : device.description.length > 12 && !this.props.format ? (
              <Tooltip arrow placement='top' title={device.description} enterDelay={300} style={{ cursor: 'pointer' }}>
                <span>{device.description.substring(0, 12)} ...</span>
              </Tooltip>
            ) : (
              device.description
            ),
            concernScore: device.concernAdjusted,
            concernShift: Math.round(device.concernShift),
            concernMax: Math.round(device.concernMax),
            importance: device.importance,
          });
        }
      }
    }

    /*{
        deviceId: 'testID',
        deviceType: 'Valve',
        concernScore: 70,
        concernShift: 5,
        concernMax: 72,
        importance: 'Standard',
      },*/

    if (this.state.tileExpanded) {
      return (
        <React.Fragment>
          <Grid item xs={12} style={printStyles ? null : { padding: 16 }}>
            <Grid item>
              <WidgetTitle title={`Details`} />
              <Paper className={printStyles ? classes.printPaper : classes.hidePaper}>
                <Grid container spacing={4}>
                  {headerArr.map((item) => {
                    return this.textDisplayFactory(item);
                  })}
                </Grid>
              </Paper>
            </Grid>
            {!device || !device.recommendations || device.recommendations === '' ? null : (
              <React.Fragment>
                <WidgetTitle title={`Recommendations`} />
                {!device || !device.recommendations ? null : this.getDeviceRecommendations(device)}
              </React.Fragment>
            )}
            {!device.asset_photos || device.asset_photos.length === 0 ? null : (
              <React.Fragment>
                <WidgetTitle title={`Images`} />
                <ImageGallery images={device.asset_photos} />
              </React.Fragment>
            )}
            <Grid item>
              {!googleMapLink ? null : (
                <React.Fragment>
                  <WidgetTitle title={`Map`} />
                  <iframe
                    title={`Google Map for Device ${device.tag}`}
                    width='100%'
                    height={!printStyles ? '600' : '400'}
                    frameBorder='0'
                    marginHeight='0'
                    marginWidth='0'
                    src={googleMapLink}
                    style={{ marginBottom: -8, borderRadius: 4 }}
                  />
                </React.Fragment>
              )}
            </Grid>
          </Grid>
          <Grid container style={printStyles ? { marginBottom: 100 } : { display: 'none' }} className={classes.printHide}></Grid>
        </React.Fragment>
      );
    } else {
      return null;
    }
  }

  clickPrint(id) {
    let win = window.open(`${window.location.href}/detail/${id}/print`, '_blank');
    win.focus();
  }

  render() {
    const theme = initializeTheme(this.props.colorMode);
    const { classes } = this.props;
    //const hasBorder = this.state.hasBorder;
    const device = !this.props.device ? {} : this.props.device;
    //const openIssuesContent = this.getLoopOpenIssues(device);
    let content = <div>Could not find data for this loop!</div>;
    //console.log(this.props.analysisRange);
    //const { analysisRange /*analysisRangeShift*/ } = this.parseanalysisRange(this.props.analysisRange);
    /*let borderColor;
    if (device.concern < 25) {
      borderColor = classes.greenBorder;
    } else if (device.concern < 75) {
      borderColor = classes.yellowBorder;
    } else {
      borderColor = classes.redBorder;
    }*/
    /*const handleClick = (e) => {
      e.preventDefault();
      this.setState({
        selectMenu: !this.state.selectMenu,
        selectMenuAnchor: e.currentTarget,
      });
    };
    const handleCheckboxClick = (e) => {
      e.preventDefault();
      this.setState({ checked: !this.state.checked });
    };
    const handleClose = () => {
      this.setState({ selectMenu: false });
    };*/
    let styleObj = Object.assign({}, device.style, {
      marginBottom: this.props.colorMode === 'dark' ? 6 : 4,
    });
    const printStyles = functions.setPrintStyles(this.props.activeTab);
    if (this.props.device) {
      //console.log(this.props.index);
      if (this.props.device && printStyles) {
        content = (
          <div className={!this.state.tileExpanded ? null : !this.state.tileExpanded || this.props.index === 0 ? classes.pageBreakAfter : `${classes.pageBreak} ${classes.pageBreakAfter}`}>
            <Paper className={`${printStyles ? classes.printIBATile : classes.ibaTile}`} style={styleObj} elevation={3}>
              <Grid
                container
                justifyContent='space-between'
                onClick={this.handleTileExpand}
                className={!this.state.tileExpanded || !!printStyles ? classes.ibaTileHeader : classes.ibaTileHeaderExpanded}
              >
                <Grid item xs={12} sm={12} md={12}>
                  <Grid container justifyContent='space-between'>
                    <Grid item xs={12} sm={6} md={6} lg={2}>
                      <Typography variant='subtitle2'>
                        {!device.plant_name ? 'Site Not Found' : device.plant_name}
                        {!device.plant_location ? ' - Unit Not Found' : ` - ${device.plant_location}`}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} lg={2}>
                      <Typography variant='subtitle2'>
                        <Grid container spacing={1}>
                          <Grid item>
                            {!device.tag ? null : 'Device: '}
                            <span style={theme.palette.blue}>
                              {!device.tag ? 'No Device Exists' : device.tag}{' '}
                              {/*!device.devices || device.devices === 'undefined' ? 'Unknown Devices' : device.devices.length} Device{!device.devices || device.devices.length === 1 ? '' : 's'*/}
                              {!device.asset_type || device.asset_type === 'undefined' ? null : `${device.asset_type}`}
                            </span>
                          </Grid>
                          <Grid item>{!device.manufacturer || device.manufacturer === 'undefined' ? null : `${device.manufacturer}`}</Grid>
                        </Grid>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={2}>
                      <Grid container spacing={1}>
                        <Grid item>
                          <Typography variant='subtitle2'>
                            {!device.device_location || device.device_location === 'Unknown' ? null : 'Application: '}
                            <span style={theme.palette.blue}>{!device.device_location || device.device_location === 'Unknown' ? `Unknown Location` : `${device.device_location}`}</span>
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant='subtitle2'>{!device.vessel_line_location || device.vessel_line_location === 'undefined' ? null : `${device.vessel_line_location}`}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item sm={6} md={6} lg={2}>
                      <Typography variant='subtitle2'>
                        {!device.recommendation_type ? null : `Recommendations: `}
                        {!device.recommendation_type ? 'Okay' : device.recommendation_type}
                      </Typography>
                    </Grid>
                    {this.props.alwaysExpanded ? null : (
                      <Grid item xs={12} md={12} lg={2} className={classes.printHide}>
                        <Grid container justifyContent='space-between'>
                          <Grid item xs={8} sm={10} lg={1}></Grid>

                          <Grid item style={!printStyles ? null : { display: 'none' }}>
                            <IconButton style={{ padding: 0, marginTop: -4 }} color='inherit' onClick={() => this.clickPrint(device.deviceId)} size='large'>
                              <Print></Print>
                            </IconButton>
                          </Grid>
                          <Grid item>{!this.state.tileExpanded || this.props.alwaysExpanded ? <KeyboardArrowDown></KeyboardArrowDown> : <KeyboardArrowUp></KeyboardArrowUp>}</Grid>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
            <Grid container>{this.renderExpandedTile()}</Grid>
          </div>
        );
      } else if (this.props.device) {
        content = (
          <Paper className={`${printStyles ? null : classes.ibaTile}`} style={styleObj} elevation={3}>
            <Grid container justifyContent='space-between' onClick={this.handleTileExpand} className={!this.state.tileExpanded ? classes.ibaTileHeader : classes.ibaTileHeaderExpanded}>
              <Grid item xs={12} sm={12} md={12}>
                <Grid container justifyContent='space-between'>
                  <Grid item xs={12} sm={6} md={6} lg={2}>
                    <Typography variant='subtitle2'>
                      {!device.plant_name ? 'Site Not Found' : device.plant_name}
                      {!device.plant_location ? ' - Unit Not Found' : ` - ${device.plant_location}`}
                    </Typography>
                    <Grid item>{!device.criticality || device.criticality === 'undefined' ? null : `${device.criticality}`}</Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={2}>
                    <Typography variant='subtitle2'>
                      <Grid container spacing={1}>
                        <Grid item>
                          {!device.tag ? null : 'Device: '}
                          <span style={theme.palette.blue}>
                            {!device.tag ? 'No Device Exists' : device.tag}{' '}
                            {/*!device.devices || device.devices === 'undefined' ? 'Unknown Devices' : device.devices.length} Device{!device.devices || device.devices.length === 1 ? '' : 's'*/}
                            {!device.asset_type || device.asset_type === 'undefined' ? null : `${device.asset_type}`}
                          </span>
                        </Grid>
                        <Grid item>{!device.manufacturer || device.manufacturer === 'undefined' ? null : `${device.manufacturer}`}</Grid>
                      </Grid>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={2}>
                    <Grid container spacing={1}>
                      <Grid item>
                        <Typography variant='subtitle2'>
                          {!device.device_location || device.device_location === 'Unknown' ? null : 'Application: '}
                          <span style={theme.palette.blue}>{!device.device_location || device.device_location === 'Unknown' ? `Unknown Location` : `${device.device_location}`}</span>
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant='subtitle2'>{!device.vessel_line_location || device.vessel_line_location === 'undefined' ? null : `${device.vessel_line_location}`}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item sm={6} md={6} lg={2}>
                    <Typography variant='subtitle2'>
                      {!device.recommendation_type ? null : `Recommendations: `}
                      {!device.recommendation_type ? 'Okay' : device.recommendation_type}
                    </Typography>
                  </Grid>
                  {this.props.alwaysExpanded ? null : (
                    <Grid item xs={12} md={12} lg={2} className={classes.printHide}>
                      <Grid container justifyContent='space-between'>
                        <Grid item /*xs={8} sm={10} lg={1}*/>
                          {!device.flagged ? null : (
                            <Grid item>
                              <Tooltip arrow placement='top' title='Flag Device' aria-label='Flag Device'>
                                <IconButton
                                  color='inherit'
                                  aria-label='more'
                                  onClick={(e) => {
                                    e.stopPropagation(); //THE MAGIC THAT BLOCKS OTHER ONCLICKS FROM FIRING
                                    //console.log(e);
                                    //console.log(device.deviceId);
                                    this.updateFlagged(device.deviceId, device.flagged === 'N' ? 'Y' : 'N');
                                  }}
                                  style={{ marginRight: -10 }}
                                  size='large'
                                >
                                  {device.flagged === 'Y' ? <Flag color={'primary'} /> : <FlagOutlined />}
                                </IconButton>
                              </Tooltip>
                            </Grid>
                          )}
                        </Grid>

                        <Grid item style={!printStyles ? null : { display: 'none' }}>
                          <IconButton style={{ padding: 0, marginTop: -4 }} color='inherit' onClick={() => this.clickPrint(device.deviceId)} size='large'>
                            <Print></Print>
                          </IconButton>
                        </Grid>
                        <Grid item>{!this.state.tileExpanded || this.props.alwaysExpanded ? <KeyboardArrowDown></KeyboardArrowDown> : <KeyboardArrowUp></KeyboardArrowUp>}</Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid container>{this.renderExpandedTile()}</Grid>
          </Paper>
        );
      }
    }
    return (
      <Grid item xs={12} sm={12} md={!this.state.tileExpanded ? 6 : 12} lg={!this.state.tileExpanded ? 4 : 12} className={classes.textDecoNone}>
        {content}
      </Grid>
    );
  }
}

IBATile.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    isSignedIn: state.auth.isSignedIn,
    userId: state.auth.userId,
    locations: state.locations,
    errors: state.errors,
    events: state.events,
    loops: state.loops.loops,
    devices: state.devices.devices,
    activeLoop: state.prioritize.activeLoop,
    analysisRange: state.sort.filter.AnalysisRange,
    colorMode: state.colorMode.colorMode,
    format: state.format.format,
  };
};

export default connect(mapStateToProps, { updateDevice })(withStyles(styles)(IBATile));
