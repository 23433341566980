import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import {
  Calendar,
  Config,
  Optimize,
  OptimizeDetails,
  Support,
  Error,
  Prioritize,
  PrioritizeDetails,
  Reliability,
  ReliabilityDetails,
  Profile,
  Login,
  Signup,
  Reports,
  //Roadmap,
  Upload,
  Welcome,
  Forgot,
  ResetPassword,
  IBA,
  IBADetails,
  FSR,
  FSRDetails,
} from './pages/PagesImport';
import Bugsplat from './pages/Bugsplat';
import withStyles from '@mui/styles/withStyles';
import { styles } from './styles';
import PrintHeader from './headers/PrintHeader';
import functions from '../functions/functions';

function PrivateRoute({ auth, component: Component, ...rest }) {
  // Confirms the state has a valid login and allows access, otherwise redirects you straight to '/' as a security precaution. Ideally shouldn't be needed given the other logic in place.
  const authCheck = () => {
    return auth;
  };
  return <Route {...rest} render={(props) => (authCheck() ? <Component {...props} /> : <Redirect to='/' />)} />;
}
class Display extends React.Component {
  render() {
    const printStyles = functions.setPrintStyles(this.props.activeTab);
    if (!this.props.isSignedIn) {
      //WHAT TO SHOW IF NOT LOGGED IN
      switch (window.location.pathname) {
        case '/signup':
          return <Signup auth={this.props.isSignedIn} />;
        case '/forgot':
          return <Forgot auth={this.props.isSignedIn} />;
        case '/reset-password':
          return <ResetPassword auth={this.props.isSignedIn} />;
        default:
          return <Login auth={this.props.isSignedIn} />;
      }
      // Either still verifying User is Signed in or User isn't signed in.
      //return <Login auth={this.props.isSignedIn};
    } else {
      return (
        <Bugsplat>
          <PrintHeader cert={printStyles}></PrintHeader>
          <Switch>
            <Route path='/' component={Welcome} exact sensitive={false} />
            <PrivateRoute path='/calendar/:print?' component={Calendar} exact sensitive={false} auth={this.props.isSignedIn} />
            <PrivateRoute path='/prioritize/:print?' component={Prioritize} exact sensitive={false} auth={this.props.isSignedIn} />
            <PrivateRoute path='/prioritize/:id/:tab/:print?' component={PrioritizeDetails} sensitive={false} auth={this.props.isSignedIn} />
            <PrivateRoute path='/reliability/:print?' component={Reliability} exact sensitive={false} auth={this.props.isSignedIn} />
            <PrivateRoute path='/reliability/:id/:tab/:print?' component={ReliabilityDetails} exact sensitive={false} auth={this.props.isSignedIn} />
            <PrivateRoute path='/stabilize/:print?' component={Prioritize} exact sensitive={false} auth={this.props.isSignedIn} />
            <PrivateRoute path='/stabilize/:id/:tab/:print?' component={PrioritizeDetails} exact sensitive={false} auth={this.props.isSignedIn} />
            <PrivateRoute path='/optimize/:id/:tab/:print?' component={OptimizeDetails} exact sensitive={false} auth={this.props.isSignedIn} />
            {
              /*TODO ADD TEST RETENTION TO URL*/
              //!Fix base reports logic
            }
            <PrivateRoute path='/reports' component={Reports} exact sensitive={false} auth={this.props.isSignedIn} />
            <PrivateRoute path='/reports/:tab/:print?' component={Reports} exact sensitive={false} auth={this.props.isSignedIn} />
            <PrivateRoute path='/reports/:print?' component={Reports} exact sensitive={false} auth={this.props.isSignedIn} />

            <PrivateRoute path='/reports/:tab/:report?/:print?' component={Reports} exact sensitive={false} auth={this.props.isSignedIn} />
            {/*<PrivateRoute
              path='/roadmap/:tab'
              component={Roadmap}
              exact
              auth={this.props.isSignedIn}
      />*/}
            <PrivateRoute path='/optimize/:print?' component={Optimize} exact sensitive={false} auth={this.props.isSignedIn} />
            <PrivateRoute path='/iba/:print?' component={IBA} exact sensitive={false} auth={this.props.isSignedIn} />
            <PrivateRoute path='/iba/detail/:id/:print?' component={IBADetails} exact sensitive={false} auth={this.props.isSignedIn} />
            <PrivateRoute path='/fsr/:print?' component={FSR} exact sensitive={false} auth={this.props.isSignedIn} />
            <PrivateRoute path='/fsr/detail/:id/:print?' component={FSRDetails} exact sensitive={false} auth={this.props.isSignedIn} />

            <PrivateRoute path='/upload/:tab/:print?' component={Upload} exact sensitive={false} auth={this.props.isSignedIn} />
            <PrivateRoute path='/upload/:print?' component={Upload} exact sensitive={false} auth={this.props.isSignedIn} />
            <PrivateRoute path='/profile/:print?' component={Profile} exact sensitive={false} auth={this.props.isSignedIn} />
            <PrivateRoute path='/configure/:tab/:print?' component={Config} exact sensitive={false} auth={this.props.isSignedIn} />
            <PrivateRoute path='/configure/:print?' component={Config} exact sensitive={false} auth={this.props.isSignedIn} />

            <PrivateRoute path='/configure/:tab/:report/:print?' component={Config} exact sensitive={false} auth={this.props.isSignedIn} />
            <PrivateRoute path='/support/:tab/:supportTab/:print?' component={Support} exact sensitive={false} auth={this.props.isSignedIn} />
            <PrivateRoute path='/support/:tab/:print?' component={Support} exact sensitive={false} auth={this.props.isSignedIn} />

            <PrivateRoute path='/support/:print?' component={Support} exact sensitive={false} auth={this.props.isSignedIn} />

            <Route path='/login' component={Login} exact sensitive={false} />
            <Route path='/forgot' component={Forgot} exact sensitive={false} />
            <Route path='/reset-password' component={ResetPassword} exact sensitive={false} />
            <Route path='/signup' component={Signup} exact sensitive={false} />
            <Route component={Error} sensitive={false} />
          </Switch>
        </Bugsplat>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    isSignedIn: state.auth.isSignedIn,
    userId: state.auth.userId,
    locations: state.locations,
    errors: state.errors,
    activeModule: state.currentModule.currentModule,
    activeTab: state.optimize.activeTab,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles, { withTheme: true })(Display));
