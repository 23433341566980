import _ from 'lodash';
import React from 'react';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../styles.js';
import { connect } from 'react-redux';
//import { Link } from '@mui/icons-material';
import { Typography, TablePagination, Button, TextField } from '@mui/material';
import FSRTile from '../tiles/FSRTile.js';
import CustomSelect from '../filters/CustomSelect.js';
import { RestartAlt } from '@mui/icons-material';
import Grid from '@mui/material/Grid';
import functions from '../../functions/functions.js';
import Legend from '../headers/Legend.js';
import {
  fetchOptimizeDevices,
  fetchDevicesSummary,
  fetchDevices,
  fetchEvents,
  setTableOffset,
  setTablePageSize,
  setTablePage,
  setModule,
  updateSort,
  updateDateRange,
  fetchLocations,
  fetchApplications,
  fetchImportances,
  fetchSites,
  fetchIssueTypes,
  deleteLoops,
  deleteDevices,
  fetchSort,
  fetchLoops,
  updateFilter,
  clearFilter,
} from '../../actions/index';

import { CircularProgress } from '@mui/material';
import WidgetTitle from '../headers/WidgetTitle.js';
import ReportChartWidget from '../widgets/ReportChartWidget.js';
//import Highcharts from 'highcharts';
import initializeTheme from '../theme.js';
//import TableWidget from '../widgets/TableWidget.js';
//import fastfield from '../../apis/fastfield.js';
//import api from '../../apis/api.js';
//import FastFieldImage from '../../components/test/FastFieldImage.js';
//import zapier from '../../functions/zapierJavascriptTest.js';

class FSR extends React.Component {
  constructor(props) {
    super(props);
    this.state = { filter: {}, loading: false, searchValueTemp: '', searchValue: '' };
    this.processData = this.processData.bind(this);
  }
  async componentDidMount() {
    if (this.state.currentModule !== 'FSR') {
      this.props.setModule('FSR');
    }
    //this.props.fetchDevices('3');
    this.setState({ loading: true });
    await this.props.fetchDevicesSummary();
    this.setState({ loading: false });
    //console.log(zapier());
    /*console.log(JSON.parse(`{"display": "lat 40.616870, lng -74.036145", "horizontal_accuracy": 12.754, "latitude": 40.61687, "longitude": -74.036145, "vertical_accuracy": -1}`));*/
    /*console.log(
      JSON.parse(
        `[{"comment": "None", "photo": "109305_6ab2ec2a-77c7-47e5-9b63-39738289728b_3eb50803-4c94-47e8-80b2-aa5a4f0f57c9.jpeg"}, {"comment": "None", "photo": "109305_6ab2ec2a-77c7-47e5-9b63-39738289728b_25505b56-48e0-414c-85d1-42cf051dafbc.jpeg"}]`
      )
    );*/
    // this.props.fetchOptimizeDevices(0, 24);
  }

  renderLoading() {
    const { classes } = this.props;
    if (this.state.loading) {
      return (
        <div>
          <Typography variant='subtitle2' style={{ marginTop: 12 }}>
            <CircularProgress className={classes.loadIcon} size={15} color='secondary' />
            Loading...
          </Typography>
        </div>
      );
    } else {
      return;
    }
  }

  onSearchChange = (event) => {
    //this.setState({ searchValueTemp: event.target.value });
  };

  onSearchBlur = (event) => {
    //console.log(event.target.value);
    this.setState({ searchValue: event.target.value });
    //console.log(event.target.value);
    //this.setState({ searchValue: this.state.searchValueTemp });
  };

  resetFilters = async () => {
    await this.props.clearFilter();
    this.props.updateSort('Importance');

    let obj = { ...this.props.sort.filter };
    let stateObj = {};
    let objKeys = Object.keys(obj);
    let stateKeys = Object.keys(this.state);
    //console.log(obj);
    //console.log(this.state);
    for (let i = 0; i < stateKeys.length; i++) {
      if (stateKeys[i] !== 'loading' && stateKeys[i] !== 'changes' && stateKeys[i] !== 'searchValue') stateObj[stateKeys[i]] = [];
    }
    for (let i = 0; i < objKeys.length; i++) {
      obj[objKeys[i]] = [];
    }
    stateObj['changes'] = true;
    stateObj['searchValue'] = '';
    stateObj['searchValueTemp'] = '';

    this.setState(stateObj);

    //console.log(name);
    this.props.updateFilter(obj);
    this.props.setTablePage(0);
    this.props.setTableOffset(0);
    //this.multiSelectOnClose();
  };
  handleSort = async (event, value, type) => {
    return 'fix';
    // this.props.updateSort(value);
    // // TODO FINISH THIS WHEN TABLE FULLY IN REDUX
    // let filters = {
    //   Application: this.props.sort.filter.Application,
    //   Site: this.props.sort.filter.Site,
    //   Location: this.props.sort.filter.Location,
    //   Disruption: this.props.sort.filter.Disruption,
    //   startDate: this.props.sort.filter.Range.startDate,
    //   endDate: this.props.sort.filter.Range.endDate,
    //   analysisRange: this.props.sort.filter.analysisRange,
    //   Devicetypes: this.props.sort.filter['Device Types'],
    //   Issuetypes: this.props.sort.filter['Issue Types'],
    //   Importance: this.props.sort.filter.Importance,
    //   SpecificDevice: this.props.sort.filter.SpecificDevice,
    //   Concerns: JSON.stringify(this.props.sort.concern),
    // };
    // if (
    //   !(
    //     !filters.Application &&
    //     !filters.Disruption &&
    //     !filters.Devicetypes &&
    //     !filters.Issuetypes &&
    //     !filters.Importance &&
    //     !filters.Location &&
    //     !filters.Site &&
    //     !filters.Concerns &&
    //     this.props.auth.require_filter
    //   )
    // ) {
    //   this.props.deleteDevices();
    //   this.props.deleteLoops();
    //   //TODO Finish with update of page

    //   //console.log(filters);
    //   //console.log(this.props.sort.filter);
    //   this.setState({ loading: true });
    //   this.props.setTablePage(0);
    //   //}

    //   //console.log(filters);

    //   await this.props.fetchLoops(0, this.props.table.pageSize, value, filters, this.props.sort.filter.Dataset).then(() => {
    //     this.setState({
    //       //page,
    //       //rowsPerPage,
    //       loading: false,
    //       changes: false,
    //     });
    //   });
    //   //this.props.fetchLoopsSummary();
    // }
  };
  renderTotals() {
    return 'fix';
    // const { classes } = this.props;
    // let issueKeys = Object.keys(this.props.loops).map((key) => {
    //   return this.props.loops[key].issues.length;
    // });
    // let totalIssues = 0;
    // issueKeys.forEach((issue) => {
    //   totalIssues += issue;
    // });
    // let actionKeys = Object.keys(this.props.loops).map((key) => {
    //   return this.props.loops[key].actions.length;
    // });
    // let totalActions = 0;
    // actionKeys.forEach((action) => {
    //   totalActions += action;
    // });
    // return (
    //   <Grid container className={classes.totalsContainer} spacing={2}>
    //     <Grid item>{<Typography variant='subtitle2'>Total Issues: {!totalIssues ? 0 : totalIssues}</Typography>}</Grid>
    //     <Grid item>{<Typography variant='subtitle2'>Total Action Items: {!totalActions ? 0 : totalActions}</Typography>}</Grid>
    //   </Grid>
    // );
  }

  multiSelectOnClose = async (event, label, changesOverride) => {
    let obj = { ...this.props.sort.filter };
    let name = !event.target.name ? event.target.id.split('-')[2] : event.target.name;

    //TODO -- Partial fix for clear implemented, need more responsive one.
    if (!!event.relatedTarget && event.relatedTarget.innerText.toLowerCase() === 'clear') {
      this.setState({ [name]: [] });
      obj[name] = [];
    } else {
      obj[name] = this.state[name];
    }
    // this.setState({ filter: obj });

    this.props.updateFilter(obj);
    this.props.setTablePage(0);
    this.props.setTableOffset(0);
  };

  multiSelectOnChange = async (event, value, type) => {
    //console.log('change', event, value, type);
    //let obj = { ...this.props.sort.filter };
    let name = !event.target.name ? event.target.id.split('-')[2] : event.target.name;
    //obj[name] = value;
    this.setState({ [name]: value });
    //this.props.updateFilter(obj);
  };

  refreshPageView = (offset, rowsPerPage, expectedPage) => {
    return 'fix';
    // let filters = {
    //   Application: this.props.sort.filter.Application,
    //   Site: this.props.sort.filter.Site,
    //   Location: this.props.sort.filter.Location,
    //   Disruption: this.props.sort.filter.Disruption,
    //   startDate: this.props.sort.filter.Range.startDate,
    //   endDate: this.props.sort.filter.Range.endDate,
    //   analysisRange: this.props.sort.filter.analysisRange,
    //   Devicetypes: this.props.sort.filter['Device Types'],
    //   Issuetypes: this.props.sort.filter['Issue Types'],
    //   Importance: this.props.sort.filter.Importance,
    //   SpecificDevice: this.props.sort.filter.SpecificDevice,
    //   Concerns: JSON.stringify(this.props.sort.concern),
    // };
    // if (
    //   !(
    //     !filters.Application &&
    //     !filters.Disruption &&
    //     !filters.Devicetypes &&
    //     !filters.Issuetypes &&
    //     !filters.Importance &&
    //     !filters.Location &&
    //     !filters.Site &&
    //     !filters.Concerns &&
    //     this.props.auth.require_filter
    //   )
    // ) {
    //   //Check to see if we already pulled the row data, if so don't bother the API again
    //   //console.log(this.props.sort);
    //   let rowsExist = true;
    //   const page = Math.floor(offset / rowsPerPage); // correct the page number for changing the page size
    //   //const rowList = this.props.loops.map(loop => loop.rowId);
    //   const rowList = _.map(this.props.loops, (loop) => loop.rowId);
    //   for (let i = offset; i < offset + rowsPerPage; i++) {
    //     if (!rowList.includes(i + 1)) {
    //       rowsExist = false;
    //     }
    //   }
    //   if (rowsExist) {
    //     this.setState({
    //       page,
    //       rowsPerPage,
    //       loading: false,
    //     });
    //   } else {
    //     this.props.fetchLoops(offset, rowsPerPage, this.props.sort.sort, filters, this.props.sort.filter.Dataset).then(() => {
    //       this.setState({
    //         page,
    //         rowsPerPage,
    //         loading: false,
    //       });
    //     });
    //   }
    // }
  };

  handleChangePage = (event, page) => {
    //return 'fix';
    // this.setState({
    //   loading: true,
    // });
    this.props.setTableOffset(this.props.table.pageSize * page);
    this.props.setTablePage(page);
    //const offset = this.props.table.pageSize * page;
    //const rowsPerPage = this.props.table.pageSize;
    // this.refreshPageView(offset, rowsPerPage, page);
  };
  handleChangeRowsPerPage = (event) => {
    //return 'fix';
    // this.setState({
    //   loading: true,
    // });
    this.props.setTablePageSize(event.target.value);
    this.props.setTableOffset(this.props.table.page * this.props.table.pageSize);
    const offset = this.props.table.page * this.props.table.pageSize;
    const rowsPerPage = event.target.value;
    const page = Math.floor(offset / rowsPerPage);
    this.props.setTablePage(page);
    // this.refreshPageView(offset, rowsPerPage, page);
  };

  additionalOlinProcessing = (obj) => {
    const theme = initializeTheme(this.props.colorMode);
    let localObj = obj;
    localObj.status = this.checkDeviceOperatingStatusOlin(localObj);
    let color = localObj.status === 'Issue Found' ? theme.palette.red.color : localObj.status === 'Turned Away' ? theme.palette.blue.color : theme.palette.green.color;
    localObj.style = { borderLeft: '8px solid ' + color };

    //localObj.follow_up_required = localObj.follow_up_required === '1' ? 'Yes' : 'No';
    localObj.is_follow_up = localObj.is_follow_up === '1' ? 'Yes' : 'No';

    //localObj.follow_up_required = localObj.follow_up_required === '1' ? 'Yes' : 'No';
    localObj.combined_follow_up_required = localObj.follow_up_required === 'Yes' || localObj.repair_required === 'Yes, put WO# in Comments' ? 'Yes' : 'No';
    let signature_date_utc = new Date(localObj.signature_date).getTime();
    localObj.signature_date = functions.dateToString(signature_date_utc, 'date');

    localObj.dcs_measurement_af = parseFloat(localObj.dcs_measurement_af);
    localObj.dcs_measurement_al = parseFloat(localObj.dcs_measurement_al);
    localObj.field_measurement_af = parseFloat(localObj.field_measurement_af);
    localObj.field_measurement_al = parseFloat(localObj.field_measurement_al);

    localObj.maximum_dcs_deviation = parseFloat(localObj.maximum_dcs_deviation);
    localObj.measurement_range_high = parseFloat(localObj.measurement_range_high);
    localObj.measurement_range_low = parseFloat(localObj.measurement_range_low);
    localObj.within_dcs = localObj.within_dcs === 1 ? 'Yes' : localObj.within_dcs === 0 ? 'No' : localObj.within_dcs;

    //localObj.schedule_category = localObj.deviceId % 2 === 0 ? 'Scheduled' : 'Unscheduled';

    localObj.utcTime = new Date(localObj.date_time).getTime();
    localObj.serviceMonth = new Date(localObj.date_time).getFullYear() + ' - ' + ('0' + (new Date(localObj.date_time).getMonth() + 1)).slice(-2);
    return localObj;
  };

  additionalStreamlineProcessing = (obj) => {
    const theme = initializeTheme(this.props.colorMode);
    let localObj = obj;
    localObj.meterCheck = this.checkDeviceOperatingStatus(localObj);
    //console.log(localObj.meterCheck);
    localObj.actionCheck = 'TRUE';
    localObj.change_found_left_pH = localObj.as_left_value_pH - localObj.as_found_value_pH;
    localObj.as_found_value_rounded_pH = Math.round(localObj.as_found_value_pH * 10) / 10;
    localObj.as_left_value_rounded_pH = Math.round(localObj.as_left_value_pH * 10) / 10;
    localObj.change_value_rounded_pH = Math.round((localObj.as_left_value_pH - localObj.as_found_value_pH) * 10) / 10;

    localObj.change_found_left_orp = localObj.as_left_value_orp - localObj.as_found_value_orp;
    localObj.as_found_value_rounded_orp = Math.round(localObj.as_found_value_orp * 10) / 10;
    localObj.as_left_value_rounded_orp = Math.round(localObj.as_left_value_orp * 10) / 10;
    localObj.change_value_rounded_orp = Math.round((localObj.as_left_value_orp - localObj.as_found_value_orp) * 10) / 10;

    /*localObj.report_link = (
            <Button variant='outlined' onClick={() => report_link_function(localObj.submission_id)}>
              Get Report
            </Button>
          );*/
    /*let lat = localObj.location_gps_lat;
          let lat_suff = lat > 0 ? 'N' : 'S';
          let long = localObj.location_gps_long;
          let long_suff = long > 0 ? 'E' : 'W';
          localObj.map_link = <a href={`https://www.google.com/maps/place/${Math.abs(lat)}${lat_suff}+${Math.abs(long)}${long_suff}`}>Map Link</a>;
          console.log(`https://www.google.com/maps/place/${Math.abs(lat)}${lat_suff}+${Math.abs(long)}${long_suff}`);*/
    localObj.report_link = localObj.deviceId; //<FastFieldImage file={localObj.asset_photos[0]} />;
    localObj.status = this.checkDeviceOperatingStatus(localObj);
    //off, recommended action, no issue
    //TODO Consider alternative coloration
    let color = localObj.status === 'Broken Probe' ? theme.palette.red.color : localObj.status === 'Additional Action' ? theme.palette.blue.color : theme.palette.green.color;
    //console.log(color);
    localObj.style = { borderLeft: '8px solid ' + color };

    //localObj.schedule_category = localObj.deviceId % 2 === 0 ? 'Scheduled' : 'Unscheduled';

    localObj.utcTime = new Date(localObj.date_time).getTime();
    localObj.serviceMonth = new Date(localObj.date_time).getFullYear() + ' - ' + ('0' + (new Date(localObj.date_time).getMonth() + 1)).slice(-2);

    //DATA CLEANUP TO NORMAL
    localObj.further_repair = !localObj.further_repair || localObj.further_repair === '0' ? 'No' : 'Yes';
    localObj.customer_notified = !localObj.customer_notified || localObj.customer_notified === '0' ? 'No' : 'Yes';
    localObj.application = localObj.application === 'R' ? 'Regen' : 'Spent';
    return localObj;
  };

  //Look through filters and rows of the data at the designated points and return all the match, using the orArray to set wether a particular column allows AND vs OR checks
  processData = (fsr_type) => {
    /*let data = _.map(this.props.loops, (loop) => {
      let loopAdj = loop;
      loopAdj.meterCheck = 'TRUE';
      loopAdj.actionCheck = 'TRUE';
      //return loopAdj;
    });*/
    const filters = this.props.sort.filter;
    //let rowId = 1;
    //const report_link_function = this.getFastFieldReport;
    let filterList = [];
    let filterListRow = [];
    //console.log(fsr_type);
    if (fsr_type === 'Olin') {
      filterList = [
        /*'Customer', */ 'Site',
        'Application',
        'Status',
        'Schedule Type',
        'Service Type',
        'Follow Up Required' /* 'Unit', 'Application', 'Line', 'Manufacturer', 'Device Type', 'Year', 'Status', 'Importance', 'Recommendations'*/,
      ];
      filterListRow = [
        //'customer',
        'site',
        'application',
        'status',
        'schedule_category',
        'expected_service_category',
        'combined_follow_up_required',
        /*'plant_location',
      'device_location',
      'vessel_line_location',
      'manufacturer',
      'asset_description',
      'year_manufactured',
      'meterCheck',
      'criticality',
      'recommendation_type',*/
      ];
    } /*if (fsr_type === 'Streamline')*/ else {
      filterList = [
        'Customer',
        'Site',
        'Application',
        'Status',
        'Service Type',
        'Service Status',
        /*'Follow Up Required'* /* 'Unit', 'Application', 'Line', 'Manufacturer', 'Device Type', 'Year', 'Status', 'Importance', 'Recommendations'*/
      ];
      filterListRow = [
        'customer',
        'site',
        'application',
        'status',
        'service_type',
        'meterCheck',
        /* 'further_repair',
        /*'plant_location',
      'device_location',
      'vessel_line_location',
      'manufacturer',
      'asset_description',
      'year_manufactured',
      'meterCheck',
      'criticality',
      'recommendation_type',*/
      ];
    }
    let searchFilterListRow = ['tag', 'serial'];

    let orArray = [true, true, true, true, true, true, true, true, true, true, true, true, true, true]; //sets column check to and vs or (default and);
    //console.log(filters);
    let outerThis = this;

    //const theme = initializeTheme(this.props.colorMode);
    let data = _.reduce(
      this.props.loops,
      function (acc, obj) {
        //acc stands for accumlation, [] is the accumulator
        let match = true;
        let searchMatch = false;
        //console.log(obj);
        //search extra fields first to save time if there is a match
        for (let i = 0; i < searchFilterListRow.length; i++) {
          if (
            !outerThis.state.searchValue ||
            outerThis.state.searchValue === '' ||
            (!searchMatch &&
              !!obj[searchFilterListRow[i]] &&
              !!outerThis.state.searchValue &&
              outerThis.state.searchValue !== '' &&
              obj[searchFilterListRow[i]].toString().toLowerCase().indexOf(outerThis.state.searchValue.toLowerCase()) !== -1)
          ) {
            //console.log(obj[filterListRow[j]]);
            searchMatch = true;
          }
        }

        if (fsr_type === 'Streamline' || fsr_type === 'Demo') {
          obj = outerThis.additionalStreamlineProcessing(obj);
        }
        if (fsr_type === 'Olin') {
          obj = outerThis.additionalOlinProcessing(obj);
        }
        //let match = false; //when and/or disabled

        //do filter + search fields
        for (let i = 0; i < filterList.length; i++) {
          let filterListArr = !filters[filterList[i]] ? [] : filters[filterList[i]];
          /*console.log(filterList[i]);
          console.log(obj[filterListRow[i]]);
          console.log(filterListArr);*/
          /* !and or disabled 
         for (let j = 0; j < filterListArr.length; j++) {
            if (
              !filterListArr[j] ||
              filterListArr[j].length === 0 ||
              !obj[filterListRow[i]] ||
              (!!obj[filterListRow[i]] &&
                obj[filterListRow[i]].indexOf(filterListArr[j]) !== -1)
            ) {
              match = true;
            }
          }*/
          // console.log(filterListArr);
          // //if ((!filters[filterList[i]] || filters[filterList[i]].length === 0 || filters[filterList[i]].indexOf(obj[filterListRow[i]]) !== -1) && match) {
          let orMatch = filterListArr.length === 0 && match ? true : false; //convert check to an OR check from an AND check
          //console.log(orMatch);
          //console.log(orArray[i], filterListRow[i]);

          //Checking the row for the search term if one exists
          if (
            !outerThis.state.searchValue ||
            outerThis.state.searchValue === '' ||
            (!searchMatch &&
              !!obj[filterListRow[i]] &&
              !!outerThis.state.searchValue &&
              outerThis.state.searchValue !== '' &&
              obj[filterListRow[i]].toString().toLowerCase().indexOf(outerThis.state.searchValue.toLowerCase()) !== -1)
          ) {
            //console.log(obj[filterListRow[j]]);
            searchMatch = true;
          }

          for (let j = 0; j < filterListArr.length; j++) {
            // orcheck - later
            // filterList doesn't exist
            // we're not filtering on anything
            // object is in the result
            // match is still true
            // note: orArray = false makes it an and

            if (
              ((orMatch && orArray[i]) ||
                !filterListArr[j] ||
                filterListArr[j].length === 0 ||
                (!obj[filterListRow[i]] && !filterListArr[j]) ||
                (!!obj[filterListRow[i]] && obj[filterListRow[i]].indexOf(filterListArr[j]) !== -1)) &&
              match
            ) {
              orMatch = true;
            } else if (!orArray[i]) {
              match = false;
            } /*&& true*/
          }
          match = orMatch;
        }

        //console.log(ignore);
        if (match && searchMatch) {
          //obj.rowId = rowId;
          //rowId++;
          acc.push(obj);
        }
        //console.log(acc);
        return acc;
      },
      []
    );
    let sort_key = 'date_time';
    data.sort((a, b) => {
      if (sort_key === 'date_time') {
        return new Date(b[sort_key]) - new Date(a[sort_key]);
      } else {
        //console.log(a[sort_key], b[sort_key], a[sort_key] > b[sort_key]);
        if (a[sort_key] > b[sort_key]) {
          return -1;
        }
        if (b[sort_key] > a[sort_key]) {
          return 1;
        }
        return 0;
      }
    });
    return data;
  };

  createRecommendationPie = (data) => {
    let recommendation_types = {};

    for (let i = 0; i < data.length; i++) {
      let recommendation_type_split = !data[i].recommendation_type ? [] : data[i].recommendation_type.split(',');
      for (let j = 0; j < recommendation_type_split.length; j++) {
        if (!recommendation_types[recommendation_type_split[j]]) {
          recommendation_types[recommendation_type_split[j]] = 1;
        } else {
          recommendation_types[recommendation_type_split[j]]++;
        }
      }
    }

    let obj_keys = Object.keys(recommendation_types);
    //console.log(obj_keys);
    let pie_data = [];
    for (let i = 0; i < obj_keys.length; i++) {
      pie_data.push({
        name: obj_keys[i],
        y: recommendation_types[obj_keys[i]],
      });
    }
    return pie_data;
  };

  checkDeviceOperatingStatusOlin = (device) => {
    if (!device) {
      return 'Error';
    } else if (!!device.turned_away && device.turned_away === 1) {
      return 'Turned Away';
    } /* else if (device.meter_measurement_check === '0') {
      return 'Incorrect';
    }*/ else if (
      device.is_tag_label_damaged === 'Yes' ||
      device.is_special_function_label_damaged === 'Yes' ||
      device.is_cable_label_damaged === 'Yes' ||
      device.shs_corrosion === 'Yes' ||
      device.shs_connections === 'Yes' ||
      device.shs_indicators_damaged === 'Yes' ||
      device.shs_pressure_flow_values === 'No' ||
      device.shs_functioning === 'No' ||
      device.shs_utilities_functioning === 'No' ||
      device.shs_heat_tracing_damage === 'Yes' ||
      device.loose_wiring === 'Yes' ||
      device.cable_damaged === 'Yes' ||
      device.covers_drains_plugs_missing === 'Yes' ||
      device.conduit_openings === 'Yes' ||
      device.analyzer_damage === 'Yes' ||
      device.indicator_damage === 'Yes' ||
      device.piping_damage === 'Yes' ||
      device.analyzer_heat_tracing_damage === 'Yes' ||
      device.sensor_connection_damage === 'Yes' ||
      device.probe_damage === 'Yes' ||
      device.probe_buildup === 'Yes' ||
      device.transmitter_issue === 'Yes' ||
      device.sap_discrepancy === 'Yes' ||
      //SPACER
      //SPACER

      device.is_tag_label_damaged === 'Quick Fix' ||
      device.is_special_function_label_damaged === 'Quick Fix' ||
      device.is_cable_label_damaged === 'Quick Fix' ||
      device.shs_corrosion === 'Quick Fix' ||
      device.shs_connections === 'Quick Fix' ||
      device.shs_indicators_damaged === 'Quick Fix' ||
      device.shs_pressure_flow_values === 'Quick Fix' ||
      device.shs_functioning === 'Quick Fix' ||
      device.shs_utilities_functioning === 'Quick Fix' ||
      device.shs_heat_tracing_damage === 'Quick Fix' ||
      device.loose_wiring === 'Quick Fix' ||
      device.cable_damaged === 'Quick Fix' ||
      device.covers_drains_plugs_missing === 'Quick Fix' ||
      device.conduit_openings === 'Quick Fix' ||
      device.analyzer_damage === 'Quick Fix' ||
      device.indicator_damage === 'Quick Fix' ||
      device.piping_damage === 'Quick Fix' ||
      device.analyzer_heat_tracing_damage === 'Quick Fix' ||
      device.sensor_connection_damage === 'Quick Fix' ||
      device.probe_damage === 'Quick Fix' ||
      device.probe_buildup === 'Quick Fix' ||
      device.transmitter_issue === 'Quick Fix' ||
      device.sap_discrepancy === 'Quick Fix'
    ) {
      return 'Issue Found';
    } else {
      return 'No Issue';
    }
  };

  checkDeviceOperatingStatus = (device) => {
    if (!device) {
      return 'Error';
    } else if (!!device.probe_broken && device.probe_broken.toLowerCase() !== 'no' && device.probe_broken.toLowerCase() !== '0') {
      return 'Broken Probe';
    } /* else if (device.meter_measurement_check === '0') {
      return 'Incorrect';
    }*/ else if (
      !!device.comment &&
      device.comment.toLowerCase() !== 'no' &&
      device.comment.toLowerCase() !== 'none' &&
      device.comment.toLowerCase() !== 'n' &&
      device.comment.toLowerCase().replace('/', '') !== 'na'
    ) {
      return 'Additional Action';
    } else {
      return 'No Issue';
    }
  };

  createDeviceOperationColumn = (data, xCategory) => {
    let operationColumnObj = {};
    const theme = initializeTheme(this.props.colorMode);
    for (let i = 0; i < data.length; i++) {
      let xAxisType = data[i][xCategory] === null ? 'Unknown' : data[i][xCategory];
      if (!operationColumnObj[xAxisType]) {
        operationColumnObj[xAxisType] = {
          'No Issue': 0,
          Off: 0,
          Incorrect: 0,
          'Recommended Action': 0,
        };
      }
      //console.log(xAxisType, this.checkDeviceOperatingStatus(data[i]));
      operationColumnObj[xAxisType][data[i].status]++;
    }
    //console.log(operationColumnObj);
    let types = ['Off', /* 'Incorrect',*/ 'Recommended Action', 'No Issue'];
    let colorTypes = ['#888', theme.palette.blue.color, /* '#e3a61b',*/ '#308D3B'];
    let obj_keys = Object.keys(operationColumnObj);
    let stacking_data = [];
    //console.log(operationColumnObj);
    for (let i = 0; i < types.length; i++) {
      let dataArr = [];
      for (let j = 0; j < obj_keys.length; j++) {
        dataArr.push(operationColumnObj[obj_keys[j]][types[i]]);
      }
      //console.log(obj_keys[i]);
      //console.log(dataArr);

      stacking_data.push({
        name: types[i],
        data: dataArr,
        color: colorTypes[i],
      });
    }
    //console.log(stacking_data);
    //console.log({ importanceTypes: obj_keys, stackingData: stacking_data });
    return { importanceTypes: obj_keys, stackingData: stacking_data };
    /* let importanceTypes = ['Extremely Important', 'Very Important', 'Important', 'Standard', 'Not Important'];
    let stackingData = [
      {
        name: 'Incorrect Measurement',
        data: [1, 2, 1, 0, 0],
        color: '#D3001B',
      },
      {
        name: 'Off',
        data: [4, 3, 3, 0, 0],
        color: '#e3a61b',
      },
      {
        name: 'On',
        data: [6, 7, 2, 1, 0],
        color: '#308D3B',
      },
    ];
    return { importanceTypes, stackingData };*/
  };

  createTypeColumn = (data, xCategory, yCategories, yKey) => {
    let operationColumnObj = {};
    //console.log(data, xCategory);
    //const theme = initializeTheme(this.props.colorMode);
    for (let i = 0; i < data.length; i++) {
      let xAxisType = data[i][xCategory] === null ? 'Unknown' : data[i][xCategory];

      if (!operationColumnObj[xAxisType]) {
        operationColumnObj[xAxisType] = {};
        for (let j = 0; j < yCategories.length; j++) {
          operationColumnObj[xAxisType][yCategories[j]] = 0;
        }
      }
      //console.log(xAxisType, this.checkDeviceOperatingStatus(data[i]));
      operationColumnObj[xAxisType][data[i][yKey]]++;
    }
    //console.log(operationColumnObj);
    let types = yCategories; //['Scheduled', /* 'Incorrect',*/ 'Unscheduled', 'Callout', 'Rescheduled'];
    //let colorTypes = ['#888', theme.palette.blue.color, /* '#e3a61b',*/ '#308D3B'];
    let obj_keys = Object.keys(operationColumnObj);
    obj_keys = obj_keys.sort();
    let stacking_data = [];
    //console.log(operationColumnObj);
    //console.log(types);
    for (let i = 0; i < types.length; i++) {
      let dataArr = [];
      for (let j = 0; j < obj_keys.length; j++) {
        dataArr.push(operationColumnObj[obj_keys[j]][types[i]]);
      }
      //console.log(obj_keys[i]);
      //console.log(dataArr);

      stacking_data.push({
        name: types[i],
        data: dataArr,
        //color: colorTypes[i],
      });
    }
    //console.log(stacking_data);
    //console.log(obj_keys);
    //console.log({ xAxis: obj_keys, data: stacking_data });
    return { xAxis: obj_keys, data: stacking_data };
    /* let importanceTypes = ['Extremely Important', 'Very Important', 'Important', 'Standard', 'Not Important'];
    let stackingData = [
      {
        name: 'Incorrect Measurement',
        data: [1, 2, 1, 0, 0],
        color: '#D3001B',
      },
      {
        name: 'Off',
        data: [4, 3, 3, 0, 0],
        color: '#e3a61b',
      },
      {
        name: 'On',
        data: [6, 7, 2, 1, 0],
        color: '#308D3B',
      },
    ];
    return { importanceTypes, stackingData };*/
  };

  createPieData = (data, type) => {
    let obj = {};
    if (type === 'Olin') {
      obj = { 'No Issue': 0, 'Turned Away': 0, 'Issue Found': 0, Error: 0 };
    } else {
      obj = {
        'No Issue': 0,
        'Broken Probe': 0,
        'Additional Action': 0,
        Error: 0,
      };
    }
    for (let i = 0; i < data.length; i++) {
      let key = 'Error';
      if (type === 'Olin') {
        key = this.checkDeviceOperatingStatusOlin(data[i]);
      } else {
        key = this.checkDeviceOperatingStatus(data[i]);
      }
      obj[key]++;
    }
    if (type === 'Olin') {
      return [
        {
          name: 'No Issue',
          y: obj['No Issue'],
        },
        {
          name: 'Issue Found',
          y: obj['Issue Found'],
        },
        {
          name: 'Turned Away',
          y: obj['Turned Away'],
        },
      ];
    } else {
      return [
        {
          name: 'No Issue',
          y: obj['No Issue'],
        },
        {
          name: 'Broken Probe',
          y: obj['Broken Probe'],
        },
        {
          name: 'Additional Action',
          y: obj['Additional Action'],
        },
      ];
    }
  };

  getUniqueValues = (data, prop, csv) => {
    let uniqueValues = [];
    for (let i = 0; i < data.length; i++) {
      if (!data[i][prop]) {
        console.log(`Error: Undefined in getUniqueValues() for ${prop}`);
      } else {
        let values = !csv ? [data[i][prop]] : data[i][prop].split(',');
        for (let j = 0; j < values.length; j++) {
          if (uniqueValues.indexOf(values[j]) === -1 && !!values[j]) {
            uniqueValues.push(values[j].toString());
          } else if (!values[j]) {
            console.log(`Error: Undefined in getUniqueValues() for ${prop}`);
          }
        }
      }
    }
    return uniqueValues;
  };

  customSelectFactory = (label, values) => {
    return (
      <CustomSelect
        id={null}
        label={label}
        disabled={this.state.loading}
        onCloseFunction={this.multiSelectOnClose}
        onChangeFunction={this.multiSelectOnChange}
        //type='unit'
        values={!this.state[label] ? [] : this.state[label]}
        options={values /*!this.props.sites || !this.props.sites.list ? [] : this.props.sites.list.sort()*/}
        value={null}
        helperText={null}
      />
    );
  };

  render() {
    //TODO ARRANGE FILTERS BELOW TO BE TYPED
    //console.log('FSR Render');
    const { classes } = this.props;
    let fsr_type = !this.props.fsr_type ? 'default' : this.props.fsr_type;
    let data = this.processData(fsr_type);
    let count = data.length;

    //console.log(data);
    let customer_names = fsr_type !== 'Olin' ? this.getUniqueValues(data, 'customer') : [];
    let site_names = this.getUniqueValues(data, 'site');
    let application_names = this.getUniqueValues(data, 'application');
    //let status_names = this.getUniqueValues(data, "status");
    let schedule_category_names = this.getUniqueValues(data, fsr_type === 'Olin' ? 'schedule_category' : 'service_type');
    let further_repair_names = this.getUniqueValues(data, fsr_type === 'Olin' ? 'combined_follow_up_required' : 'further_repair');
    let status_names = this.getUniqueValues(data, 'meterCheck');
    let expected_service_categories = this.getUniqueValues(data, 'expected_service_category');

    const serviceChart = this.createTypeColumn(
      data,
      'serviceMonth',
      fsr_type === 'Olin' ? ['Scheduled', 'Unscheduled', 'Callout' /*, 'Rescheduled'*/] : ['Scheduled', 'Callout'],
      fsr_type === 'Olin' ? 'schedule_category' : 'service_type'
    );
    const customerChart = fsr_type === 'Olin' ? this.createTypeColumn(data, 'serviceMonth', site_names, 'site') : this.createTypeColumn(data, 'serviceMonth', customer_names, 'customer');
    const pieData = this.createPieData(data, fsr_type);
    //const pie_recommendation_data = this.createRecommendationPie(data);
    //let customer_names = this.getUniqueValues(data, 'customer');
    // let plant_names = this.getUniqueValues(data, 'plant_name');
    // let unit_names = this.getUniqueValues(data, 'plant_location');
    // let application_names = this.getUniqueValues(data, 'device_location');
    // let line_names = this.getUniqueValues(data, 'vessel_line_location');
    // let device_type_names = this.getUniqueValues(data, 'asset_type');
    // let manufacturer_names = this.getUniqueValues(data, 'manufacturer');
    // //let year_names = this.getUniqueValues(data, 'year_manufactured');
    // let status_names = this.getUniqueValues(data, 'meterCheck');
    // let importance_names = this.getUniqueValues(data, 'criticality');
    // //let recommendations_names = this.getUniqueValues(data, 'actionCheck');
    // let recommendation_types = this.getUniqueValues(data, 'recommendation_type', true);

    /*let headerArr = [
      //{ id: 'tag', label: 'Device' },
      //{ id: 'report_link', label: 'Link' },
      { id: 'tag', label: 'Device' },
      { id: 'asset_type', label: 'Device Type' },
      { id: 'plant_name', label: 'Plant' },
      { id: 'plant_location', label: 'Unit' },
      { id: 'device_location', label: 'Application' },
      { id: 'vessel_line_location', label: 'Line' },
      { id: 'manufacturer', label: 'Manuf.' },
      //{ id: 'order_code', label: 'order_code' },
      // { id: 'year_manufactured', label: 'Year Manuf.' },
      //{ id: 'meterCheck', label: 'Meter Status' },
      { id: 'criticality', label: 'Importance' },
      //{ id: 'actionCheck', label: 'Recommendations' },
      { id: 'recommendation_type', label: 'Recommendation Types' },
      { id: 'link_formatted', label: 'Report' },

      //{ id: '', label: '' },
      //{ id: '', label: '' },
    ];*/

    /*for (let i = 0; i < data.length; i++) {
      data[i].link_formatted = (
        <React.Fragment>
          {
            //<Typography>{data[i].tag}</Typography>
          }
          <Button variant='outlined' size='medium' color='primary' className={classes.button} href={`FSR/detail/${data[i].report_link}`} style={{ marginTop: 8, marginBottom: 8 }}>
            <Link className={classes.buttonIcon}></Link>View Device
          </Button>
        </React.Fragment>
      );
    }*/

    const theme = initializeTheme(this.props.colorMode);
    /*const monoColors = Highcharts.getOptions().colors.map((c, i) =>
      // Start out with a darkened base color (negative brighten), and end
      // up with a much brighter color
      Highcharts.color(Highcharts.getOptions().colors[0])
        .brighten((i - 6) / 16)
        .get()
    );*/
    /* const monoColors2 = Highcharts.getOptions().colors.map((c, i) =>
      // Start out with a darkened base color (negative brighten), and end
      // up with a much brighter color
      Highcharts.color(Highcharts.getOptions().colors[7])
        .brighten((i - 3) / 12)
        .get()
    );*/
    //console.log(this.props.loops);
    if (!this.props.auth) {
      return this.renderLoading();
    }
    if (!!this.props.auth && this.props.auth.fsr === 0) {
      return <div>You do not have permission to access this module</div>;
    }
    // let count = 0;
    // let keys = Object.keys(this.props.loops);
    // for (var k in keys) {
    //   if (this.props.loops[keys[k]].hasOwnProperty('rowId')) {
    //     ++count;
    //   }
    // }
    const printStyles = functions.setPrintStyles(this.props.activeTab);
    //console.log(this.props);
    return (
      <React.Fragment>
        <form className={classes.form} autoComplete='off' style={{ marginTop: 10 }}>
          <Grid container justifyContent='space-between'>
            <Grid item>
              <CustomSelect
                single
                id={null}
                label='Sort'
                type='sort'
                disabled={this.state.loading}
                //onCloseFunction={this.multiSelectOnClose}
                onChangeFunction={this.handleSort}
                values={!this.props.sort.sort ? 'Date' : this.props.sort.sort}
                options={
                  /*!this.props.sort.sort
                ? ''
                :*/ ['Date' /*'Concern', 'Concern Max', 'Concern Shift', 'Importance', 'Name', 'Application Order' /*'Priority'*/]
                }
                //value={null}
                helperText={null}
              />
              {fsr_type === 'Olin' ? null : this.customSelectFactory('Customer', customer_names)}
              {this.customSelectFactory('Site', site_names)}
              {this.customSelectFactory('Application', application_names)}
              {this.customSelectFactory('Schedule Type', schedule_category_names)}
              {this.customSelectFactory('Service Type', expected_service_categories)}
              {/*this.customSelectFactory("Status", status_names)*/}
              {fsr_type === 'Olin' ? this.customSelectFactory('Follow Up Required', further_repair_names) : null}
              {fsr_type === 'Olin' ? null : this.customSelectFactory('Service Status', status_names)}
              <TextField
                placeholder='Search'
                //value={!this.state.searchValueTemp ? '' : this.state.searchValueTemp}
                variant='outlined'
                //onChange={this.onSearchChange}
                /*onChange={() => {
                  console.log('change');
                }}*/
                onBlur={this.onSearchBlur}
              />
            </Grid>
            <Grid item>
              <Button className={`${classes.buttonFilterRight} ${classes.printHide}`} variant='outlined' onClick={this.resetFilters} style={{ float: 'right', marginTop: 14 }}>
                <RestartAlt className={classes.buttonIcon}></RestartAlt>Clear All Filters
              </Button>
              {/*<Button
                className={`${classes.buttonFilterRight} ${classes.printHide}`}
                variant='outlined'
                component={Link}
                to={'/support/Prioritize'}
                style={{ float: 'right', marginLeft: 0, marginTop: 14 }}
                onClick={() => this.props.setActiveNav(7)}
              >
                <Directions className={classes.buttonIcon}></Directions>View Roadmaps
                      </Button>*/}
            </Grid>
          </Grid>
          {/*<DatePicker
            label='Start Date'
            disabled={this.state.loading}
            date={`${new Date().getFullYear() - 2}-01-01T12:00:00`}
            dateFunction={(startDate) => {
              this.updateDateRange(startDate, 'start');
            }}
          ></DatePicker>
          <DatePicker
            label='End Date'
            disabled={this.state.loading}
            dateFunction={(endDate) => {
              this.updateDateRange(endDate, 'end');
            }}
          ></DatePicker>*/}
          <Legend type={fsr_type === 'Olin' ? 'Status' : 'Streamline'}></Legend>
        </form>
        <Grid container justifyContent='space-between'>
          <Grid item>{/*this.renderTotals()*/}</Grid>
        </Grid>
        <Grid container spacing={1}>
          <ReportChartWidget
            name='Status Breakdown'
            data={pieData}
            type='pie'
            colors={[theme.palette.green.color, theme.palette.red.color, theme.palette.blue.color] /*monoColors*/}
            initialWidth={printStyles ? 12 : 4}
            loading={this.state.loading}
          ></ReportChartWidget>
          <ReportChartWidget
            name='Service Status'
            colors={[theme.palette.blue.dark, theme.palette.gray.color, theme.palette.blue.light]}
            type='stack'
            seriesData={serviceChart.data}
            categories={serviceChart.xAxis}
            initialWidth={printStyles ? 6 : 4}
            loading={this.state.loading}
          ></ReportChartWidget>
          <ReportChartWidget
            name='Customers'
            //colors={[theme.palette.red.color, theme.palette.yellow.color, theme.palette.green.color]}
            type='stack'
            seriesData={customerChart.data}
            categories={customerChart.xAxis}
            initialWidth={printStyles ? 6 : 4}
            loading={this.state.loading}
          ></ReportChartWidget>
          {/*<ReportChartWidget name='Recommendations' data={pie_recommendation_data} type='pie' colors={monoColors2} initialWidth={printStyles ? 6 : 4} loading={this.state.loading}></ReportChartWidget>*/}
        </Grid>
        <div className={classes.avoidBreak}>
          <WidgetTitle title={`Field Service Report`} />
          {this.renderLoading()}
          {count === 0 ? null : (
            <Grid container style={{ marginTop: -10 }}>
              <Grid item>
                <TablePagination
                  rowsPerPageOptions={[24, 48, 96]}
                  component='div'
                  count={count}
                  rowsPerPage={this.props.table.pageSize}
                  page={this.props.table.page}
                  backIconButtonProps={{
                    'aria-label': 'Previous Page',
                  }}
                  nextIconButtonProps={{
                    'aria-label': 'Next Page',
                  }}
                  labelRowsPerPage='Devices per Page'
                  onPageChange={this.handleChangePage}
                  onRowsPerPageChange={this.handleChangeRowsPerPage}
                  onClick={(e) => e.preventDefault()}
                  className={classes.paginationFix}
                  showFirstButton
                  showLastButton
                />
              </Grid>
            </Grid>
          )}
          {data.map((device, index) => {
            if (/*device.rowId*/ index > this.props.table.offset && /*device.rowId*/ index <= this.props.table.offset + this.props.table.pageSize) {
              return <FSRTile type={fsr_type} key={`fsr_tile_${index}`} device={device} hasBorder />;
            } else {
              return null;
            }
          })}
          {count === 0 ? null : (
            <Grid container style={{ marginTop: -10 }}>
              <Grid item>
                <TablePagination
                  rowsPerPageOptions={[24, 48, 96]}
                  component='div'
                  count={count}
                  rowsPerPage={this.props.table.pageSize}
                  page={this.props.table.page}
                  backIconButtonProps={{
                    'aria-label': 'Previous Page',
                  }}
                  nextIconButtonProps={{
                    'aria-label': 'Next Page',
                  }}
                  labelRowsPerPage='Devices per Page'
                  onPageChange={this.handleChangePage}
                  onRowsPerPageChange={this.handleChangeRowsPerPage}
                  onClick={(e) => e.preventDefault()}
                  className={classes.paginationFix}
                  showFirstButton
                  showLastButton
                />
              </Grid>
            </Grid>
          )}
          {/*<TableWidget header={headerArr} data={data} defaultRows={25} loading={this.state.loading}></TableWidget>*/}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    applications: state.applications,
    importances: state.importances,
    locations: state.locations,
    loops: state.devices.devices,
    loopCount: state.devices.count,
    format: state.format.format,
    current_realm: state.auth.current_realm,
    sort: state.sort,
    table: state.table,
    sites: state.sites,
    issueTypes: state.issuetypes,
    currentModule: state.currentModule,
    colorMode: state.colorMode.colorMode,
    fsr_type: state.auth.fsr_type,
  };
};

export default connect(mapStateToProps, {
  fetchOptimizeDevices,
  fetchDevicesSummary,
  fetchDevices,
  fetchEvents,
  setTableOffset,
  setTablePageSize,
  setTablePage,
  setModule,
  updateSort,
  updateDateRange,
  fetchLocations,
  fetchApplications,
  fetchImportances,
  fetchSites,
  fetchIssueTypes,
  deleteLoops,
  deleteDevices,
  fetchSort,
  fetchLoops,
  updateFilter,
  clearFilter,
})(withStyles(styles)(FSR));
