import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import withStyles from '@mui/styles/withStyles';
import { Link } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Addchart, Engineering, Assignment } from '@mui/icons-material';
import { connect } from 'react-redux';
import { prioritizeSetActiveTab } from '../../actions/index';
import { styles } from '../styles';

class PrioritizeTabs extends React.Component {
  setActiveTab = (event, value) => {
    this.props.prioritizeSetActiveTab(value);
  };
  render() {
    const { classes } = this.props;
    return (
      <Paper square className={classes.tabsWrapper} elevation={3}>
        <Tabs value={this.props.activeTab} onChange={this.setActiveTab} variant='fullWidth' indicatorColor='secondary' textColor='secondary' className={classes.tabs}>
          <Tab className={classes.tab} icon={<Addchart />} label='Workbench' value='workbench' component={Link} to={`workbench`} />
          <Tab className={classes.tab} icon={<Engineering />} label='Address Issues' value='issues' component={Link} to={`issues`} />
          <Tab className={classes.tab} icon={<Assignment />} label='Action Items' value='actions' component={Link} to={`actions`} />
        </Tabs>
      </Paper>
    );
  }
}

PrioritizeTabs.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    activeTab: state.prioritize.activeTab,
    activeLoop: state.prioritize.activeLoop,
    loops: state.loops.loops,
  };
};

export default connect(mapStateToProps, { prioritizeSetActiveTab })(withStyles(styles)(PrioritizeTabs));
