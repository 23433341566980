import _ from "lodash";
import React from "react";
import withStyles from "@mui/styles/withStyles";
//import SingleSelect from '../filters/SingleSelect';
import OptimizeTile from "../tiles/OptimizeTile";
import CustomSelect from "../filters/CustomSelect";
//import ToggleSwitch from '../filters/ToggleSwitch';
import Divider from "@mui/material/Divider";
import {
  Grid,
  Button,
  Typography,
  TablePagination,
  SvgIcon,
  Tooltip,
} from "@mui/material";
import { styles } from "../styles";
import { connect } from "react-redux";
import initializeTheme from "../theme";
import functions from "../../functions/functions.js";
import {
  fetchOptimizeDevices,
  fetchDevicesSummary,
  fetchEvents,
  setTableOffset,
  setTablePageSize,
  setTablePage,
  setModule,
  updateSort,
  updateDateRange,
  fetchLocations,
  fetchApplications,
  fetchImportances,
  fetchSites,
  fetchIssueTypes,
  deleteLoops,
  deleteDevices,
  fetchSort,
  fetchLoops,
  updateFilter,
  clearFilter,
} from "../../actions/index";
import { CircularProgress } from "@mui/material";
import DatePicker from "../filters/DatePicker";
import { RestartAlt } from "@mui/icons-material";

class Optimize extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rowsPerPage: 24,
      page: 0,
      order: "",
      orderBy: "",
      loading: true,
      clear: false,
      changes: false,
    };
    this.resetFilters = this.resetFilters.bind(this);
  }
  alphabetical_sort_object_of_objects(data, attr) {
    var arr = [];
    for (let prop in data) {
      if (data.hasOwnProperty(prop)) {
        let obj = {};
        obj[prop] = data[prop];
        obj.tempSortName =
          typeof data[prop][attr] === "string"
            ? data[prop][attr].toLowerCase()
            : data[prop][attr];
        arr.push(obj);
      }
    }

    arr.sort(function (a, b) {
      const arbNum = 999999999999999; //arbitrary high number

      var at = !a.tempSortName ? arbNum : a.tempSortName,
        bt = !b.tempSortName ? arbNum : b.tempSortName;
      return at > bt ? 1 : at < bt ? -1 : 0;
    });
    //console.log(arr);

    var result = [];
    for (var i = 0, l = arr.length; i < l; i++) {
      let obj = arr[i];
      delete obj.tempSortName;
      for (let prop in obj) {
        if (obj.hasOwnProperty(prop)) {
          var id = prop;
        }
      }
      var item = obj[id];
      result.push(item);
    }
    //console.log(result);
    return result;
  }
  componentDidMount() {
    if (this.state.currentModule !== "Optimize") {
      this.props.setModule("Optimize");
      //this.props.updateSort('');
      //this.props.fetchSites();
      //this.props.fetchApplications();
      //this.props.fetchLocations();
      //this.props.fetchImportances();
      //this.props.updateSort('');
      //this.props.updateFilter({});
    }
    if (!!this.props.table) {
      this.setState({
        //page: this.props.table.offset / this.props.table.pageSize,
        rowsPerPage: this.props.table.pageSize,
        page: this.props.table.page,
        //loading: false,
      });
    }
    //console.log(this.props.sort.filter);
    //console.log(this.props.page);
    if (
      Object.keys(this.props.sort.filter).length === 0 ||
      Object.keys(this.props.sort.filter).length === 1 || // adaptation for base 'range' filter
      Object.keys(this.props.sort.filter).length === 2 || // adaptation for base 'range' filter
      Object.keys(this.props.sort.filter).length === 3 // adaptation for print
    ) {
      let filters = {
        Application: this.props.sort.filter.Application,
        Site: this.props.sort.filter.Site,
        Location: this.props.sort.filter.Location,
        Disruption: this.props.sort.filter.Disruption,
        Range: this.props.sort.filter.Range,
        Importance: this.props.sort.filter.Importance,
        IssueType: this.props.sort.filter["Issue Type"],
      };
      if (this.props.auth.require_filter === 0) {
        //if filters aren't required load data
        /*let filters = {
          Application: this.props.sort.filter.Application,
          Site: this.props.sort.filter.Site,
          Location: this.props.sort.filter.Location,
          Disruption: this.props.sort.filter.Disruption,
          Range: this.props.sort.filter.Range,
          Importance: this.props.sort.filter.Importance,
          IssueType: this.props.sort.filter['Issue Type'],
        };*/
        /*console.log(
        this.state.rowsPerPage,
        this.state.page,
        this.props.sort.sort,
        filters
      );*/
        if (!!this.props.table) {
          this.props
            .fetchOptimizeDevices(
              this.props.table.offset,
              this.props.table.pageSize,
              this.props.sort.sort,
              filters
            )
            .then(() => {
              this.setState({
                loading: false,
              });
            });
        } else {
          this.props
            .fetchOptimizeDevices(
              this.state.page * this.state.rowsPerPage,
              this.state.rowsPerPage,
              this.props.sort.sort,
              filters
            )
            .then(() => {
              this.setState({
                loading: false,
              });
            });
        }
      } else {
        this.setState({
          //page,
          //rowsPerPage,
          loading: false,
        });
      }
      if (
        !this.props.locations ||
        Object.entries(this.props.locations).length === 0 ||
        this.props.locations.list.length === 0
      ) {
        this.props.fetchLocations(null, filters);
      }
      if (
        !this.props.applications ||
        Object.entries(this.props.applications).length === 0 ||
        this.props.applications.list.length === 0
      ) {
        this.props.fetchApplications(null, filters);
      }
      if (
        !this.props.sites ||
        Object.entries(this.props.sites).length === 0 ||
        this.props.sites.list.length === 0
      ) {
        this.props.fetchSites(null, filters);
      }
      if (
        !this.props.importances ||
        Object.entries(this.props.importances).length === 0 ||
        this.props.importances.list.length === 0
      ) {
        this.props.fetchImportances(null, filters);
      }
      if (
        !this.props.issueTypes ||
        Object.entries(this.props.issueTypes).length === 0 ||
        this.props.issueTypes.list.length === 0
      ) {
        this.props.fetchIssueTypes(null, filters);
      }
    } else {
      //console.log(this.props.sort.filter.Range);
      let filters = {
        Application: this.props.sort.filter.Application,
        Site: this.props.sort.filter.Site,
        Location: this.props.sort.filter.Location,
        Disruption: this.props.sort.filter.Disruption,
        Range: this.props.sort.filter.Range,
        Importance: this.props.sort.filter.Importance,
        IssueType: this.props.sort.filter["Issue Type"],
      };
      /*console.log(
        this.state.rowsPerPage,
        this.state.page,
        this.props.sort.sort,
        filters
      );*/
      this.props
        .fetchOptimizeDevices(
          this.state.page * this.state.rowsPerPage,
          this.state.rowsPerPage,
          this.props.sort.sort,
          filters
        )
        .then(() => {
          this.setState({
            loading: false,
          });
        });
    }
    //this.setState({ loading: true });
    //this.props.fetchEvents();
    /* {Object.keys(this.props.loops).map(key => 
            <PrioritizeTile hasBorder key = {key} loop = {this.props.loops[key].name} />
          )}*/
  }

  refreshPageView = (offset, rowsPerPage, expectedPage) => {
    this.setState({ loading: true });

    let filters = {
      Application: this.props.sort.filter.Application,
      Site: this.props.sort.filter.Site,
      Location: this.props.sort.filter.Location,
      Disruption: this.props.sort.filter.Disruption,
      Range: this.props.sort.filter.Range,
      Importance: this.props.sort.filter.Importance,
      IssueType: this.props.sort.filter["Issue Type"],
    };
    //Check to see if we already pulled the row data, if so don't bother the API again
    let rowsExist = true;
    const page = Math.floor(offset / rowsPerPage); // correct the page number for changing the page size
    //const page = 0;
    //const rowList = this.props.loops.map(loop => loop.rowId);
    const rowList = _.map(this.props.loops, (loop) => loop.rowId);
    for (let i = offset; i < offset + rowsPerPage; i++) {
      if (!rowList.includes(i + 1)) {
        rowsExist = false;
      }
    }
    if (rowsExist) {
      this.setState({
        page,
        rowsPerPage,
        loading: false,
      });
    } else {
      this.props
        .fetchOptimizeDevices(
          offset,
          rowsPerPage,
          this.props.sort.sort,
          filters
        )
        .then(() => {
          this.setState({
            page,
            rowsPerPage,
            loading: false,
          });
        });
    }
  };

  handleChangePage = (event, page) => {
    this.setState({
      loading: true,
    });
    this.props.setTableOffset(this.state.rowsPerPage * page);
    this.props.setTablePage(page);
    const offset = this.state.rowsPerPage * page;
    const rowsPerPage = this.props.table.pageSize;
    this.refreshPageView(offset, rowsPerPage, page);
  };
  handleChangeRowsPerPage = (event) => {
    this.setState({
      loading: true,
    });

    this.props.setTablePageSize(event.target.value);
    this.props.setTableOffset(
      this.props.table.page * this.props.table.pageSize
    );
    const offset = this.props.table.page * this.props.table.pageSize;
    const rowsPerPage = event.target.value;
    const page = Math.floor(offset / rowsPerPage);
    this.props.setTablePage(page);
    this.refreshPageView(offset, rowsPerPage, page);
  };
  multiSelectOnClose = async (event, label, changesOverride) => {
    if (this.state.changes || changesOverride) {
      let filters = {
        Application: this.props.sort.filter.Application,
        Site: this.props.sort.filter.Site,
        Location: this.props.sort.filter.Location,
        Disruption: this.props.sort.filter.Disruption,
        Range: this.props.sort.filter.Range,
        Importance: this.props.sort.filter.Importance,
        IssueType: this.props.sort.filter["Issue Type"],
      };

      if (
        !(
          !filters.Application &&
          !filters.Disruption &&
          !filters.Importance &&
          !filters.Location &&
          !filters.Site &&
          this.props.auth.require_filter
        )
      ) {
        this.props.deleteDevices();
        this.props.deleteLoops();
        //TODO Finish with update of page

        //console.log(filters);
        //console.log(this.props.sort.filter);
        this.setState({ loading: true });
        this.props.setTablePage(0);
        //await this.props.fetchDevicesSummary();

        this.props
          .fetchOptimizeDevices(
            0,
            this.props.table.pageSize,
            this.props.sort.sort,
            filters
          )
          .then(() => {
            this.setState({
              //page,
              //rowsPerPage,
              loading: false,
              changes: false,
            });
          });
        //if (!this.props.locations || Object.entries(this.props.locations).length === 0) {
        //if (label !== 'Location') {
        this.props.fetchLocations(null, filters);
        //}
        //}

        //if (!this.props.applications || Object.entries(this.props.applications).length === 0) {
        //if (label !== 'Application') {
        this.props.fetchApplications(null, filters);
        //}
        // }

        //if (!this.props.sites || Object.entries(this.props.sites).length === 0) {
        //if (label !== 'Site') {
        this.props.fetchSites(null, filters);
        //}
        //if (label !== 'Importancce') {
        this.props.fetchImportances(null, filters);

        this.props.fetchIssueTypes(null, filters);
        //}
        //}
      }
    }
  };
  multiSelectOnChange = async (event, value, type) => {
    let obj = { ...this.props.sort.filter };
    let name = !event.target.name
      ? event.target.id.split("-")[2]
      : event.target.name;
    //name = name.replace(' ', '');
    obj[name] = value;
    this.props.updateFilter(obj);
    this.setState({ changes: true });
  };
  updateSort = async (event, value, type) => {
    this.props.updateSort(value);
    this.props.deleteDevices();
    //TODO Finish with update of page
    let filters = {
      Application: this.props.sort.filter.Application,
      Site: this.props.sort.filter.Site,
      Location: this.props.sort.filter.Location,
      Disruption: this.props.sort.filter.Disruption,
      Range: this.props.sort.filter.Range,
      Importance: this.props.sort.filter.Importance,
      IssueType: this.props.sort.filter["Issue Type"],
    };
    if (
      !(
        !filters.Application &&
        !filters.Disruption &&
        !filters.Importance &&
        !filters.Location &&
        !filters.Site &&
        this.props.auth.require_filter
      )
    ) {
      //console.log(filters);
      //console.log(this.props.sort.filter);
      this.setState({ loading: true });
      this.props.setTableOffset(0);
      this.props.setTablePage(0);

      await this.props.fetchDevicesSummary();
      this.props
        .fetchOptimizeDevices(
          0,
          this.props.table.pageSize,
          this.props.sort.sort,
          filters
        )
        .then(() => {
          this.setState({
            //page,
            //rowsPerPage,
            loading: false,
          });
        });
    }
    //console.log(this.props.sort);
  };

  isValidDate(d) {
    return d instanceof Date && !isNaN(d) && d.getTime() > 0;
  }
  //TODO FIX TARGET ASYNC AWAIT
  updateDateRange = async (value, type) => {
    if (this.isValidDate(value)) {
      //do nothing if the date isn't valid

      if (type === "start") {
        this.props.updateDateRange({
          startDate: Math.floor(value.getTime() / 1000),
          endDate: this.props.sort.filter.Range.endDate,
        });
      } else {
        this.props.updateDateRange({
          startDate: this.props.sort.filter.Range.startDate,
          endDate: Math.floor(value.getTime() / 1000),
        });
      }
      let filters = {
        Application: this.props.sort.filter.Application,
        Site: this.props.sort.filter.Site,
        Location: this.props.sort.filter.Location,
        Disruption: this.props.sort.filter.Disruption,
        Range:
          type === "start"
            ? {
                startDate: Math.floor(value.getTime() / 1000),
                endDate: this.props.sort.filter.Range.endDate,
              }
            : {
                startDate: this.props.sort.filter.Range.startDate,
                endDate: Math.floor(value.getTime() / 1000),
              },
        Importance: this.props.sort.filter.Importance,
        IssueType: this.props.sort.filter["Issue Type"],
      };
      if (
        !(
          !filters.Application &&
          !filters.Disruption &&
          !filters.Importance &&
          !filters.Location &&
          !filters.Site &&
          this.props.auth.require_filter
        )
      ) {
        this.props.deleteDevices();

        //TODO Finish with update of page

        //console.log(filters);
        //console.log(this.props.sort.filter);
        this.setState({
          loading: true,
        });
        this.props.setTableOffset(0);
        this.props.setTablePage(0);
        await this.props.fetchDevicesSummary();

        this.props
          .fetchOptimizeDevices(
            0,
            this.props.table.pageSize,
            this.props.sort.sort,
            filters
          )
          .then(() => {
            this.setState({
              //page,
              //rowsPerPage,
              loading: false,
            });
          });
        this.props.fetchLocations(null, filters);
        //}
        //}

        //if (!this.props.applications || Object.entries(this.props.applications).length === 0) {
        //if (label !== 'Application') {
        this.props.fetchApplications(null, filters);
        //}
        // }

        //if (!this.props.sites || Object.entries(this.props.sites).length === 0) {
        //if (label !== 'Site') {
        this.props.fetchSites(null, filters);
        //}
        //if (label !== 'Importancce') {
        this.props.fetchImportances(null, filters);

        this.props.fetchIssueTypes(null, filters);
      }
    }
  };
  createOptimizeTiles(format) {
    const { classes } = this.props;
    const printStyles = functions.setPrintStyles(this.props.activeTab);
    //when formating for print creates pairs of tiles so we can insert page breaks correctly
    if (!format) {
      let tiles = this.alphabetical_sort_object_of_objects(
        this.props.loops,
        "rowId"
      ).map((key) => {
        //TODO FIX THE PAGE AND OFFSET VARIABLES TO BE THE SAME
        //TODO CORRECT EVENTS AND OTHER FIXED DATA

        // console.log(this.props.table.page);
        // console.log(this.props.table.pageSize);
        //  console.log('');
        //console.log(key.rowId);
        //  console.log('');
        if (
          this.props.table.page * this.props.table.pageSize < key.rowId &&
          key.rowId <=
            this.props.table.page * this.props.table.pageSize +
              this.props.table.pageSize
        ) {
          //console.log(key.rowId);
          return (
            <OptimizeTile
              hasLink={!printStyles}
              hasBorder
              key={key.rowId}
              device={this.props.loops[key.deviceId]}
            />
          );
        } else {
          return null;
        }
      });
      return tiles;
    } else {
      let sortedLoops = this.alphabetical_sort_object_of_objects(
        this.props.loops,
        "rowId"
      );
      let validLoops = [];
      let tiles = [];
      for (let i = 0; i < sortedLoops.length; i++) {
        let key = sortedLoops[i];
        if (
          this.props.table.page * this.props.table.pageSize < key.rowId &&
          key.rowId <=
            this.props.table.page * this.props.table.pageSize +
              this.props.table.pageSize
        ) {
          validLoops.push(key);
        }
      }
      for (let i = 0; i < validLoops.length; i = i + 2) {
        let key = validLoops[i];
        let key2 = !validLoops[i] ? null : validLoops[i + 1];
        tiles.push(
          <React.Fragment key={`tilepair_${i}`}>
            <Grid
              container
              className={classes.avoidBreak}
              style={{ paddingTop: 1 }}
            >
              <OptimizeTile
                hasLink={!printStyles}
                hasBorder
                key={key.rowId}
                device={this.props.loops[key.deviceId]}
              />
              {!key2 ? null : (
                <OptimizeTile
                  hasLink={!printStyles}
                  hasBorder
                  key={key2.rowId}
                  device={this.props.loops[key2.deviceId]}
                />
              )}
            </Grid>
          </React.Fragment>
        );
      }

      return tiles;
    }
  }
  renderLoading() {
    const { classes } = this.props;
    if (this.state.loading) {
      return (
        <React.Fragment>
          <Typography variant="subtitle2" style={{ marginTop: 12 }}>
            <CircularProgress
              className={classes.loadIcon}
              size={15}
              color="secondary"
            />
            Loading...
          </Typography>
        </React.Fragment>
      );
    } else {
      return;
    }
  }
  renderTotals() {
    const { classes } = this.props;
    let issueKeys = Object.keys(this.props.loops).map((key) => {
      return this.props.loops[key].issueCount === undefined
        ? 0
        : parseInt(this.props.loops[key].issueCount);
    });
    let totalIssues = 0;
    issueKeys.forEach((issue) => {
      totalIssues += issue;
    });
    let level1Keys = Object.keys(this.props.loops).map((key) => {
      return !this.props.loops[key].level1
        ? 0
        : parseInt(this.props.loops[key].level1);
    });
    let totalLevel1 = 0;
    level1Keys.forEach((recommendation) => {
      totalLevel1 += recommendation;
    });
    let level2Keys = Object.keys(this.props.loops).map((key) => {
      return !this.props.loops[key].level2
        ? 0
        : parseInt(this.props.loops[key].level2);
    });
    let totalLevel2 = 0;
    level2Keys.forEach((recommendation) => {
      totalLevel2 += recommendation;
    });
    let level3Keys = Object.keys(this.props.loops).map((key) => {
      return !this.props.loops[key].level3
        ? 0
        : parseInt(this.props.loops[key].level3);
    });
    let totalLevel3 = 0;
    level3Keys.forEach((recommendation) => {
      totalLevel3 += recommendation;
    });
    return (
      <Grid container className={classes.totalsContainer} spacing={2}>
        <Grid item>
          <Typography variant="subtitle2">
            Total Issues: {!totalIssues ? 0 : totalIssues}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle2">
            Field Adjustments: {!totalLevel1 ? 0 : totalLevel1}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle2">
            Part Replacements: {!totalLevel2 ? 0 : totalLevel2}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle2">
            Body Repairs: {!totalLevel3 ? 0 : totalLevel3}
          </Typography>
        </Grid>
      </Grid>
    );
  }
  async resetFilters() {
    this.setState({ changes: true });
    await this.props.clearFilter();
    this.multiSelectOnClose();
  }
  render() {
    const { classes } = this.props;
    /*if (Object.keys(this.props.loops).length !== 0 && this.state.loading) {
      this.setState({ loading: false });
    }*/
    const theme = initializeTheme(this.props.colorMode);
    //TODO PROVIDE NICE NO PERMISSION AND LOADING RESPONSES
    if (!this.props.auth) {
      return (
        <Typography variant="subtitle2">
          <CircularProgress
            className={classes.loadIcon}
            size={15}
            color="secondary"
          />
          Loading...
        </Typography>
      );
    }
    if (!!this.props.auth && this.props.auth.optimize === 0) {
      return <div>You do not have permission to access this module</div>;
    }
    let count = 0;
    let keys = Object.keys(this.props.loops);
    for (var k in keys) {
      if (this.props.loops[keys[k]].hasOwnProperty("rowId")) {
        ++count;
      }
    }

    return (
      <React.Fragment>
        <form
          className={classes.form}
          autoComplete="off"
          style={{ marginTop: 10 }}
        >
          <Grid container>
            <DatePicker
              disableFuture
              maxDate={new Date(this.props.sort.filter.Range.endDate * 1000)}
              label="Start Date"
              disabled={this.state.loading}
              value={new Date(this.props.sort.filter.Range.startDate * 1000)}
              date={new Date(this.props.sort.filter.Range.startDate * 1000)}
              dateFunction={(startDate) => {
                this.updateDateRange(startDate, "start");
              }}
            ></DatePicker>
            <DatePicker
              minDate={new Date(this.props.sort.filter.Range.startDate * 1000)}
              label="End Date"
              value={new Date(this.props.sort.filter.Range.endDate * 1000)}
              disabled={this.state.loading}
              dateFunction={(endDate) => {
                this.updateDateRange(endDate, "end");
              }}
            ></DatePicker>
          </Grid>
          <Grid
            container
            justifyContent="space-between"
            style={{ marginTop: 8 }}
          >
            <Grid item>
              <CustomSelect
                single
                id={null}
                label="Sort"
                type="sort"
                disabled={this.state.loading}
                onChangeFunction={this.updateSort}
                values={
                  !this.props.sort.sort ? "Date (Recent)" : this.props.sort.sort
                }
                options={[
                  "Date (Recent)",
                  "Resolution (Highest)",
                  "Sensitivity (Highest)",
                  "Stroke (Highest)",
                  "Importance",
                ]}
                helperText={null}
                width={190}
              />
              <CustomSelect
                id={null}
                label="Site"
                disabled={this.state.loading}
                values={
                  !this.props.sort.filter.Site
                    ? []
                    : this.props.sort.filter.Site
                }
                //type='site'
                options={
                  !this.props.sites || !this.props.sites.list
                    ? []
                    : this.props.sites.list.sort()
                }
                onCloseFunction={this.multiSelectOnClose}
                onChangeFunction={this.multiSelectOnChange}
                //selectedOption={'Resolution'}
                value={null}
                helperText={null}
              />
              <CustomSelect
                id={null}
                label="Location"
                disabled={this.state.loading}
                values={
                  !this.props.sort.filter.Location
                    ? []
                    : this.props.sort.filter.Location
                }
                onCloseFunction={this.multiSelectOnClose}
                onChangeFunction={this.multiSelectOnChange}
                //type='unit'
                options={
                  !this.props.locations || !this.props.locations.list
                    ? []
                    : this.props.locations.list.sort()
                }
                value={null}
                helperText={null}
                width={160}
              />
              <CustomSelect
                id={null}
                label="Application"
                disabled={this.state.loading}
                onCloseFunction={this.multiSelectOnClose}
                onChangeFunction={this.multiSelectOnChange}
                //type='application'
                values={
                  !this.props.sort.filter.Application
                    ? []
                    : this.props.sort.filter.Application
                }
                options={
                  !this.props.applications || !this.props.applications.list
                    ? []
                    : this.props.applications.list.sort()
                }
                value={null}
                helperText={null}
                width={170}
              />
              <CustomSelect
                id={null}
                label="Importance"
                disabled={this.state.loading}
                values={
                  !this.props.sort.filter.Importance
                    ? []
                    : this.props.sort.filter.Importance
                }
                options={
                  !this.props.importances || !this.props.importances.list
                    ? []
                    : this.props.importances.list.sort(functions.sortImportance)
                }
                onCloseFunction={this.multiSelectOnClose}
                onChangeFunction={this.multiSelectOnChange}
                value={null}
                helperText={null}
                width={170}
              />
              <CustomSelect //do not change name from Issue Type, Issue Types is for Prioritize
                id={null}
                label="Issue Type"
                disabled={this.state.loading}
                onCloseFunction={this.multiSelectOnClose}
                onChangeFunction={this.multiSelectOnChange}
                values={
                  !this.props.sort.filter["Issue Type"]
                    ? []
                    : this.props.sort.filter["Issue Type"]
                }
                options={
                  !this.props.issueTypes || !this.props.issueTypes.list
                    ? []
                    : this.props.issueTypes.list.sort()
                }
                value={null}
                helperText={null}
                width={300}
              />
            </Grid>
            <Grid item>
              <Button
                className={`${classes.buttonFilterClear} ${classes.printHide}`}
                variant="outlined"
                onClick={this.resetFilters}
              >
                <RestartAlt className={classes.buttonIcon}></RestartAlt>Clear
                All Filters
              </Button>
            </Grid>
          </Grid>
        </form>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="subtitle2" className={classes.concernLegend}>
              Concern Legend
            </Typography>
            <Grid container justifyContent="flex-start">
              <Tooltip
                arrow
                placement="top"
                title="Performance is within normal ranges"
                enterDelay={300}
              >
                <Grid item xs={12} sm="auto" className={classes.legendItem}>
                  <Typography style={theme.palette.green} variant="subtitle2">
                    <SvgIcon className={classes.legendIcon}>
                      <circle cx="12" cy="12" r="12" />
                    </SvgIcon>
                    <span style={{ marginTop: 2 }}>Good</span>
                  </Typography>
                </Grid>
              </Tooltip>
              <Tooltip
                arrow
                placement="top"
                title="Performance may exceed normal ranges"
                enterDelay={300}
              >
                <Grid item xs={12} sm="auto" className={classes.legendItem}>
                  <Typography style={theme.palette.yellow} variant="subtitle2">
                    <SvgIcon className={classes.legendIcon}>
                      <circle cx="12" cy="12" r="12" />
                    </SvgIcon>
                    Advisory
                  </Typography>
                </Grid>
              </Tooltip>
              <Tooltip
                arrow
                placement="top"
                title="Performance is outside of normal ranges"
                enterDelay={300}
              >
                <Grid item xs={12} sm="auto" className={classes.legendItem}>
                  <Typography style={theme.palette.red} variant="subtitle2">
                    <SvgIcon className={classes.legendIcon}>
                      <circle cx="12" cy="12" r="12" />
                    </SvgIcon>
                    Critical
                  </Typography>
                </Grid>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid item>{this.renderTotals()}</Grid>
        </Grid>
        <Divider className={classes.dividerOptimize} />
        {count === 0 ? null : (
          <Grid container>
            <Grid item>
              <TablePagination
                rowsPerPageOptions={[24, 48, 96]}
                component="div"
                count={this.props.loopCount}
                rowsPerPage={this.props.table.pageSize}
                page={this.props.table.page}
                backIconButtonProps={{
                  "aria-label": "Previous Page",
                }}
                nextIconButtonProps={{
                  "aria-label": "Next Page",
                }}
                labelRowsPerPage="Valves per Page"
                onPageChange={this.handleChangePage}
                onRowsPerPageChange={this.handleChangeRowsPerPage}
                onClick={(e) => e.preventDefault()}
                className={classes.paginationFix}
                showFirstButton
                showLastButton
              />
            </Grid>
          </Grid>
        )}
        <Grid container>
          <Grid item>{this.renderLoading()}</Grid>
        </Grid>
        <Grid
          container={!this.props.format}
          className={classes.containerSpacing}
        >
          {(keys.length === 0 || count === 0) &&
          this.state.loading === false ? (
            <Typography variant="h6">
              {!!this.props.auth.require_filter &&
              Object.keys(this.props.sort.filter).length <= 3
                ? `Please select a Site, Location, Application, or Importance.`
                : `No tests found within this date range.`}
            </Typography>
          ) : null}
          {this.createOptimizeTiles(this.props.format)}
        </Grid>
        {count === 0 ? null : (
          <Grid container>
            <Grid item>
              <TablePagination
                rowsPerPageOptions={[24, 48, 96]}
                component="div"
                count={this.props.loopCount}
                rowsPerPage={this.props.table.pageSize}
                page={this.props.table.page}
                backIconButtonProps={{
                  "aria-label": "Previous Page",
                }}
                nextIconButtonProps={{
                  "aria-label": "Next Page",
                }}
                labelRowsPerPage="Valves per Page"
                onPageChange={this.handleChangePage}
                onRowsPerPageChange={this.handleChangeRowsPerPage}
                onClick={(e) => e.preventDefault()}
                className={classes.paginationFix}
                showFirstButton
                showLastButton
              />
            </Grid>
          </Grid>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    applications: state.applications,
    importances: state.importances,
    locations: state.locations,
    loops: state.devices.devices,
    loopCount: state.devices.count,
    format: state.format.format,
    sort: state.sort,
    table: state.table,
    sites: state.sites,
    issueTypes: state.issuetypes,
    currentModule: state.currentModule,
    colorMode: state.colorMode.colorMode,
  };
};

export default connect(mapStateToProps, {
  fetchOptimizeDevices,
  fetchDevicesSummary,
  fetchEvents,
  setTableOffset,
  setTablePageSize,
  setTablePage,
  setModule,
  updateSort,
  updateDateRange,
  fetchLocations,
  fetchApplications,
  fetchImportances,
  fetchSites,
  fetchIssueTypes,
  deleteLoops,
  deleteDevices,
  fetchSort,
  fetchLoops,
  updateFilter,
  clearFilter,
})(withStyles(styles)(Optimize));
