import { ROADMAP_SET_ACTIVE_TAB } from '../actions/types';

const INITIAL_STATE = {
  activeTab: 'prioritize',
};

const roadmapReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ROADMAP_SET_ACTIVE_TAB:
      return { ...state, activeTab: action.payload };
    default:
      return state;
  }
};

export default roadmapReducer;
