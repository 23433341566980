import React from 'react';
//import Grid from '@mui/material/Grid';
import WidgetTitle from '../../../headers/WidgetTitle';
import {
  Button,
  Collapse,
  Divider,
  Grid,
  /*Input,
  Tooltip,
  TableSortLabel,
  TableRow,
  TablePagination,
  TableHead,
  TableCell,
  TableBody,
  Table,*/
  //IconButton,
  TextField,
  Typography,
  //Typography,
} from '@mui/material';
import Confirm from '../../../dialogs/Confirm';
//import DialogContentText from '@mui/material/DialogContentText';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../../../styles';
import CustomSelect from '../../../filters/CustomSelect';
import { AddBox } from '@mui/icons-material';
import TableWidgetExpandable from '../../../widgets/TableWidgetExpandable';
import api from '../../../../apis/api';
import { fetchSites, fetchLocations, fetchApplications, fetchClients } from '../../../../actions/index';
import matchFilter from '../../../filters/functions/searchFilter';
import ExpandableSection from '../../../helpers/ExpandableSection';
import CustomDialog from '../../../popup/CustomDialog';

class UserConfig extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: '',
      statusMessage: '',
      siteOpen: false,
      userList: [],
      deleteConfirm: false,
      changeConfirm: false,
      clickedRow: null,
      dialogOpen: false,
      rowOpen: false,
      userId: -1,
      username: '',
      email: '',
      first: '',
      last: '',
      password: '',
      confirmPassword: '',
      permission: 99,
      realms: [],
      sites: [],
      applications: [],
      locations: [],
      clients: [],
      filterStr: '',
      filterObj: {
        realms: '',
        sites: '',
        clients: '',
        locations: '',
        applications: '',
      },
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleInputChangeCheckbox = this.handleInputChangeCheckbox.bind(this);
    this.handleInputChangePermission = this.handleInputChangePermission.bind(this);
    this.updateUser = this.updateUser.bind(this);
    /*this.handleInputChangeLevel = this.handleInputChangeLevel.bind(this);
    this.handleInputChangeDate = this.handleInputChangeDate.bind(this);
    this.handleApproval = this.handleApproval.bind(this);*/
  }
  async componentDidMount() {
    if (this.state.userList.length === 0) {
      const url = `getUserList`;
      const response = await api.get(url);
      //console.log(response);
      this.setState({ userList: response.data });
    }
    if (!this.props.sites || Object.entries(this.props.sites).length === 0) {
      //! TODO SET UP FOR MULTIPLE MODULE TYPES
      if (this.props.activeModule === 'iba') {
        this.props.fetchSites('iba');
        this.props.fetchLocations('iba');
        this.props.fetchApplications('iba');
        this.props.fetchClients('iba');
      } else {
        this.props.fetchSites('optimize');
        this.props.fetchLocations('optimize');
        this.props.fetchApplications('optimize');
        this.props.fetchClients('optimize');
      }
    }
  }
  handleRowClick = (row) => {
    const selectedUser = this.state.userList.find((user) => user.id === row);
    //console.log(selectedUser);
    this.setState((state) => ({
      clickedRow: row,
      userId: selectedUser.userId,
      username: selectedUser.username,
      email: selectedUser.email,
      first: selectedUser.first_name,
      last: selectedUser.last_name,
      password: '',
      confirmPassword: '',
      permission: selectedUser.permission_level,
      realms: selectedUser.available_realms.split(','),
      sites: selectedUser.sitePermission,
      locations: selectedUser.locationPermission,
      clients: selectedUser.clientPermission,
      applications: selectedUser.applicationPermission,
      status: '',
      statusMessage: '',
      /*rowChange: {
          name: this.props.devices[row].name,
          serial: this.props.devices[row].serial,
          site: this.props.devices[row].site,
          application: this.props.devices[row].application,
          description: this.props.devices[row].description,
          bodySize: this.props.devices[row].bodySize,
          bodyType: this.props.devices[row].bodyType,
          importance: this.props.devices[row].importance,
          resApp: !this.props.devices[row].resApp
            ? null
            : this.props.devices[row].resApp,
          senApp: !this.props.devices[row].senApp
            ? null
            : this.props.devices[row].senApp,
          strokeApp: !this.props.devices[row].strokeApp
            ? null
            : this.props.devices[row].strokeApp,
        },*/
    }));
    if (this.state.rowOpen && row === this.state.clickedRow) {
      this.setState((state) => ({ rowOpen: !state.rowOpen }));
    } else {
      this.setState((state) => ({ rowOpen: false }));
      this.setState((state) => ({ rowOpen: !state.rowOpen }));
    }
  };

  renderChildRowContent = (row) => {
    const { classes } = this.props;
    //console.log(this.state);
    return (
      <Collapse in={this.state.rowOpen && this.state.clickedRow === row.id} unmountOnExit={true}>
        <div className={classes.collapseBody}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              this.setState({ changeConfirm: true });
            }}
          >
            {this.renderUserForm(false)}
            {this.state.status !== '' ? (
              <Typography variant='subtitle1' color={this.state.status === 'Failure' ? 'error' : 'secondary'} style={{ marginLeft: 20, marginTop: 35 }}>{`${this.state.statusMessage}`}</Typography>
            ) : null}
            <div style={{ marginTop: 40 }}></div>
            <Divider className={`${classes.dialogDivider} ${classes.accordionDialogDivider}`}></Divider>
            <Grid container justifyContent='space-between' style={{ marginBottom: -5 }}>
              <Grid item></Grid>
              <Grid item>
                <Button
                  type='text'
                  color='secondary'
                  onClick={() => {
                    //this.deleteUser(this.state.username);
                    this.setState({ deleteConfirm: true });
                  }}
                  style={{ marginRight: 12 }}
                >
                  Delete
                </Button>
                <Button type='text' color='secondary'>
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Collapse>
    );
  };
  handleClickOpen = () => {
    //console.log();
    this.setState({
      dialogOpen: true,
      rowOpen: false,
      clickedRow: null,
      userId: -1,
      username: '',
      email: '',
      first: '',
      last: '',
      password: '',
      confirmPassword: '',
      permission: 99,
      realms: !this.props.current_realm ? [] : [this.props.current_realm.toString()],
      sites: [],
      locations: [],
      applications: [],
      clients: [],
      filterObj: {
        realms: '',
        sites: '',
        locations: '',
        applications: '',
        clients: '',
      },
      status: '',
      statusMessage: '',
    });
  };

  handleClose = () => {
    this.setState({ dialogOpen: false });
  };
  handleInputChange(event) {
    const target = event.target;
    const value = target.name === 'isGoing' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }
  handleInputChangeCheckbox(event, isChecked) {
    //console.log(event.target);
    const target = event.target;
    const category = target.name;
    const value = target.value;
    let currentCategory = this.state[category];
    //console.log(currentCategory);
    if (isChecked) {
      currentCategory.push(value);
    } else {
      currentCategory = currentCategory.filter((e) => e !== value);
    }
    this.setState({
      [category]: currentCategory,
    });
  }
  convertPermission(permissionLevel) {
    //console.log(this.state);
    //console.log(permissionLevel);
    switch (true) {
      case permissionLevel === 99:
        return 'Viewer';
      //break;
      case permissionLevel === 40:
        return 'Uploader';
      //break;

      case permissionLevel <= 20:
        return 'Super Admin';
      //break;
      case permissionLevel <= 30:
        return 'Admin';
      default:
        return 'Viewer';
    }
  }

  handleInputChangePermission(event) {
    let value = 999;
    switch (event) {
      case 'Viewer':
        value = 99;
        break;
      case 'Uploader':
        value = 40;
        break;
      case 'Admin':
        value = 30;
        break;
      case 'Super Admin':
        value = 20;
        break;
      default:
        value = 99;
    }
    const name = 'permission';
    this.setState({
      [name]: value,
    });
  }
  selectAll(type, all) {
    this.setState({ [type]: all });
  }
  selectNone(type) {
    this.setState({ [type]: [] });
  }
  getSectionContent(data, type, description) {
    /*const siteArr =
      !this.props.sites || !this.props.sites.list ? [] : this.props.sites.list;*/
    const { classes } = this.props;
    /*const setKeys = [
      'serial',
      'name',
      'site',
      'application',
      'bodyType',
      'description',
      'importance',
    ];*/
    //console.log(this.state.filterObj);
    const threshold = 0.2;
    const filteredOptions = this.state.filterObj[type] === '' ? data.sort() : data.sort().filter((item) => item.toString().score(this.state.filterObj[type]) > threshold);
    //console.log(filteredOptions);
    const content = (
      <Grid container style={{ marginTop: 20 }}>
        <Grid item>
          <Typography variant='h6'>{description}</Typography>
          <Grid container spacing={1} style={{ marginTop: 5, marginBottom: 5 }}>
            <Grid item>
              <TextField
                variant='standard'
                className={classes.textFieldCompact}
                label=''
                placeholder='Search'
                onChange={(event) => {
                  let localFilterObj = this.state.filterObj;
                  localFilterObj[type] = event.target.value;
                  this.setState({ filterObj: localFilterObj });
                }}
                value={this.state.filterObj[type]}
                InputLabelProps={{
                  classes: {
                    root: classes.inputLabelFix,
                  },
                }}
              />
            </Grid>
            <Grid item>
              <Button
                color='primary'
                variant='outlined'
                size='small'
                onClick={() => {
                  this.selectAll(type, filteredOptions);
                }}
              >
                Select All
              </Button>
            </Grid>
            <Grid item>
              <Button
                color='primary'
                variant='outlined'
                size='small'
                onClick={() => {
                  this.selectNone(type);
                }}
              >
                Select None
              </Button>
            </Grid>
          </Grid>
          <div style={{ padding: 8 }}>
            {filteredOptions.map((item) => {
              if (!this.state[type]) {
                console.log(type);
                console.log(this.state);
              }
              if (item !== null && !!item) {
                return (
                  <FormControlLabel
                    //value="top"
                    key={`label_${type}_${item}`}
                    className={classes.configListItem}
                    control={
                      <Checkbox
                        checked={this.state[type].indexOf(item.toString()) !== -1}
                        key={item}
                        value={item}
                        onChange={this.handleInputChangeCheckbox}
                        name={type}
                        className={classes.configListItemCheckbox}
                        color='secondary'
                      />
                    }
                    label={<div className={classes.configListItemLabel}>{item.toUpperCase()}</div>}
                    //labelPlacement="top"
                  />
                );
              } else {
                return null;
              }
              //return <div key={realm.name}>{realm.name}</div>;
            })}
          </div>
        </Grid>
      </Grid>
    );
    return content;
  }
  getRealmContent() {
    const { classes } = this.props;
    const threshold = 0.2;

    const filteredOptions =
      this.state.filterObj['realms'] === '' ? this.props.realms.sort() : this.props.realms.sort().filter((item) => item.toString().score(this.state.filterObj['realms']) > threshold);
    const content = (
      <Grid container spacing={2} style={{ marginTop: 20 }}>
        <Grid item>
          <Typography variant='h6' style={{ marginBottom: 4 }}>
            Select which realms this user may access
          </Typography>
          <Grid container spacing={1} style={{ marginTop: 5, marginBottom: 5 }}>
            <Grid item>
              <TextField
                variant='standard'
                className={classes.textFieldCompact}
                label=''
                placeholder='Search'
                onChange={(event) => {
                  let localFilterObj = this.state.filterObj;
                  localFilterObj['realm'] = event.target.value;
                  this.setState({ filterObj: localFilterObj });
                }}
                value={this.state.filterObj['realm']}
                InputLabelProps={{
                  classes: {
                    root: classes.inputLabelFix,
                  },
                }}
              />
            </Grid>
            <Grid item>
              <Button
                color='primary'
                variant='outlined'
                size='small'
                onClick={() => {
                  this.selectAll(
                    'realms',
                    this.props.realms.map((o) => o.id.toString())
                  );
                }}
              >
                Select All
              </Button>
            </Grid>
            <Grid item>
              <Button
                color='primary'
                variant='outlined'
                size='small'
                onClick={() => {
                  this.selectNone('realms');
                }}
              >
                Select None
              </Button>
            </Grid>
          </Grid>
          <div style={{ padding: 8 }}>
            {filteredOptions.map((realm) => {
              return (
                <FormControlLabel
                  //value="top"
                  key={`label_${realm.name}`}
                  className={classes.configListItem}
                  control={
                    <Checkbox
                      checked={this.state.realms.indexOf(realm.id.toString()) !== -1}
                      key={realm.name}
                      value={realm.id}
                      onChange={this.handleInputChangeCheckbox}
                      name={'realms'}
                      color='secondary'
                      className={classes.configListItemCheckbox}
                    />
                  }
                  label={<div className={classes.configListItemLabel}>{realm.name.toUpperCase()}</div>}
                  //labelPlacement="top"
                />
              );
              //return <div key={realm.name}>{realm.name}</div>;
            })}
          </div>
        </Grid>
      </Grid>
    );
    return content;
  }
  showUserForm(user) {
    //const { classes } = this.props;
    return (
      <CustomDialog
        center
        maxWidth='md'
        open={this.state.dialogOpen}
        onClose={this.handleClose}
        title={`${!user ? `Add New` : `Edit`} User`}
        titleIcon='Add'
        content={
          <React.Fragment>
            {' '}
            <form>{this.renderUserForm(true)}</form>
            {this.state.status !== '' ? (
              <Typography variant='subtitle1' color={this.state.status === 'Failure' ? 'error' : 'secondary'} style={{ marginLeft: 20, marginTop: 35 }}>{`${this.state.statusMessage}`}</Typography>
            ) : null}
          </React.Fragment>
        }
        actions={
          <React.Fragment>
            <Button onClick={this.handleClose} color='secondary'>
              Cancel
            </Button>
            <Button
              onClick={() => {
                this.updateUser(true);
              }}
              color='secondary'
            >
              Submit
            </Button>
          </React.Fragment>
        }
      ></CustomDialog>
    );
  }

  renderUserForm(fullWidthBool) {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Grid container spacing={3} style={{ marginTop: 0 }}>
          <Grid item>
            <TextField
              variant='outlined'
              className={classes.textField}
              autoFocus
              id='username'
              label='Username'
              name='username'
              disabled={!fullWidthBool && this.state.username.length > 0}
              value={this.state.username}
              fullWidth={fullWidthBool}
              onChange={this.handleInputChange}
              InputLabelProps={{
                classes: {
                  root: classes.inputLabelFix,
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginTop: 0 }}>
          <Grid item>
            <TextField
              variant='outlined'
              className={classes.textField}
              autoFocus
              id='password'
              label='Password'
              name='password'
              type='password'
              fullWidth={fullWidthBool}
              onChange={this.handleInputChange}
              InputLabelProps={{
                classes: {
                  root: classes.inputLabelFix,
                },
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              variant='outlined'
              className={classes.textField}
              autoFocus
              id='confirmPassword'
              label='Confirm Password'
              name='confirmPassword'
              type='password'
              fullWidth={fullWidthBool}
              onChange={this.handleInputChange}
              InputLabelProps={{
                classes: {
                  root: classes.inputLabelFix,
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginTop: 10 }}>
          <Grid item>
            <TextField
              variant='standard'
              className={classes.textField}
              autoFocus
              id='email'
              label='Email'
              name='email'
              type='email'
              value={this.state.email}
              fullWidth={fullWidthBool}
              onChange={this.handleInputChange}
              style={{ width: 280 }}
              InputLabelProps={{
                classes: {
                  root: classes.inputLabelFix,
                },
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              variant='standard'
              className={classes.textField}
              autoFocus
              id='first'
              label='First'
              name='first'
              value={this.state.first}
              fullWidth={fullWidthBool}
              onChange={this.handleInputChange}
              InputLabelProps={{
                classes: {
                  root: classes.inputLabelFix,
                },
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              variant='standard'
              className={classes.textField}
              autoFocus
              id='last'
              label='Last'
              name='last'
              value={this.state.last}
              fullWidth={fullWidthBool}
              onChange={this.handleInputChange}
              InputLabelProps={{
                classes: {
                  root: classes.inputLabelFix,
                },
              }}
            />
          </Grid>
          <Grid item>
            <CustomSelect
              single
              id='permission'
              label='Permission'
              type='permission'
              name='permission'
              disabled={this.state.loading}
              //onCloseFunction={this.multiSelectOnClose}
              onChangeFunction={(event, value) => {
                this.handleInputChangePermission(value);
              }}
              //
              values={this.convertPermission(this.state.permission)}
              options={this.props.permission < 20 ? ['Viewer', 'Uploader', 'Admin', 'Super Admin'] : this.props.permission < 30 ? ['Viewer', 'Uploader', 'Admin'] : ['Viewer', 'Uploader']}
              //value={null}
              helperText={null}
              InputLabelProps={{
                classes: {
                  root: classes.inputLabelFix,
                },
              }}
            />
          </Grid>
        </Grid>
        {this.props.permission <= 30 ? (
          <React.Fragment>
            <Typography style={{ marginTop: 40 }} variant='subtitle1' color='primary'>
              Global Permissions
            </Typography>
            <ExpandableSection title={'Realms'} content={this.getRealmContent()} />
          </React.Fragment>
        ) : null}
        <Typography style={{ marginTop: 40 }} variant='subtitle1' color='primary'>
          Current Realm Permissions
        </Typography>
        <ExpandableSection
          title={'Client Restrictions'}
          content={this.getSectionContent(
            !this.props.clients || !this.props.clients.list ? [] : this.props.clients.list,
            'clients',

            'Select which Clients to restrict this user to.'
          )}
        />
        <ExpandableSection
          title={'Site Restrictions'}
          content={this.getSectionContent(
            !this.props.sites || !this.props.sites.list ? [] : this.props.sites.list,
            'sites',

            'Select which Sites to restrict this user to.'
          )}
        />
        <ExpandableSection
          title={'Location Restrictions'}
          content={this.getSectionContent(
            !this.props.locations || !this.props.locations.list ? [] : this.props.locations.list,
            'locations',

            'Select which Locations to restrict this user to.'
          )}
        />
        <ExpandableSection
          title={'Application Restrictions'}
          content={this.getSectionContent(
            !this.props.applications || !this.props.applications.list ? [] : this.props.applications.list,
            'applications',

            'Select which Applications to restrict this user to.'
          )}
        />
      </React.Fragment>
    );
  }

  async deleteUser(username) {
    //console.log(username);
    const userData = {
      username,
    };
    const url = `deleteuser`;
    //console.log(userData);
    /*const response = */ await api.post(url, userData);
    /*if (response.data.changedRows > 0) {
    //if (true) {
      console.log('Deleted'); //TODO POPUP SAYING SUCCESS GOES HERE
    }*
    /*const url = `getrealms`;
      const response = await api.get(url);*/
    //console.log(response);
    const url2 = `getUserList`;
    const response2 = await api.get(url2);
    //console.log(response);
    this.setState({
      dialogOpen: false,
      rowOpen: false,
      clickedRow: null,
      userId: -1,
      username: '',
      email: '',
      first: '',
      last: '',
      password: '',
      confirmPassword: '',
      permission: 99,
      realms: [],
      sites: [],
      applications: [],
      locations: [],
      clients: [],
      filterObj: {
        realms: '',
        sites: '',
        clients: '',
        locations: '',
        applications: '',
      },
      userList: response2.data,
    });
  }
  async updateUser(newUser) {
    try {
      if (this.state.password !== this.state.confirmPassword) {
        this.setState({
          status: 'Failure',
          statusMessage: 'Passwords do not match',
        });
        //console.log('FAILED PASS MATCH OR PASS UNDER 8 CHARS');
      } else if (newUser && this.state.password !== 0 && this.state.password.length < 7) {
        this.setState({
          status: 'Failure',
          statusMessage: 'Password under 8 Letters',
        });
      } else if (this.state.realms.length === 0) {
        //console.log('NO REALMS');
        this.setState({ status: 'Failure', statusMessage: 'No Realms' });
      } /* else if (
      this.state.sites.length === 0 ||
      this.state.locations.length === 0 ||
      this.state.applications.length === 0
    ) {
      //console.log('NO REALMS');
      this.setState({
        status: 'Failure',
        statusMessage: 'No Sites, Locations, or Applications assigned',
      });
    }*/ else if (this.state.username.length === 0) {
        this.setState({ status: 'Failure', statusMessage: 'No Username' });
      } else {
        //console.log('create');
        const userData = {
          userId: this.state.userId,
          username: this.state.username,
          password: this.state.password.length === 0 ? null : this.state.password,
          first: this.state.first,
          last: this.state.last,
          email: this.state.email,
          permission: this.state.permission,
          realms: this.state.realms.join(','),
          sites: this.state.sites,
          locations: this.state.locations,
          clients: this.state.clients,
          applications: this.state.applications,
          create: newUser,
        };
        const url = `updateuser`;
        //console.log(userData);
        //console.log('submit'); //await api.post(url, userData);
        const response = await api.post(url, userData);
        //const response = { data: { changedRows: 1 } };
        if (response.data.changedRows > 0) {
          this.setState({ status: '', statusMessage: '' });
        }
        /*if (true) {
        if (newUser) {
          console.log('Created'); //TODO POPUP SAYING SUCCESS GOES HERE
        } else {
          console.log('Edited'); //TODO POPUP SAYING SUCCESS GOES HERE
        }
      }
      /*const url = `getrealms`;
      const response = await api.get(url);*/
        //console.log(response);
        const url2 = `getUserList`;
        const response2 = await api.get(url2);
        //console.log(response);
        this.setState({
          dialogOpen: false,
          rowOpen: false,
          clickedRow: null,
          userId: -1,
          username: '',
          email: '',
          first: '',
          last: '',
          password: '',
          confirmPassword: '',
          permission: 99,
          realms: [],
          sites: [],
          applications: [],
          locations: [],
          clients: [],
          filterObj: {
            realms: '',
            sites: '',
            locations: '',
            clients: '',
            applications: '',
          },
          userList: response2.data,
        });
      }
    } catch (e) {
      console.log(e);
      this.setState({
        status: 'Failure',
        statusMessage: !e.data || !e.data.error ? (!e.statusText ? 'Failure!' : `Error: ${e.statusText}`) : e.data.error,
      });
    }
  }
  //TODO

  render() {
    const { classes } = this.props;
    const headerArr = [
      { id: 'username', label: 'Username' },
      { id: 'first_name', label: 'First Name' },
      { id: 'last_name', label: 'Last Name' },
      { id: 'permission_level2', label: 'Permission Level' },
      { id: 'email', label: 'Email' },
      //{ id: 'email', label: 'Email' },
    ];
    let data = !this.state.userList ? [] : matchFilter(this.state.userList, this.state.filterStr);
    for (let i = 0; i < data.length; i++) {
      data[i].permission_level2 = this.convertPermission(data[i].permission_level);
    }
    //console.log(data);
    //.log(this.props.permission);
    if (this.props.permission > 20) {
      //TODO Forcefull link to another section?
      return <React.Fragment>You do not have permission to view this module</React.Fragment>;
    }
    return (
      <React.Fragment>
        <Confirm
          open={this.state.deleteConfirm}
          title={'Delete User'}
          content={'Are you sure you would like to delete this user?'}
          handleConfirm={() => {
            this.deleteUser(this.state.username);
            this.setState({ deleteConfirm: false });
          }}
          handleClose={() => {
            this.setState({ deleteConfirm: false });
          }}
        />
        <Confirm
          open={this.state.changeConfirm}
          title={'Edit User'}
          content={'Are you sure you would like to edit this user?'}
          handleConfirm={() => {
            this.updateUser(false);
            this.setState({ changeConfirm: false });
          }}
          handleClose={() => {
            this.setState({ changeConfirm: false });
          }}
        />

        {this.showUserForm()}
        {/*<Button
          variant='outlined'
          size='medium'
          color='primary'
          className={`${classes.buttonFilterRight} ${classes.button}`}
        >
          Select All
        </Button>
        <Button
          variant='outlined'
          size='medium'
          color='primary'
          className={`${classes.buttonFilterRight} ${classes.button}`}
        >
          Deselect
        </Button>*/}
        <WidgetTitle title='User Database'></WidgetTitle>
        <Grid container justifyContent='space-between' className={classes.printHide}>
          <Grid item>
            <TextField
              variant='outlined'
              InputProps={{ classes: { root: classes.inlineInput } }}
              className={classes.searchField}
              hiddenLabel
              placeholder='Search'
              onChange={(event) => {
                //console.log(event.target.value);
                this.setState({ filterStr: event.target.value });
              }}
              value={this.state.filterStr}
              /*defaultValue={
                  !this.props.clickedRow
                    ? null
                    : this.props.devices[this.props.clickedRow].application
                }*/
              InputLabelProps={{
                classes: {
                  root: classes.inputLabelFix,
                },
              }}
            />
          </Grid>
          <Grid item>
            <Button variant='outlined' size='medium' color='primary' onClick={this.handleClickOpen} className={classes.buttonFilterRight} style={{ marginBottom: 8 }}>
              <AddBox className={classes.buttonIcon}></AddBox>Add New User
            </Button>
          </Grid>
        </Grid>
        <Typography variant='body2' style={{ marginBottom: 4 }} className={classes.printHide}>
          Click any row to expand
        </Typography>
        <TableWidgetExpandable
          data={data}
          header={headerArr}
          rows={25}
          type='userConfig'
          handleClick={this.handleRowClick}
          renderChildRowContent={this.renderChildRowContent}
          rowOpen={this.state.rowOpen}
        ></TableWidgetExpandable>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    activeModule: state.currentModule.currentModule,
    userId: state.auth.userId,
    realms: state.auth.realms,
    current_realm: state.auth.current_realm,
    permission: state.auth.view,
    sites: state.sites,
    locations: state.locations,
    clients: state.clients,
    applications: state.applications,
  };
};

export default connect(mapStateToProps, {
  //fetchDeviceImprovements,
  fetchSites,
  fetchLocations,
  fetchClients,
  fetchApplications,
})(withStyles(styles)(UserConfig));
